.keil-nav {
  background-color: $arm-white;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &__container {
    @include kl-centred-content;
    display: flex;
    justify-content: space-between;
    height: 4rem;
  }

  &__left {
    flex: 1 0 auto;
  }

  &__right {
    flex: 0 1 auto;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 1.75rem;
    width: auto;
    padding: 0.25rem 0.5rem 0.25rem 0;
    margin-right: 0.5rem;
    border-right: 1px solid $arm-grey;
  }

  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: $arm-blue;
    text-decoration: none;
  }

  &__menu {
    display: none;

    @include medium-screen {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: 0;
      margin: 0 0 0 1.5rem;
    }

    & > * + * {
      margin-left: 1.5rem;
    }

    &item {
      color: $arm-black;
      position: relative;
      padding: 1.25rem 0;
      cursor: pointer;
      font-weight: 400;

      &[aria-haspopup="true"]::after {
        font: var(--fa-font-light);
        content: "\f078";
        margin-left: 0.5rem;
        font-size: 0.75rem;
      }

      &[aria-haspopup="true"]:hover::after {
        color: $arm-black;
        content: "\f077";
      }

      &:hover > a {
        color: black;
      }

      > a {
        position: relative;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__submenu {
    visibility: hidden;
    position: absolute;
    width: 15rem;
    padding: 0;
    background-color: $arm-white;
    z-index: 1;
    list-style-type: none;
    top: 100%;
    left: 50%;
    margin-left: -7.5rem;
    border: $border-style;

    a {
      color: $arm-black;
      text-decoration: none;
      padding: 0.5rem;
      display: block;

      &:hover {
        background-color: rgba($arm-light-grey, 0.4);
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -0.5rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent transparent $border-color transparent;
    }
  }

  &__menuitem:hover &__submenu {
    visibility: visible;
  }

  &__cta {
    @include kl-yellow-button;
    font-size: 0.9rem;
    margin-left: 2rem;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }

  &__control {
    background: none;
    border: none;

    &::after {
      font: var(--fa-font-light);
      content: "\f0c9";
      color: $arm-black;

      @include medium-screen {
        display: none;
      }
    }

    &[aria-expanded="true"]::after {
      content: "\f00d";
    }
  }

  &-mob {
    &__container {
      position: absolute;
      width: 100%;
      height: calc(100vh - 4rem);
      display: none;

      &[data-expanded] {
        display: block;

        @include medium-screen {
          display: none;
        }
      }

      button {
        color: $arm-black;
        background: none;
        border: none;
        margin: 0;
        padding: 0;

        i {
          margin-right: 1rem;
        }
      }

      a {
        display: block;
        color: $arm-black;
        text-decoration: none;
      }
    }

    &__menu,
    &__submenu {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 1.5rem;
      list-style-type: none;
      background-color: $arm-light-grey-alt;
      overflow-y: auto;

      & > * + * {
        margin-top: 1.5rem;
      }

      a {
        display: block;
        text-decoration: none;
        display: flex;
        align-items: center;

        span {
          flex: 1 0 auto;
        }
      }
    }

    &__menu {
      a {
        color: $arm-black;
        font-weight: 400;
      }
    }

    &__menuitem[aria-expanded="true"] &__submenu {
      display: block;
    }

    &__submenu {
      display: none;

      a {
        color: $arm-dark-grey-web-safe;
        font-weight: 400;
      }
    }
  }
}
