.device-summary,
.family-summary,
.device-group-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &__header {
    @media (max-width: 40rem) {
      margin-bottom: 1rem;
    }
  }

  a {
    @include kl-text-link($arm-black);
    font-weight: normal;
  }
}

.device-header,
.family-header,
.device-group-header {
  &__container {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }

  &__title {
    @include kl-large-header;
    white-space: break-spaces;
  }

  &__revision {
    color: darken($keil-grey, 40%);
    margin: 0;
  }

  &__vendor {
    display: inline-block;
  }
}

.device-components {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 2rem 0;

  &__item {
    padding: 0 1.5rem;
    border-right: $border-style;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: 40rem) {
      padding: 0 1rem;
    }
  }

  &__title {
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    color: darken($keil-grey, 40%);
  }

  &__value--large-text {
    font-size: 22px;
    margin: 0;

    @media (max-width: 40rem) {
      font-size: 18px;
    }
  }
}

.device-breadcrumbs,
.family-breadcrumbs,
.device-group-breadcrumbs {
  @include kl-breadcrumbs;
}

.devices-page-info {
  &__url {
    @include kl-text-link-light;
  }
}
