.pack-badge {
  &__inline-container {
    display: flex;
    gap: 10px;
  }

  &__deprecated {
    @include kl-tag-horizontal;
    border: 1px solid rgba(111, 119, 124, 0.6);
    background-color: rgba(111, 119, 124, 0.2);
  }

  &__pre-release {
    @include kl-tag-horizontal;
    border: 1px solid rgba(255, 107, 0, 0.6);
    background-color: rgba(255, 107, 0, 0.2);
  }

  &__recommended {
    @include kl-tag-horizontal;
    border: 1px solid rgba(149, 214, 0, 0.6);
    background-color: rgba(149, 214, 0, 0.2);
  }
}
