.feature-categories {
  @include kl-feature-tables;
}

.feature-category {
  @include kl-borderless-table;

  &__feature {
    &__type {
      font-weight: bold;
      vertical-align: top;
      padding-right: 1rem;
      width: 10rem;
    }

    > * {
      padding-top: 1rem;
    }
  }
}
