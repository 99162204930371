.kl-banner {
  padding: 0.8rem;
  background: $arm-light-grey;
  display: flex;

  &__text {
    margin: 0;
    flex: 1;
    text-align: center;

    a {
      @include kl-text-link;
      font-weight: normal;
    }

    i {
      margin-right: 0.25rem;
    }
  }

  &__dismiss {
    border: none;
    background: initial;

    &:hover {
      cursor: pointer;
    }
  }
}
