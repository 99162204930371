.board-tabs,
.vendor-tabs,
.device-tabs,
.family-tabs {
  @include kl-tabs;
  $top-level-class: &;
  margin-bottom: 2em;
  white-space: nowrap;

  a:hover {
    #{$top-level-class}__count {
      color: $arm-black;
      border-color: $arm-black;
    }
  }

  &__count {
    display: inline-block;
    margin-left: 0.3rem;
    background: transparent;
    border-radius: 2em;
    color: $arm-blue;
    text-align: center;
    font-size: 12px;
    border: 1px solid $arm-blue;
    padding: 0 5px;
    min-width: 20px;

    &--empty {
      color: darken($arm-grey, 30%);
      border-color: darken($arm-grey, 30%);
    }
  }

  &--selected #{$top-level-class}__count {
    background: transparent;
    border: 1px solid $arm-black;
    color: $arm-black;
  }
}
