.pack-versions {
  &__title {
    font-weight: bold;
  }

  &__version-header {
    font-weight: bold;
    line-height: 1.5;
  }

  &__versions-container {
    margin-top: 1.2rem;
    border-bottom: 1px solid $arm-light-grey;
  }

  &__release-notes {
    font-family: "Lato";
    white-space: pre-line;
    padding: 0.7rem 0rem 1.4rem 0.7rem;
    margin: 0rem 0rem;
  }

  &__version-header-container {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
  }

  &__pack-cta {
    @include pack-cta;
  }
}

.pack-release-link {
  @include kl-text-link;
  font-weight: normal;

  &__icon {
    margin-right: 0.1rem;
  }
}
