.board-pagination {
  display: flex;
  justify-content: center;

  @mixin item {
    margin: 0 10px;
    line-height: 36px;
    width: 38px;

    text-align: center;
  }

  @mixin link {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  @mixin circle {
    border-radius: 50%;
    border: 1px solid $arm-black;
  }

  @mixin light-circle {
    @include circle;
    background-color: $arm-light-grey;
    color: $arm-black;
  }

  @mixin dark-circle {
    @include circle;
    background-color: $arm-black;
    color: $arm-light-grey;
  }

  @mixin disabled-circle {
    background-color: $arm-grey;
    color: $arm-light-grey;
    border-color: $arm-grey;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    list-style: none;
  }

  &__page {
    @include item;
    color: $arm-blue;

    a {
      @include link;
    }

    &:hover {
      color: $arm-orange-web-safe;
    }

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &__current_page {
    @include item;
    @include dark-circle;
    cursor: default;
  }

  &__next,
  &__previous {
    @include item;
    @include light-circle;

    a {
      @include link;
    }

    &:hover {
      @include dark-circle;
    }

    &--disabled {
      @include disabled-circle;

      &:hover {
        @include disabled-circle;
      }
    }
  }

  &__spacer {
    @include item;
    cursor: default;

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }
}
