.board-summary {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas:
    "header image"
    "components image";

  @media (max-width: 40rem) {
    grid-template-columns: auto;
    grid-template-areas:
      "header"
      "image"
      "components";
  }

  &__header {
    grid-area: header;
    display: flex;
    justify-content: space-between;

    @media (max-width: 40rem) {
      margin-bottom: 1rem;
    }
  }

  &__image {
    grid-area: image;

    @include small-screen {
      justify-self: end;
    }
  }

  &__components {
    grid-area: components;

    @media (max-width: $breakpoint-sm) {
      overflow: scroll;
    }
  }

  a {
    @include kl-text-link($arm-black);
    font-weight: normal;
  }
}

.board-header {
  &__container {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }

  &__title {
    @include kl-large-header;
    white-space: break-spaces;
    overflow-wrap: anywhere;
  }

  &__revision {
    color: darken($keil-grey, 40%);
    margin: 0;
  }

  &__vendor {
    display: inline-block;
  }
}

.board-components {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 2rem 0;

  &__item {
    padding: 0 1.5rem;
    border-right: $border-style;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: 40rem) {
      padding: 0 1rem;
    }
  }

  &__title {
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    color: darken($keil-grey, 40%);
  }

  &__value--pre-wrap {
    white-space: pre-wrap;
  }

  &__value--large-text {
    font-size: 22px;
    margin: 0;

    @media (max-width: 40rem) {
      font-size: 18px;
    }
  }

  &__value--tag {
    @include kl-tag-vertical;
    vertical-align: sub;
  }
}

.board-image {
  border: $border-style;
  border-radius: 4px;
  width: 220px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  overflow: hidden;

  &--empty {
    padding: 0;
  }

  .board-image__image {
    max-width: 100%;
    max-height: 100%;
  }
}

.board-breadcrumbs {
  @include kl-breadcrumbs;
}

.boards-page-info {
  &__url {
    @include kl-text-link-light;
  }
}
