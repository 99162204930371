.support {
  @include kl-centred-content();
  font-size: 1.1rem;

  a {
    @include kl-text-link-light;
  }

  &-header {
    h1 {
      @include kl-large-header;
      margin-bottom: 0.8rem;
    }

    margin-top: 2rem;
  }

  &-intro {
    @include small-screen {
      margin-bottom: 0;
    }
  }

  .support-uvision,
  .support-ksc,
  .support-ks-vs-code,
  .support-cmsis-toolbox,
  .support-mdk {
    padding: 1rem 0;

    @include small-screen {
      padding: 2rem 0 3rem;
    }

    &__title {
      @include kl-small-header;
      margin-bottom: 1.5rem;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }
    }

    &__image,
    &__resources,
    &__issues,
    &__videos {
      flex: 1;

      @media (max-width: $breakpoint-sm) {
        margin-bottom: 1.5rem;
      }
    }

    &__image {
      img {
        @include kl-box-shadow();
        border-radius: 5px;
      }
    }

    &__resources,
    &__issues,
    &__videos {
      ul {
        margin: 0;
        padding-left: 22px;
        font-size: 1rem;

        li {
          margin: 8px 0;
        }
      }
    }
  }
}

.support-ks-vs-code-table {
  @include kl-table;
  font-size: 16px;
  margin: 1rem 0 3rem;

  td {
    padding: 1rem 1rem;
  }

  th {
    padding: 1rem 1rem;
  }

  thead th {
    font-weight: 700;
    background-color: $arm-light-grey-alt;
  }

  tr:hover {
    background-color: $arm-light-grey-alt;
  }

  a.support-ks-vs-code-table__report {
    font-weight: normal;

    i {
      margin-right: 0.3rem;
    }
  }
}
