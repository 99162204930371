@mixin vscode-code-block {
  @include kl-code-text;
  padding: 5px 30px 5px 10px;

  pre {
    margin: 0;
  }

  &-key {
    color: $arm-blue;
  }

  &-value {
    color: $arm-orange-web-safe;
  }
}

@mixin pack-actions {
  list-style-type: none;
  padding-left: 10px;
  margin: 0;

  li {
    padding: 10px 5px;
    border-bottom: 1px solid $arm-grey;

    h4 {
      font-size: 14px;
      color: darken($keil-grey, 40%);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__code {
    @include vscode-code-block;
    position: relative;
    $button-color: $arm-light-grey;

    [data-copy-content-id] {
      display: none;
    }
    [data-copy-button-id] {
      background-color: $button-color;
      transition: background-color 0.3s, transform 0.1s;
      color: inherit;
      border: 1px solid $button-color;
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;
      outline: inherit;
      padding: 4px;

      border-radius: 3px;

      &:hover {
        background-color: darken($button-color, 10%);
      }
      &:active {
        background-color: darken($button-color, 20%);
        transform: scale(0.98);
      }
    }
  }

  a {
    @include kl-text-link;
  }
}

@mixin pack-cta {
  .pack-cta {
    position: relative;

    &__button {
      @include kl-slim-button;
    }

    &:hover,
    &:focus {
      .pack-cta__button {
        color: white;
        background-color: $arm-blue;
      }
    }

    &-actions {
      display: none;
      position: absolute;
      top: 23px;
      @include kl-box-shadow;
      background-color: white;
      padding: 5px 10px;
      border-radius: 5px;
      @include pack-actions;
    }

    &:hover {
      .pack-cta-actions {
        display: block;
        z-index: 1;
      }
    }
  }
}
