.pack-page {
  margin: 2rem 3rem;
  max-width: $page-max-width;
  min-height: calc(100vh - #{$header-height});

  @include extra-large-screen {
    margin: 2rem auto;
    width: 100%;
  }

  @media (max-width: $breakpoint-md) {
    margin: 2rem 1rem;
  }
}
