.device-features {
  &__title {
    &__device {
      text-align: center;

      div {
        text-align: left;
        display: inline-block;
      }

      &__title {
        margin: 0;
      }

      &__name {
        margin: 0;
        font-size: 20px;
        font-weight: normal;
      }
    }
    &__features {
      vertical-align: bottom;
      color: $keil-dark-grey;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__table {
    border: none;
    border-collapse: collapse;
    width: 60%;
    table-layout: fixed;
    margin-bottom: 0.5em;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    &__title {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      border-top: 1px solid;
      border-color: lighten($arm-grey, 5%);
    }

    &__row {
      &:nth-child(odd) {
        background-color: $arm-light-grey-alt;
      }
    }

    td {
      padding: 1rem 0.5rem;

      &:not(:last-child) {
        border-right: $border-style;
      }
    }

    &__head {
      font-weight: bold;
    }

    &__data {
      text-align: center;
    }
  }
}
