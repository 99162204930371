.pack-breadcrumbs {
  @include kl-breadcrumbs;
}

.pack-summary-metadata-row {
  display: flex;
  flex-direction: column;

  @include small-screen {
    flex-direction: row;

    &__description {
      flex-grow: 1;
    }

    &__actions {
      max-width: 30rem;
      flex-shrink: 0;
    }
  }

  .pack-cta-actions {
    @include pack-actions;
  }
}

.pack-summary {
  display: grid;
  grid-template-columns: 3fr;
  grid-template-areas:
    "header image"
    "components image";

  @media (max-width: $breakpoint-sm) {
    grid-template-columns: auto;
    grid-template-areas:
      "header"
      "image"
      "components";
  }

  &__header {
    grid-area: header;
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-sm) {
      margin-bottom: 1rem;
    }
  }

  &__image {
    grid-area: image;

    @include small-screen {
      justify-self: end;
    }
  }

  &__components {
    grid-area: components;
  }

  a {
    @include kl-text-link($arm-black);
    font-weight: normal;
  }
}

.pack-header {
  &__container {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }

  &__title {
    @include kl-large-header;
    white-space: break-spaces;
  }

  &__revision {
    color: darken($keil-grey, 40%);
    margin: 0;
  }

  &__vendor {
    display: inline-block;
  }

  &__download-container {
    a {
      white-space: nowrap;
      font-size: 14px;
      margin: 0;
    }
  }

  &__download {
    @include small-screen {
      margin-left: 1rem;
    }

    @media (max-width: $breakpoint-sm) {
      display: block;
    }

    padding-right: 0.25em;

    &__icon {
      margin-right: 0.1rem;
    }
  }
}

.pack-components {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 2rem 0;

  &__item {
    padding: 0 1.5rem;
    border-right: $border-style;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 0 1rem;
    }
  }

  &__title {
    margin: 0;
    white-space: nowrap;
    font-size: 14px;
    color: darken($keil-grey, 40%);
  }

  &__value--pre-wrap {
    white-space: pre-wrap;
  }

  &__value--large-text {
    font-size: 22px;
    margin: 0;

    @media (max-width: $breakpoint-sm) {
      font-size: 18px;
    }
  }

  &__value--tag {
    @include kl-tag-vertical;
    vertical-align: sub;
  }
}

.pack-family-page-info {
  &__url {
    @include kl-text-link-light;
  }
}
