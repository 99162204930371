.cmsis {
  @include kl-centred-content();
  font-size: 1.1rem;

  &__header {
    @include kl-large-header;
    margin-bottom: 0.8rem;
    align-self: center;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &__info {
    margin-top: 1.2rem;
    margin-bottom: 2rem;
    font-size: 16px;
  }

  &__resources {
    margin-top: 2.5rem;

    h1 {
      @include kl-small-header;
      margin-bottom: 0.8rem;
    }
  }

  a {
    @include kl-text-link-light;
  }

  ul {
    margin-top: 0.5rem;
  }

  li {
    margin-bottom: 0.7rem;
  }
}
