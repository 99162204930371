@use "sass:color";

.board-projects-search {
  @include kl-search-input;
  margin-bottom: 1em;
}

.board-projects-results {
  list-style: none;
  padding: 0;
  margin: 0;
}

.board-project {
  border-radius: 12px;
  border: $border-style;
  margin-bottom: 1rem;

  &:hover {
    box-shadow: 0 2px 4px color.scale($arm-black, $alpha: -90%);
  }

  &__header {
    border-bottom: $border-style;
    padding: 1rem 1.5rem;
  }

  &__title-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__chip-row {
    & > * + * {
      margin-left: 0.5rem;
    }
  }

  &__title {
    @include kl-tiny-header;
  }

  &__body {
    padding: 1rem 1.5rem;
  }

  &__description {
    max-width: 80%;
    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &[data-extended="true"] {
      -webkit-line-clamp: unset;
    }

    + [data-extendable-content-control] {
      @include kl-text-link-light;
      margin: 0;
      padding: 0;
      background-color: unset;
      border: none;
      cursor: pointer;
    }

    @media (max-width: 30rem) {
      max-width: 100%;
    }
  }

  &__compatibility,
  &__toolchains {
    @include kl-hollow-chip;
  }

  &__origin {
    display: block;
    margin-top: 1rem;
    @include kl-text-link;
  }
}

.board-projects-results--no-search-results {
  text-align: center;
  margin-top: 4rem;

  &__title {
    @include kl-small-header;
    margin-bottom: 0.8rem;
    font-weight: lighter;
    color: darken($arm-grey, 15%);
  }
}

.board-projects-download-menu {
  &__dropdown-container {
    position: relative;
    margin-left: auto;

    &:hover {
      .board-projects-download-menu__dropdown-link-content-container {
        display: block;
      }

      .board-projects-download-menu__dropdown-link {
        background-color: $arm-yellow;
      }

      .board-projects-download-menu__dropdown-link-icon::before {
        content: "\f077";
      }
    }

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &__dropdown-link {
    border-radius: 4px;
    border: $border-style;
    border-color: $arm-yellow;
    padding: 0.4rem 1rem;

    &-icon {
      &::before {
        transform: scale(0);
        font: var(--fa-font-light);
        content: "\f078";
        margin-left: 0.2rem;
        font-size: 12px;
      }
    }

    &:hover {
      cursor: pointer;
      color: $arm-black;
    }
  }

  &__hint-link {
    @include kl-text-link;
  }

  &__dropdown-link-content-container {
    position: absolute;
    left: auto;
    right: 0;
    width: 310px;
    display: none;
    z-index: 99;
    padding-top: 6px;
  }

  &__dropdown-link-content-inner-container {
    position: inherit;
    margin: 0 auto 0.5em;
    width: 95%;
  }

  &__dropdown-link-content {
    list-style: none;
    padding: 0rem;
    background-color: $arm-white;
    border-radius: 4px;
    border: $border-style;
    border-color: rgba($arm-black, 0.4);

    a:hover {
      background-color: rgba($arm-light-grey, 0.4);
    }
  }

  &__hint {
    margin-bottom: 0em;
    font-size: 14px;
    color: $arm-dark-grey-web-safe;
    grid-column: 2;

    a {
      @include kl-text-link;
    }
  }

  &__link {
    text-decoration: none;
    color: $arm-black;
    font-size: 1rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1.4em auto;
    padding: 0.5em 1em;

    &:hover {
      color: black;
    }
  }

  &__separator {
    margin: 0 auto;
    width: 85%;
    color: rgba($arm-light-grey, 0.4);
  }
}

.board-projects-uvprojx-container {
  &__content {
    background-color: $arm-light-grey-alt;
    border: none;
  }

  &__title {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    margin-left: 0.8em;
    margin-right: 0.5em;
    padding-top: 0.6em;
    padding-bottom: 0.7em;
    color: $keil-dark-grey;
  }

  &__title-text {
    flex: 1 0 auto;
  }

  &__compatibility-tag {
    @include kl-tag-horizontal;
    background-color: $arm-light-grey;
    color: $keil-dark-grey;
    font-weight: bold;
    font-size: 12px;
  }
}

.board-projects-mdk-banner {
  background-color: lighten($keil-silver, 15%);
  padding-left: 2em;
  padding-top: 1em;
  border-radius: 0.5em;
  margin-bottom: 1em;
  overflow-y: hidden;

  @media (max-width: 30rem) {
    display: none;
  }

  &__header {
    font-size: 24px;
    font-weight: 300;
  }

  &__body {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__link {
    @include kl-text-link;
    margin-bottom: 1em;
  }

  &__close-button {
    float: right;
    border: none;
    background: initial;
    margin-right: 0.8em;

    &:hover {
      cursor: pointer;
    }
  }

  &__image {
    float: right;
    margin-top: 1em;
    margin-left: 4em;
    margin-right: 1.2em;
  }
}
