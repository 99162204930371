@use "sass:color";
.pack-listing__results,
.device-listing__results {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.device-card {
  display: flex;
  padding: 0 1rem;
  border: $border-style;
  border-radius: 12px;
  min-height: 54px;
  text-decoration: none;
  color: $arm-black;

  &:hover {
    box-shadow: 0 2px 4px color.scale($arm-black, $alpha: -90%);
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    width: 100%;
    align-items: center;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  &__name {
    font-weight: normal;
    color: $arm-blue;
    font-size: 16px;

    em {
      font-style: normal;
      font-weight: 900;
    }
  }

  &__cores {
    list-style: none;
    padding: 0;
  }
}

$search-boxes: pack-device-search, device-search;

@each $search-box in $search-boxes {
  .#{$search-box} {
    @include kl-search-input;
    margin-bottom: 1rem;
  }
}

.device-filters {
  margin-bottom: 2em;
}

.device-filters,
.device-listing__results-header {
  display: flex;
  align-items: center;

  .device-filter__dropdown-button,
  .device-filter__dropdown-content {
    width: 210px;
  }

  .device-sort__dropdown-button,
  .device-sort__dropdown-content {
    width: 250px;
  }

  .device-filter,
  .device-sort {
    &:not(:first-child) {
      margin-left: 2rem;
    }

    input:hover,
    label:hover {
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;

      input {
        @include kl-checkbox-input;
      }

      input[type="radio"] {
        @include kl-radio-input;
      }
    }

    &__dropdown-button {
      @include kl-slim-button;
      background: $arm-white;
      border: $border-style;
      color: initial;
      padding: 0.5rem 1rem;
      display: flex;

      &:disabled {
        cursor: default;
        opacity: 0.5;

        &:hover {
          background: $arm-white;
        }
      }

      &-text {
        justify-content: space-between;
        display: flex;
        width: 100%;
      }

      &:focus {
        background: initial;
        color: initial;
      }

      &:hover {
        background-color: rgba($arm-light-grey, 0.4);
        color: initial;
      }
    }

    &__dropdown-content {
      visibility: hidden;
      position: absolute;
      padding: 0.5rem 0;
      margin-top: 2px;
      max-height: 250px;
      overflow-y: auto;
      border: $border-style;
      border-radius: 4px;
      background-color: $arm-white;

      &-results {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          border-radius: 4px;

          & > label {
            padding: 0.25rem 0.5rem;

            &:hover {
              background-color: rgba($arm-light-grey, 0.4);
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: initial;

    .device-filter,
    .device-sort {
      &:not(:first-child) {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }
}

.device-family-list,
.device-subfamily-list,
.device-list {
  list-style-type: none;
  padding: 0;
}

.device-subfamily-list,
.device-list {
  margin: 0 1rem 1rem;
}

.device-family {
  background-color: $arm-light-grey-alt;
}

.device-subfamily {
  background-color: color.scale($arm-white, $alpha: -40%);
}

.device {
  background-color: $arm-white;
}

.device-family,
.device-subfamily,
.device {
  border: $border-style;
  border-radius: 12px;
  text-decoration: none;
  margin-bottom: 1em;
  cursor: pointer;

  em {
    font-style: normal;
    font-weight: 900;
  }

  &:hover {
    box-shadow: 0 2px 4px color.scale($arm-black, $alpha: -90%);

    &[aria-expanded="true"] {
      box-shadow: none;
    }
  }
}

.device-family__details,
.device-subfamily__details,
.device__details {
  padding: 0 1em;
  min-height: 54px;
}

.device__details {
  display: block;
  text-decoration: none;
}

$group-types: device-family, device-subfamily;

@each $group-type in $group-types {
  .#{$group-type} {
    &__details {
      color: $arm-black;
      display: grid;
      align-items: center;

      &::before {
        display: inline-block;
        font: var(--fa-font-regular);
        width: 1em;
        content: "\f105";
      }
    }

    &[aria-expanded="true"] {
      .#{$group-type}__details {
        &::before {
          content: "\f107";
        }
      }
    }
  }
}

.device {
  &__name {
    color: $arm-blue;
  }

  &__cores {
    color: $arm-black;
    list-style: none;
    padding: 0;
  }
}

.device-family,
.device-subfamily {
  &__view-all-button {
    @include kl-yellow-button;
    margin: auto;
  }

  &__count {
    border: $border-style;
    border-color: $keil-silver;
    border-radius: 4px;
    padding: 0.2em 0.3em;
    color: $keil-dark-grey;
    text-align: center;
    min-width: 100px;
    @media (max-width: $breakpoint-sm) {
      visibility: hidden;
    }
  }

  &__links {
    display: flex;
    margin-bottom: 1em;
  }
}

.device-family__details {
  grid-template-columns: 1rem 15rem 15rem max-content;
  @media (max-width: $breakpoint-sm) {
    grid-template-columns: 1rem 8.5rem max-content 0rem;
  }
}

.device-subfamily__details {
  grid-template-columns: 1rem 14rem 15rem max-content;
}

.device__details {
  display: grid;
  grid-template-columns: auto 15rem;
  @media (max-width: $breakpoint-sm) {
    grid-template-columns: auto auto;
  }
  align-items: center;
}
