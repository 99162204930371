.keil-footer {
  background: $arm-black-alt;
  padding: 0;
  font-size: 14px;

  &__container {
    max-width: $page-max-width;
    margin: 2rem auto;
    padding: 0 1rem;

    @include small-screen {
      padding: 0 2rem;
    }
  }

  hr {
    width: 100%;
    margin: 2rem 0;
    border: 0;
    border-bottom: 1px solid $arm-white;
  }

  &-links {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;

    @include medium-screen {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &-collection {
      @include medium-screen {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 2rem;
      }

      & > :first-child {
        margin-bottom: 2rem;

        @include medium-screen {
          margin-bottom: 0;
        }
      }
    }

    h2 {
      @include kl-tiny-header;
      margin-bottom: 0.8rem;
      color: $arm-dark-grey;
    }

    a {
      @include kl-text-link($arm-orange);
      color: $arm-white;
      font-weight: normal;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 0.5rem;
    }
  }

  img {
    float: right;
    height: 2rem;
    width: auto;
  }

  &__legal-links {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;

    li {
      @include medium-screen {
        &:after {
          content: "|";
          color: $arm-white;
        }
      }

      &:last-child:after {
        content: none;
      }
    }

    a {
      @include kl-text-link;
      color: $arm-dark-grey;
      padding-right: 0.5rem;
      white-space: nowrap;
      font-weight: normal;
    }
  }

  &__copyright {
    margin-top: 2rem;
    color: $arm-dark-grey;
  }
}
