[data-accordion] {
  & > [data-accordion-content] {
    display: none;
  }

  &[aria-expanded="true"] {
    & > [data-accordion-content] {
      display: block;
    }
  }
}
