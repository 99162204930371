.board-page-content--empty {
  margin-top: 4rem;
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    margin-top: 2rem;
  }

  &__title {
    @include kl-small-header;
    margin-bottom: 0.8rem;
    font-weight: lighter;
    color: darken($arm-grey, 15%);
  }

  &__text {
    margin: 0 auto;
    max-width: 525px;

    a {
      @include kl-text-link;
      font-weight: normal;
    }
  }

  &__cta {
    @include kl-yellow-button;
    margin-top: 2rem;

    @media (max-width: $breakpoint-sm) {
      margin-top: 1rem;
    }
  }
}
