.keil-bulleted-list {
  list-style-type: none;
  padding: 0 0.5rem;

  & > * + * {
    margin-top: 0.5rem;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
