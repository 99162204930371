.km6-editions-table {
  margin: 3rem;
  border-collapse: collapse;
  border-style: hidden;
  text-align: left;
  display: none;

  th,
  td {
    padding: 1rem;
    border: $border-style;
  }

  @include small-screen {
    display: table;
  }

  &__header {
    th {
      position: sticky;
      top: $header-height;
      background-color: $arm-light-grey-alt;
      max-width: 20rem;
      width: 25%;
      vertical-align: top;
    }

    h3 {
      @include kl-small-header;
    }

    p {
      font-weight: normal;
      margin-bottom: 0.5rem;
    }
  }

  &__subheader {
    @include kl-tiny-header;
    background-color: $keil-alt-grey;
  }

  &__cta {
    @include kl-cta-button;
    width: 90%;
  }

  &-feature {
    &__name {
      a {
        @include kl-text-link;
        font-weight: normal;
        text-decoration: underline;
      }

      p {
        font-weight: normal;
      }
    }

    &__value {
      text-align: center;

      & > .fa-minus {
        color: $keil-silver;
      }
    }
  }
}
