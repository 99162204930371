@use "sass:color";

.board-listing,
.device-listing,
.vendor-board-listing,
.device-board-listing {
  margin-top: 0;

  &-header {
    background: $arm-white;
    padding: 1rem 0;
  }

  &__title {
    @include kl-large-header;
    margin: 1rem 0;
  }

  &__about-cmsis {
    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &__results {
    display: grid;
    list-style: none;
    padding: 0;
    column-gap: 2rem;
    row-gap: 1.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &-header {
      display: flex;

      h2 {
        @include kl-small-header;
        white-space: nowrap;
        margin: 1rem 0;
        flex: 1;
        margin: 0;
      }

      &__count {
        margin-left: 0.1rem;
        font-weight: normal;
        font-size: 16px;
      }
    }

    @media (max-width: 60rem) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $breakpoint-sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__results--empty {
    margin: 2rem 0 0.8rem 0;
    @include kl-small-header;
    font-weight: lighter;
    color: darken($arm-grey, 15%);
    text-align: center;
  }
}

.board-card {
  display: flex;
  padding: 1rem;
  border: $border-style;
  border-radius: 12px;
  min-height: 150px;
  text-decoration: none;

  &:hover {
    box-shadow: 0 2px 4px color.scale($arm-black, $alpha: -90%);
  }

  &__details {
    flex: 1;
    height: 100%;
    padding-right: 1rem;
  }

  &__vendor {
    @include kl-text();
    color: $keil-dark-grey;
    margin-bottom: 8px;
  }

  &__revision {
    @include kl-text();
    color: $keil-dark-grey;
    margin-bottom: 8px;
  }

  &__name {
    font-size: 1rem;
    font-weight: normal;
    color: $arm-blue;
    margin-bottom: 5px;

    em {
      font-style: normal;
      font-weight: 900;
    }
  }

  &__projects-count {
    @include kl-text();
    margin-bottom: 0;
  }

  &__image {
    border-radius: 4px;
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

$search-boxes: pack-search, board-search, pack-board-search,
  device-vendor-search;

@each $search-box in $search-boxes {
  .#{$search-box} {
    @include kl-search-input;
    margin-bottom: 1rem;
  }
}

.pack-filters,
.board-filters {
  margin-bottom: 2em;
}

.device-filter__search-bar,
.pack-filter__search-bar,
.board-filter__search-bar {
  display: block;
  margin: 0 0.5rem;
  margin-bottom: 0.2rem;

  i {
    margin-top: 0.1em;
    margin-left: 0;
  }

  input {
    padding: 0.3em 0.3em 0.3em 2.5em;
  }
}

.device-filters__search-icon,
.pack-filters__search-icon,
.board-filters__search-icon {
  margin-top: 0.1em;
  margin-left: 0.3em;
}

.pack-filter__dropdown-button,
.pack-filter__dropdown-content {
  width: 210px;
}

.board-filters,
.pack-filters,
.pack-listing__results-header,
.board-listing__results-header {
  display: flex;
  align-items: center;

  .board-filter__dropdown-button,
  .board-filter__dropdown-content {
    width: 210px;
  }

  .pack-sort__dropdown-content,
  .pack-sort__dropdown-button,
  .board-sort__dropdown-button,
  .board-sort__dropdown-content {
    width: 250px;
  }

  .pack-filter,
  .pack-sort,
  .board-filter,
  .board-sort {
    &:not(:first-child) {
      margin-left: 2rem;
    }

    input:hover,
    label:hover {
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;

      input {
        @include kl-checkbox-input;
      }

      input[type="radio"] {
        @include kl-radio-input;
      }
    }

    &__dropdown-button {
      @include kl-slim-button;
      background: $arm-white;
      border: $border-style;
      color: initial;
      padding: 0.5rem 1rem;
      display: flex;

      &:disabled {
        cursor: default;
        opacity: 0.5;

        &:hover {
          background: $arm-white;
        }
      }

      &-text {
        justify-content: space-between;
        display: flex;
        width: 100%;
      }

      &:focus {
        background: initial;
        color: initial;
      }

      &:hover {
        background-color: rgba($arm-light-grey, 0.4);
        color: initial;
      }
    }

    &__dropdown-content {
      visibility: hidden;
      position: absolute;
      padding: 0.5rem 0;
      margin-top: 2px;
      max-height: 250px;
      overflow-y: auto;
      border: $border-style;
      border-radius: 4px;
      background-color: $arm-white;

      &-results {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          border-radius: 4px;

          & > label {
            padding: 0.25rem 0.5rem;

            &:hover {
              background-color: rgba($arm-light-grey, 0.4);
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: initial;

    .board-filter,
    .pack-filter,
    .pack-sort,
    .board-sort {
      &:not(:first-child) {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }
}

.pack-card {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border: $border-style;
  border-radius: 12px;
  min-height: 54px;
  text-decoration: none;
  color: $arm-black;

  &:hover {
    box-shadow: 0 2px 4px color.scale($arm-black, $alpha: -90%);
  }

  &__header {
    display: flex;
    margin-bottom: 1rem;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }

  &__title {
    flex: 1 0 auto;
  }

  &__name,
  &__vendor {
    margin-right: 1rem;
  }

  &__name {
    color: $arm-blue;

    em {
      font-style: normal;
      font-weight: 900;
    }
  }

  &__vendor {
    color: $arm-dark-grey-web-safe;
  }

  &__download {
    a {
      @include kl-text-link($arm-orange-web-safe);
      font-weight: normal;

      i {
        margin-right: 0.1rem;
      }
    }
  }

  &__description {
    color: $arm-black;
    margin-bottom: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__related-boards {
    @include kl-hollow-chip-link;
    margin-right: 1.5rem;
  }

  &__related-devices {
    @include kl-hollow-chip-link;
  }
}

.pack-listing {
  margin-top: 0;

  &__about-cmsis {
    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &-header {
    background: $arm-white;
    padding: 1rem 0;
  }

  &__title {
    @include kl-large-header;
    margin: 1rem 0;
  }

  &__results {
    display: grid;
    list-style: none;
    padding: 0;
    column-gap: 2rem;
    row-gap: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &-header {
      display: flex;

      h2 {
        @include kl-small-header;
        white-space: nowrap;
        margin: 1rem 0;
        flex: 1;
        margin: 0;
      }

      &__count {
        margin-left: 0.1rem;
        font-weight: normal;
        font-size: 16px;
      }
    }

    @media (max-width: 60rem) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $breakpoint-sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__results--empty {
    margin: 2rem 0 0.8rem 0;
    @include kl-small-header;
    font-weight: lighter;
    color: darken($arm-grey, 15%);
    text-align: center;
  }
}
