.km6-community-strip {
  @include kl-banner-strip-first-primary;
}

.km6-community-header,
.km6-community-uvision,
.km6-community-vscode,
.km6-community-conclusion {
  @include kl-centred-content;
  margin-bottom: 4rem;
}

.km6-community-header {
  &__title {
    @include kl-extra-large-header;
    color: $arm-blue;
    margin-bottom: 1.5rem;
  }

  &__description {
    @include kl-bigger-paragraph-text;
  }

  a {
    @include kl-text-link-light;
  }
}

.km6-community-uvision,
.km6-community-vscode {
  &__title {
    @include kl-small-header;
    margin-bottom: 1rem;
  }

  img {
    margin: 2rem auto;
  }
}

.km6-installation-instructions {
  margin: 0;
  padding: 0 0 0 1rem;
  font-size: 18px;

  li {
    margin-bottom: 1rem;

    p a {
      @include kl-text-link-light;
    }
  }
}

.km6-installation-instructions-download {
  &__button {
    @include kl-yellow-button;
    width: 225px;
    text-align: center;

    i {
      margin-right: 0.1em;
    }
  }

  &__requirements {
    color: $arm-dark-grey;
    font-size: 0.9rem;
    margin-left: 0.5rem;
    white-space: nowrap;
  }
}

.km6-community-conclusion {
  &__title {
    @include kl-small-header;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 18px;
  }

  a {
    @include kl-text-link;
  }

  video {
    margin: 2rem auto;
  }
}
