.error-page {
  margin: auto;
  text-align: center;

  @media (max-width: 60rem) {
    padding: 0 2rem;
  }

  h1 {
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 0.5rem;

    @media (max-width: 40rem) {
      font-size: 50px;
    }
  }

  &__button {
    @include kl-yellow-button;
    margin-bottom: 2rem;
    min-width: 300px;

    i {
      margin-right: 0.2rem;
      font-size: 0.9rem;
    }
  }
}
