@mixin markdown-content {
  // Headers
  h1 {
    @include kl-large-header;
    margin-top: 1.2em;
    margin-bottom: 0.8em;
  }

  h2 {
    @include kl-medium-header;
    margin-top: 1.1em;
    margin-bottom: 0.7em;
  }

  h3 {
    @include kl-small-header;
    margin-top: 1em;
    margin-bottom: 0.6em;
  }

  h4 {
    @include kl-extra-small-header;
    margin-top: 0.8em;
    margin-bottom: 0.5em;
  }

  h5,
  h6 {
    @include kl-tiny-header;
    margin-top: 0.6em;
    margin-bottom: 0.4em;
  }

  // Paragraphs
  p {
    font-size: 1em;
    line-height: 1.5;
    margin: 1em 0;

    code {
      @include kl-inline-code;
    }
  }

  li > p {
    margin: 0;
  }

  br {
    line-height: 1.5;
  }

  // Links
  a {
    @include kl-text-link;
  }

  // Lists
  ul,
  ol {
    margin: 1em 0;
    padding-left: 1.5em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin: 0.25em 0;

    code {
      @include kl-inline-code;
    }
  }

  // Block tags
  blockquote {
    border-left: 4px solid $keil-silver;
    padding: 0.5em 10px;
    margin: 1.5em 0;
    font-style: italic;
    background-color: $keil-grey;
  }

  code {
    @include kl-code;
  }

  pre {
    background-color: $arm-light-grey-alt;
    padding: 10px;
    border-radius: 3px;
    overflow-x: auto;
  }

  hr {
    border: none;
    border-top: 1px solid $keil-grey;
    margin: 1.5em 0;
  }

  // Tables
  table {
    width: 100%;
    margin: 1.5em 0;
    border-collapse: collapse;

    thead {
      background-color: $keil-grey;
    }

    th,
    td {
      border: 1px solid $keil-silver;
      padding: 8px;
      text-align: left;
    }

    th {
      font-weight: bold;
    }

    tbody {
      tr:nth-child(even) {
        background-color: $arm-light-grey-alt;
      }
    }
  }

  // Images
  img {
    max-width: 100%;
    height: auto;
    margin: 1.5em 0;
  }
}

.guide-markdown,
.pack-overview-markdown {
  @include markdown-content;
}
