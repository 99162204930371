html {
  scroll-padding-top: $header-height;
}

body {
  font-family: "Lato", sans-serif;
  color: $arm-black;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
  display: flex; /* sticky footer */
  flex-direction: column; /* sticky footer */
}

main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* sticky footer */
}

video {
  display: block;
  max-width: 100%;
}

p {
  margin-bottom: 1em;
}
