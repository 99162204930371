#hardware-detector-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

#hardware-detector {
  @include kl-yellow-button();
  margin-right: 10px;
  margin-bottom: 10px;

  i {
    margin: 0 5px;
  }
}

.hardware-detector__error,
.hardware-detector__detected_board,
.hardware-detector__loading {
  background-color: #fff;
  box-shadow: 3px 3px 3px -2px $keil-grey, 3px 3px 3px -2px $keil-grey,
    -3px 3px 3px -2px $keil-grey;
  border-radius: 12px;
  border: 1px solid $keil-grey;
  padding: 1em;
  margin: 0.5em 0;
  margin-right: 0.5em;

  a {
    text-decoration: none;
    font-weight: bold;
  }

  p:last-child {
    margin: 0;
  }
}

.hardware-detector__detected_board__header,
.hardware-detector__detected_board__board {
  margin-bottom: 0.5em;
}

.hardware-detector__detected_board__device {
  overflow: hidden;
  margin-bottom: 1em;
}

.hardware-detector__detected_board__board__label,
.hardware-detector__detected_board__device__label {
  margin-right: 5px;
}

.hardware-detector__detected_board__board__value,
.hardware-detector__detected_board__device__value {
  float: right;
}

.hardware-detector__detected_board__device__value {
  list-style-type: none;
  margin: 0;
}
