// https://www.sketch.com/s/61cd6f5f-cabb-4f17-8e23-6b4b176ee4d5/color-variables
$arm-white: #fff;
$arm-black-alt: #010101;
$arm-black: #333e48;
$arm-blue: #0091bd;
$arm-light-blue: #00c1de;
$arm-orange: #ff6b00;
$arm-blue-web-safe: #11809f;
$arm-orange-web-safe: #c75300;
$arm-dark-grey-web-safe: #5d6c7a;
$arm-grey: #cbcdd0;
$arm-light-grey: #e5eceb;
$arm-light-grey-alt: #f7f7f7;
$arm-dark-grey: #a3a8ae;
$arm-yellow: #ffc700;
$arm-light-yellow: #f6d452;
$arm-green: #95d600;

$keil-silver: #c2c7c9;
$keil-grey: #eee;
$keil-alt-grey: #e1e1e1;
$keil-dark-grey: #6f777c;
