@use "sass:color";

.package-listing {
  &-header {
    padding-bottom: 1rem;
  }

  &__title {
    @include kl-large-header;
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  &__terms {
    background-color: $arm-light-grey-alt;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;

    &-title {
      margin: 0;
    }

    & * + * {
      margin: 0.5rem 0 0 0;
    }
  }

  &-toc {
    margin-bottom: 3rem;

    p {
      margin-bottom: 0.5rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        border: none;
      }
    }
  }

  &__code {
    @include kl-code-text;
  }

  &__results {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    @include kl-text-link;
    font-weight: normal;
  }
}

.package {
  margin-bottom: 3rem;

  &__title {
    font-size: 1.25rem;
    margin-bottom: 1rem;

    @media (max-width: $breakpoint-sm) {
      margin-bottom: 0;
    }
  }
}

.package-versions {
  list-style: none;
  padding: 0;
}

.package-version {
  padding: 1rem 0;
  border-bottom: $border-style;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__snippet {
      visibility: hidden;
      display: flex;
      width: 500px;
      @include kl-copy-button;

      input {
        width: 100%;
      }

      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      user-select: none;

      &::before {
        display: inline-block;
        font: var(--fa-font-light);
        width: 1em;
        font-size: 18px;
        content: "\f105";
      }
    }
  }

  &[aria-expanded="true"] {
    .package-version-header__snippet {
      visibility: visible;
    }

    .package-version-header__title {
      &::before {
        content: "\f107";
      }
    }
  }
}

.package-version-details {
  margin-top: 1rem;
  margin-left: 1rem;

  &__description {
    max-width: 600px;
  }

  &__availability {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: bold;
      margin: 0;
    }
  }

  &__platform {
    @include kl-tag;
    display: inline-block;
    text-wrap: nowrap;
  }
}
