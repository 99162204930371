.km6-editions-header-strip {
  @include kl-banner-strip-first-primary;
}

.km6-editions-tools-strip {
  @include kl-banner-strip-primary;
}

.km6-editions-comparison-strip {
  @include kl-banner-strip-secondary;
}

.km6-editions-header,
.km6-editions-middleware,
.km6-editions-functional-safety,
.km6-editions-comparison,
.km6-editions-whats-included-container {
  @include kl-centred-content;
}

.km6-editions-middleware,
.km6-editions-functional-safety {
  @include kl-text-and-image;

  a {
    @include kl-text-link-light;
  }
}

.km6-editions-whats-included-container,
.km6-editions-middleware {
  margin-bottom: 88px;
}

.km6-editions-header {
  &__title,
  &__subtitle {
    color: $arm-blue;
    margin-bottom: 1.5rem;
  }

  &__title {
    @include kl-extra-small-header;
  }

  &__subtitle {
    @include kl-extra-large-header;
  }

  &__description {
    @include kl-bigger-paragraph-text;
    margin-bottom: 2rem;
  }

  &__cta-list {
    @include kl-cta-list;
  }

  &__features-cta {
    @include kl-cta-button;
    display: none;

    @include small-screen {
      display: initial;
    }
  }

  &__buy-cta {
    @include kl-cta-button-secondary;
  }
}

.km6-editions-whats-included {
  margin-top: 3rem;
  background-color: $arm-light-grey-alt;
  border-radius: 12px;
  padding: 2rem 3rem;

  @include medium-screen {
    background-image: url("../img/mdk6/whats-included.png");
    background-repeat: no-repeat;
    background-position: 400px 110px;
    background-size: 1200px 400px;
  }

  &__title {
    @include kl-large-header;
    margin-bottom: 1rem;
  }

  &__subtitle {
    @include kl-small-header;
    margin-bottom: 0.5rem;
    color: $arm-blue;
  }

  &__features-title {
    font-weight: bold;
  }
}

.km6-editions-ecosystem-list {
  display: grid;
  gap: 2rem;
  grid-template-columns: auto;

  @include small-screen {
    grid-template-columns: repeat(2, auto);
  }

  @include medium-screen {
    grid-template-columns: repeat(3, auto);
  }
}

.km6-editions-ecosystem {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2rem 1.5rem;
  background-color: $arm-white;
  border-radius: 0.5rem;

  i {
    color: $arm-blue;
    font-size: 3rem;
  }

  &__heading {
    @include kl-small-header;
  }

  &__description {
    text-align: center;
    flex-grow: 1;
    margin: 0;
  }

  &__cta {
    @include kl-text-link-cta;
  }
}

.km6-editions-comparison-strip {
  display: none;

  @include small-screen {
    display: block;
  }
}

.km6-editions-functional-safety {
  .keil-bulleted-list {
    @include kl-bigger-paragraph-text;

    & > * + * {
      margin-top: 1.5rem;
    }
  }
}

.km6-editions-comparison {
  text-align: center;

  &__heading {
    @include kl-large-header;
    margin-bottom: 1rem;
  }

  &__table {
    display: inline-block;
    margin: 0 auto;
  }
}
