.km6-pro-header {
  @include kl-hero;
  @include kl-hero-background;
  background-image: url("../img/mdk6/plus_pattern.svg");
  background-position: 750px;

  &__text {
    margin-bottom: 4rem;

    @include medium-screen {
      max-width: 45rem;
    }
  }

  &__cta-list {
    @include kl-cta-list;
  }

  &__learn-cta {
    @include kl-cta-button;
  }

  &__tools-cta {
    @include kl-cta-button-secondary;
  }
}

.km6-pro-header-strip {
  @include kl-banner-strip-primary;
  padding-top: 4rem;
}

.km6-pro-hw-get-started-strip {
  @include kl-banner-strip-primary;
}

.km6-pro-tools-strip,
.km6-editions-discovery-strip {
  @include kl-banner-strip-secondary;
}

.km6-pro-header,
.km6-pro-video,
.km6-pro-hw-get-started,
.km6-pro-tools,
.km6-editions-discovery {
  @include kl-centred-content;
}

.km6-pro-video {
  video {
    margin: 0;
    border-radius: 10px;
  }
}

.km6-pro-hw-get-started {
  @include kl-text-and-image;

  &__title {
    @include kl-large-header;
    margin-bottom: 1.5rem;
  }

  &__description {
    @include kl-bigger-paragraph-text;
    margin-bottom: 1.5rem;

    a {
      @include kl-text-link;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__cta-search {
      width: 100%;
    }

    &__cta-detect {
      display: none;
    }
  }
}

body[data-web-usb-available="true"] .km6-pro-hw-get-started {
  &__cta-detect #hardware-detector {
    @include kl-cta-button;
  }

  &__cta-search {
    @include kl-cta-button-secondary;
  }
}

body[data-web-usb-available="false"] .km6-pro-hw-get-started {
  &__cta-detect {
    display: none;
  }

  &__cta-search {
    @include kl-cta-button;
  }
}

.km6-pro-tools {
  &__title {
    @include kl-large-header;
  }

  &__subtitle {
    @include kl-extra-small-header;
    font-weight: 400;
  }

  &__title,
  &__subtitle {
    margin-bottom: 1rem;
  }

  &__description {
    margin: 0;
  }

  &__compare-link {
    margin-bottom: 1.5rem;
  }

  &__cli-link {
    margin-bottom: 0;
  }

  &__cli-link,
  &__compare-link {
    a {
      @include kl-text-link-light;
    }
  }
}

.km6-pro-tools-list {
  $card-border-radius: 7px 7px 0 0;

  @include kl-cards;
  margin: 0 0 1.5rem 0;

  .km6-pro-tool-vscode,
  .km6-pro-tool-cloud,
  .km6-pro-tool-uvision {
    &__image {
      overflow: hidden;
      border-radius: $card-border-radius;
    }

    img {
      margin-top: 1.1rem;
      margin-left: 1.2rem;
      @include kl-box-shadow();
      border-radius: $card-border-radius;
    }

    &__description {
      min-height: 15rem;
    }
  }

  .km6-pro-tool-vscode {
    @include kl-highlighted-card;

    &__image {
      background-color: $arm-light-yellow;
    }
  }

  .km6-pro-tool-cloud,
  .km6-pro-tool-uvision {
    @include kl-card;

    &__image {
      background-color: $arm-light-grey;
    }
  }
}

.km6-editions-discovery {
  &__heading {
    @include kl-large-header;
    margin-bottom: 1.5rem;
  }

  &__description {
    @include kl-bigger-paragraph-text;
    margin-bottom: 1.5rem;
  }
}
