.license-tabs {
  @include kl-tabs;
  margin: 2rem 0;
}

.license-wrapper {
  width: 100%;
  max-width: $page-max-width;
  margin: 0rem auto;
  padding: 0 2rem;

  pre {
    /* fix long tpip lines */
    white-space: pre-wrap;
  }
}
