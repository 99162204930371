$header-height: 64px;

$page-max-width: 80rem;
$text-section-width: 29rem;

$border-color: darken($keil-grey, 5%);
$border-style: 1px solid $border-color;

// Screen Width Breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

@mixin small-screen {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin medium-screen {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin extra-large-screen {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
