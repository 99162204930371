.vendor-summary {
  display: grid;
  margin-bottom: 2rem;

  grid-template-columns: 3fr 1fr;
  grid-template-areas:
    "title title"
    "details logo";

  @media (max-width: 40rem) {
    grid-template-columns: auto;
    grid-template-areas:
      "title"
      "logo"
      "details";
    margin-bottom: 1rem;
  }

  &__title {
    grid-area: title;
    @include kl-large-header;
    margin-bottom: 0.8rem;
  }

  &__details {
    grid-area: details;
  }

  &__description {
    max-width: 700px;

    p {
      @include kl-text;
    }

    h1 {
      @include kl-large-header;
      margin-bottom: 0.8rem;
    }

    h2 {
      @include kl-medium-header;
      margin-bottom: 0.8rem;
    }

    h3 {
      @include kl-small-header;
      margin-bottom: 0.8rem;
    }

    a {
      @include kl-text-link;
    }
  }

  &__url {
    @include kl-text-link;
  }

  &__logo {
    grid-area: logo;
    height: auto;
    margin-left: 5em;

    @media (max-width: 40rem) {
      margin-left: 0;
      margin-bottom: 1em;
    }
  }
}

.vendor-breadcrumbs {
  @include kl-breadcrumbs;
}
