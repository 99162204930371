.device-processors-table {
  width: 60%;
  table-layout: fixed;
  border-spacing: 0;

  @media (max-width: $breakpoint-md) {
    width: 100%;
  }

  th {
    padding-bottom: 0.8rem;
  }

  &__col-header {
    &--small {
      font-size: 1rem;
      color: $keil-dark-grey;
      text-align: left;
      vertical-align: bottom;
    }

    &--large {
      font-size: 20px;
      color: $arm-black;
      font-weight: normal;
      text-align: center;

      div {
        display: inline-block;
        text-align: left;
      }

      p {
        margin: 0;
      }
    }
  }

  &__col-subheader {
    font-weight: bold;
    font-size: 14px;
  }

  tr {
    margin: 0;
    padding: 0;

    &:nth-child(even) {
      background-color: $arm-light-grey-alt;
    }
  }

  td {
    padding: 1rem 0.5rem;
    border-collapse: collapse;
    margin: 0;

    &:not(:last-child) {
      border-right: $border-style;
    }
  }

  &__name {
    font-weight: bold;
  }

  &__value {
    text-align: center;

    i {
      opacity: 0.6;
    }
  }
}
