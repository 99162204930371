@use "sass:color";

@mixin text-line-length-limiter {
  max-width: 48rem;
  margin: 0 auto;
}

@mixin kl-text-link($color-hover: $arm-orange-web-safe) {
  text-decoration: none;
  color: $arm-blue;
  font-weight: bold;

  &:hover {
    color: $color-hover;
  }
}

@mixin kl-text-link-light {
  font-weight: normal;
  text-decoration: underline;
  color: $arm-blue-web-safe;

  &:hover {
    color: $arm-orange-web-safe;
  }
}

@mixin kl-text-link-cta {
  @include kl-text-link-light;
  text-decoration: none;

  &::after {
    font: var(--fa-font-light);
    content: "\f105";
    color: $arm-yellow;
    margin-left: 0.25rem;
  }
}

@mixin kl-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@mixin kl-tag {
  background-color: darken($keil-grey, 5%);
  border-radius: 5px;
  padding: 0 4px;
  font-size: 14px;
  margin: 0;
  overflow-wrap: anywhere;
  color: $arm-black;
}

@mixin kl-tag-vertical {
  @include kl-tag;
  display: flex;
  margin: 5px 0;
}

@mixin kl-tag-horizontal {
  @include kl-tag;
  display: inline-block;
}

@mixin kl-label {
  @include kl-tag;
  background: none;
  border: 1px solid $keil-dark-grey;
  color: $keil-dark-grey;

  width: auto;

  padding: 2px 7px;
}

@mixin kl-centred-content {
  padding: 0 2rem;
  max-width: $page-max-width;
  margin: 0 auto;

  @media (max-width: $breakpoint-sm) {
    padding: 0 1rem;
    max-width: 100%;
  }
}

/* Buttons */

@mixin kl-button($background, $background-hover, $color, $color-hover: $color) {
  padding: 0.6em;
  border-radius: 28px;
  background-color: $background;
  color: $color;
  text-decoration: none;
  font-size: 16px;
  border: 2px solid transparent;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;

  @include medium-screen {
    white-space: nowrap;
  }

  &:hover,
  &:focus {
    background-color: $background-hover;
    color: $color-hover;
  }
}

@mixin kl-primary-button {
  $background: $arm-white;
  $background-hover: $arm-blue;
  $color: $arm-blue;
  $color-hover: $arm-white;
  @include kl-button(
    $background: $background,
    $background-hover: $background-hover,
    $color: $color,
    $color-hover: $color-hover
  );
}

@mixin kl-secondary-button {
  $background: transparent;
  $background-hover: $arm-light-grey;
  $color: $arm-black;
  @include kl-button(
    $background: $background,
    $background-hover: $background-hover,
    $color: $color
  );
  border: 1px solid $arm-black;
  text-decoration: none;
}

@mixin kl-slim-button {
  @include kl-primary-button;
  border-radius: 4px;
  border: 1px solid $arm-blue;
  padding: 4px 0.5em;
}

@mixin kl-yellow-button {
  $background: $arm-yellow;
  $background-hover: $arm-light-yellow;
  $color: $arm-black;
  @include kl-button(
    $background: $background,
    $background-hover: $background-hover,
    $color: $color
  );
}

@mixin kl-cta-button() {
  @include kl-yellow-button;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  text-align: center;
}

@mixin kl-cta-button-secondary() {
  @include kl-cta-button;
  border: 2px solid $arm-yellow;
  color: $arm-black;
  background: none;
}

@mixin kl-cta-list($cta-width: 15rem) {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > * {
    width: 100%;

    @include small-screen {
      width: $cta-width;
    }
  }

  @include small-screen {
    flex-direction: row;
  }
}

/* Font sizes */
@mixin extra-large-font {
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
}

@mixin large-font {
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

@mixin medium-font {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
}

@mixin small-font {
  font-size: 27px;
  line-height: 32px;
  font-weight: 700;
}

@mixin extra-small-font {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

@mixin tiny-font {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

/* Headers */
@mixin kl-extra-large-header {
  @include large-font;

  @include small-screen {
    @include extra-large-font;
  }
}

@mixin kl-large-header {
  @include medium-font;

  @include small-screen {
    @include large-font;
  }
}

@mixin kl-medium-header {
  @include small-font;

  @include small-screen {
    @include medium-font;
  }
}

@mixin kl-small-header {
  @include small-font;
}

@mixin kl-extra-small-header {
  @include extra-small-font;
}

@mixin kl-tiny-header {
  @include tiny-font;
}

/* Strips */
$strip-section-spacing: 5.5rem;

@mixin kl-banner-strip-first-primary {
  padding: 3rem 0;
}

@mixin kl-banner-strip-primary {
  padding: 5.5rem 0;
}

@mixin kl-banner-strip-secondary {
  padding: 3rem 0 4rem 0;
  background-color: $arm-light-grey-alt;
}

/* Banners */
@mixin kl-hero {
  &__text {
    margin-right: 3rem;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    @include kl-extra-large-header;
    margin-bottom: 1.5rem;
    color: $arm-blue;
  }

  &__cta {
    @include kl-cta-button;
    margin-bottom: 2rem;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}

@mixin kl-hero-background {
  background-image: url("../img/keil-studio/hero-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 5rem 0 3rem;

  @media (max-width: $breakpoint-md) {
    background-image: none;
  }
}

@mixin kl-text-and-image {
  $vertical-spacing: 24px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10%;
  }

  &__text,
  &__image {
    flex: 0 0 45%;
  }

  &__text {
    & > * + * {
      margin-top: $vertical-spacing;
    }

    p {
      @include kl-bigger-paragraph-text;
      margin-bottom: 0;
    }
  }

  &__note {
    @include kl-tiny-header;
    color: $arm-blue;
    margin-bottom: 2rem;

    i {
      margin-right: 0.5rem;
    }
  }

  &__heading {
    @include kl-large-header;
    margin-bottom: 0.8rem;
  }

  &__image {
    order: 1;
    margin-top: $vertical-spacing;

    img {
      height: auto;
    }
  }

  @include medium-screen {
    &__content {
      flex-direction: row;
    }

    &__text,
    &__image {
      order: unset;
      margin: 0;
    }
  }
}

@mixin kl-cards {
  display: grid;
  gap: 3rem;
  grid-template-columns: auto;
  padding: 0;

  @include medium-screen {
    grid-template-columns: repeat(2, auto);
  }

  @include large-screen {
    grid-template-columns: repeat(3, auto);
  }

  li {
    list-style-type: none;
  }
}

@mixin kl-card {
  border-radius: 10px;
  border: 1px solid $arm-light-grey;
  background-color: $arm-white;

  &__image {
    height: 170px;
    width: 100%;
  }

  &__description {
    padding: 1.5rem 1rem;
    border-radius: 0 0 5px 5px;
    color: $arm-black;

    i {
      font-size: 18px;
      margin-bottom: 1rem;
    }

    p {
      min-height: 8rem;
    }
  }

  &__title {
    @include kl-small-header;
    font-weight: normal;
    margin-bottom: 0.75rem;
  }

  &__cta {
    @include kl-cta-button-secondary;
    width: 100%;
  }
}

@mixin kl-highlighted-card {
  @include kl-card;
  border: 2px solid $arm-yellow;

  &__cta {
    @include kl-cta-button;
  }
}

@mixin kl-cards-deprecated {
  @include small-screen {
    display: flex;
    justify-content: space-between;
  }

  padding: 0;

  li {
    list-style-type: none;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 1rem;
    }

    @include small-screen {
      width: 30%;
    }
  }
}

@mixin kl-card-deprecated {
  border-radius: 10px;
  border: 1px solid $arm-light-grey;

  &__image {
    height: 170px;
    width: 100%;
  }

  &__tags {
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    li {
      @include kl-label;
    }
  }

  &__description {
    position: relative;
    padding: 5px 15px;
    border-radius: 0 0 5px 5px;
    color: $arm-black;
  }

  &__title {
    @include kl-small-header;
    font-weight: normal;
    margin: 0.6rem 0;
  }

  &__cta-strip {
    margin: 0 1rem 1.5rem;
  }

  &__cta {
    @include kl-cta-button-secondary;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}

/* Text */
@mixin kl-bigger-paragraph-text {
  font-size: 1.25rem;
}

@mixin kl-text {
  font-size: 14px;
  color: $keil-dark-grey;
  line-height: 24px;
  text-align: justify;
}

@mixin kl-code {
  font-family: Monaco, monospace;
  font-size: 14px;
  background-color: $arm-light-grey-alt;
  border-radius: 4px;
}

@mixin kl-inline-code {
  @include kl-code;
  padding: 0.1rem 0.2rem;
  color: $arm-blue-web-safe;
}

@mixin kl-code-text {
  @include kl-code;
  padding: 0.1rem 0.2rem;
  border: $border-style;
}

@mixin kl-tabs {
  border-bottom: $border-style;

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;

    li {
      margin: 0 1.5em;
      padding: 0.5em 0;
    }

    li:first-child {
      margin-left: 0;
    }

    @media (max-width: 30rem) {
      justify-content: space-between;

      li {
        margin: 0;
      }
    }
  }

  a {
    @include kl-text-link;
    font-weight: bold;
    font-size: 18px;
    color: $arm-blue;
    display: flex;
    align-items: center;

    &:hover {
      color: $arm-black;
    }

    @media (max-width: $breakpoint-sm) {
      font-size: 16px;
    }
  }

  &--selected {
    border-bottom: 5px solid $arm-blue;

    a {
      color: $arm-black;
    }
  }
}

@mixin kl-breadcrumbs {
  display: flex;
  list-style: none;
  margin: 0;
  margin-bottom: 0.5em;
  padding: 0;

  li {
    color: $keil-dark-grey;

    &:last-child {
      color: $arm-black;
    }

    a {
      @include kl-text-link($arm-black);
      font-weight: normal;
    }
  }

  i {
    margin: 0 0.3rem;
    align-self: center;
  }
}

/* Tables */
@mixin kl-borderless-table {
  border-collapse: collapse;
  width: 100%;

  &__title {
    font-size: 18px;
    color: darken($keil-grey, 40%);
    border-bottom: $border-style;
  }
}

@mixin kl-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    text-align: left;

    th {
      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  td,
  th {
    border: 1px solid $keil-silver;
    border-left: none;

    &:first-child {
      border-left: 1px solid $keil-silver;
    }
  }

  td {
    border-top: none;
  }
}

@mixin kl-feature-tables {
  display: grid;
  list-style: none;
  padding: 0;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: repeat(2, auto);

  @media (max-width: $breakpoint-sm) {
    grid-template-columns: auto;
  }
}

/* Inputs */
@mixin kl-text-input {
  border-radius: 22px;
  border: $border-style;
  padding: 0.5rem 1rem;
  width: 100%;
  text-overflow: ellipsis;
  outline: none;
  appearance: none;
  margin: 0;

  &::placeholder {
    color: darken($keil-grey, 20%);
  }

  &:focus {
    border-color: $arm-blue;
    color: $arm-black;
  }
}

@mixin kl-search-input {
  $container: &;
  display: flex;
  position: relative;

  &:focus-within {
    #{$container}__suggestions {
      display: block;
    }

    #{$container}__input--with-items {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent;
    }
  }

  &__input {
    @include kl-text-input;
    padding-left: 2.5rem;

    &:focus {
      border: $border-style;

      & + #{$container}__suggestions {
        display: block;
      }
    }

    &--with-items:focus {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent;
    }
  }

  &__icon {
    position: absolute;
    align-self: center;
    padding: 0.5rem;
    padding-left: 1rem;
    color: $arm-black;
  }

  &__suggestions {
    display: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;

    list-style-type: none;
    background-color: $arm-white;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    box-shadow: 3px 3px 3px -2px $keil-grey, 3px 3px 3px -2px $keil-grey,
      -3px 3px 3px -2px $keil-grey;

    #{$container}__suggestion {
      border-left: $border-style;
      border-right: $border-style;
      background-color: $arm-white;

      a,
      div {
        display: block;
        padding: 0.5rem 1.5rem;
        text-decoration: none;
        color: $arm-black;
        cursor: pointer;

        i {
          float: right;
          line-height: inherit;
          padding: 0 0.5em;

          &:hover {
            color: $arm-white;
          }
        }
      }

      &:last-child {
        border-bottom: $border-style;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
      }

      &--focused,
      &:active {
        background-color: $arm-light-grey;
      }
    }

    #{$container}__suggestion-title {
      border-left: $border-style;
      border-right: $border-style;
      background-color: $arm-white;
      padding: 0.5rem 1.5rem;
      color: $keil-dark-grey;
      font-weight: bold;
      cursor: default;

      &:before {
        content: "";
        position: absolute;
        left: 5%;
        top: 0;
        height: 1px;
        width: 90%;
        border-bottom: $border-style;
      }
    }

    hr {
      border-top: $border-style;
      margin-top: 0;
      margin-bottom: 0;
      width: 80%;
    }
  }
}

@mixin kl-checkbox-input {
  &[type="checkbox"] {
    appearance: none;
    margin: 0;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    border: $border-style;
    border-radius: 2px;
    display: grid;
    place-content: center;

    &:checked {
      background: $arm-blue;
      border: none;
    }
  }

  &[type="checkbox"]::before {
    content: "";
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    font: var(--fa-font-regular);
    font-size: 12px;
    content: "\f00c";
    color: $arm-white;
  }

  &[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  &[type="checkbox"]:focus {
    border-color: $arm-blue;
  }
}

@mixin kl-radio-input {
  appearance: none;
  margin: 0;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  border: $border-style;
  border-radius: 50%;
  display: grid;
  place-content: center;

  &:checked {
    background: $arm-blue;
    border: none;
  }

  &::before {
    content: "";
    transform: scale(0);
    font: var(--fa-font-solid);
    font-size: 8px;
    content: "\f111";
    color: $arm-white;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:focus {
    border-color: $arm-blue;
  }
}

@mixin kl-copy-button {
  display: flex;

  & > * {
    @include kl-code-text;
    appearance: none;
    outline: none;
  }

  input {
    padding: 0.3rem 0.5rem;
    text-overflow: ellipsis;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    width: 32px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
  }
}

@mixin kl-hollow-chip($color: $arm-dark-grey-web-safe) {
  padding: 0.25rem 0.5rem;
  border: solid 1px $color;
  border-radius: 4px;
  font-size: 14px;
  color: $color;
  text-decoration: none;

  i {
    margin-right: 0.25rem;
  }
}

@mixin kl-hollow-chip-link(
  $color: $arm-dark-grey-web-safe,
  $hover-color: $arm-blue-web-safe
) {
  @include kl-hollow-chip($color);
  cursor: pointer;

  &:hover {
    border-color: $hover-color;
    color: $hover-color;
    background-color: $arm-light-grey-alt;
  }
}
