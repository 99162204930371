*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

[hidden][hidden] {
  display: none !important;
}

up-wrapper {
  display: inline-block;
}

up-bounds {
  position: absolute;
}

.up-focusable-content:focus, .up-focusable-content:focus-visible {
  outline: 0;
}

.up-request-loader {
  display: none;
}

up-progress-bar {
  z-index: 999999999;
  background-color: #007bff;
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
}

up-focus-trap {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
}

up-modal, up-drawer, up-cover, up-modal-backdrop, up-drawer-backdrop, up-modal-viewport, up-drawer-viewport, up-cover-viewport {
  inset: 0;
}

up-modal-box, up-drawer-box {
  box-shadow: 0 0 10px 1px #0000004d;
}

up-popup {
  box-shadow: 0 0 4px #0000004d;
}

up-modal:focus, up-drawer:focus, up-cover:focus, up-modal-box:focus, up-drawer-box:focus, up-cover-box:focus, up-popup:focus, up-modal:focus-visible, up-drawer:focus-visible, up-cover:focus-visible, up-modal-box:focus-visible, up-drawer-box:focus-visible, up-cover-box:focus-visible, up-popup:focus-visible {
  outline: none;
}

up-modal, up-drawer, up-cover {
  z-index: 2000;
  position: fixed;
}

up-modal-backdrop, up-drawer-backdrop {
  background: #0006;
  position: absolute;
}

up-modal-viewport, up-drawer-viewport, up-cover-viewport {
  overscroll-behavior: contain;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: absolute;
  overflow: hidden scroll;
}

up-modal-box, up-drawer-box, up-cover-box, up-popup {
  box-sizing: border-box;
  background-color: #fff;
  max-width: 100%;
  padding: 20px;
  position: relative;
  overflow-x: hidden;
}

up-modal-content, up-drawer-content, up-cover-content, up-popup-content {
  display: block;
}

up-popup {
  z-index: 1000;
}

up-modal-dismiss, up-drawer-dismiss, up-cover-dismiss, up-popup-dismiss {
  color: #888;
  font-size: 1.7rem;
  line-height: .5;
  position: absolute;
  top: 10px;
  right: 10px;
}

up-modal[nesting="0"] up-modal-viewport {
  padding: 25px 15px;
}

up-modal[nesting="1"] up-modal-viewport {
  padding: 50px 30px;
}

up-modal[nesting="2"] up-modal-viewport {
  padding: 75px 45px;
}

up-modal[nesting="3"] up-modal-viewport {
  padding: 100px 60px;
}

up-modal[nesting="4"] up-modal-viewport {
  padding: 125px 75px;
}

up-modal[size="small"] up-modal-box {
  width: 350px;
}

up-modal[size="medium"] up-modal-box {
  width: 650px;
}

up-modal[size="large"] up-modal-box {
  width: 1000px;
}

up-modal[size="grow"] up-modal-box {
  width: auto;
}

up-modal[size="full"] up-modal-box {
  width: 100%;
}

up-drawer-viewport {
  justify-content: flex-start;
}

up-drawer[position="right"] up-drawer-viewport {
  justify-content: flex-end;
}

up-drawer-box {
  min-height: 100vh;
}

up-drawer[size="small"] up-drawer-box {
  width: 150px;
}

up-drawer[size="medium"] up-drawer-box {
  width: 340px;
}

up-drawer[size="large"] up-drawer-box {
  width: 600px;
}

up-drawer[size="grow"] up-drawer-box {
  width: auto;
}

up-drawer[size="full"] up-drawer-box {
  width: 100%;
}

up-cover-box {
  width: 100%;
  min-height: 100vh;
  padding: 0;
}

up-popup {
  text-align: left;
  padding: 15px;
}

up-popup[size="small"] {
  width: 180px;
}

up-popup[size="medium"] {
  width: 300px;
}

up-popup[size="large"] {
  width: 550px;
}

up-popup[size="grow"] up-popup {
  width: auto;
}

up-popup[size="full"] up-popup {
  width: 100%;
}

[up-href], [up-clickable] {
  cursor: pointer;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fasr, .fal, .fa-light, .fasl, .fat, .fa-thin, .fast, .fad, .fa-duotone, .fass, .fa-sharp-solid, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad, .fa-classic.fa-duotone, .fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass, .fasr, .fasl, .fast, .fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass, .fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .0833333em;
}

.fa-sm {
  vertical-align: .0535714em;
  font-size: .875em;
  line-height: .0714286em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .0416667em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-bagel:before {
  content: "";
}

.fa-transporter-7:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-rectangles-mixed:before {
  content: "";
}

.fa-phone-arrow-up-right:before, .fa-phone-arrow-up:before, .fa-phone-outgoing:before {
  content: "";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-circle-l:before {
  content: "";
}

.fa-head-side-goggles:before, .fa-head-vr:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-face-hand-yawn:before {
  content: "";
}

.fa-gauge-simple-min:before, .fa-tachometer-slowest:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-salad:before, .fa-bowl-salad:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-robot-astromech:before {
  content: "";
}

.fa-ring-diamond:before {
  content: "";
}

.fa-fondue-pot:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-face-hand-peeking:before {
  content: "";
}

.fa-square-user:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-wifi-fair:before, .fa-wifi-2:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-tally-4:before {
  content: "";
}

.fa-rectangle-history:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-ufo:before {
  content: "";
}

.fa-fork:before, .fa-utensil-fork:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-mobile-signal:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-folder-arrow-down:before, .fa-folder-download:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-face-icicles:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-films:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-face-glasses:before {
  content: "";
}

.fa-nfc:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-closed-captioning-slash:before {
  content: "";
}

.fa-calculator-simple:before, .fa-calculator-alt:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-sliders-up:before, .fa-sliders-v:before {
  content: "";
}

.fa-location-minus:before, .fa-map-marker-minus:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-ski-boot:before {
  content: "";
}

.fa-standard-definition:before, .fa-rectangle-sd:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-message-bot:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-message-exclamation:before, .fa-comment-alt-exclamation:before {
  content: "";
}

.fa-bowl-scoop:before, .fa-bowl-shaved-ice:before {
  content: "";
}

.fa-square-x:before {
  content: "";
}

.fa-building-memo:before {
  content: "";
}

.fa-utility-pole-double:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-chevrons-up:before, .fa-chevron-double-up:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-user-vneck:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-square-arrow-right:before, .fa-arrow-square-right:before {
  content: "";
}

.fa-location-plus:before, .fa-map-marker-plus:before {
  content: "";
}

.fa-lightbulb-exclamation-on:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-crate-empty:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-calendar-circle-user:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-person-carry-box:before, .fa-person-carry:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-chevrons-left:before, .fa-chevron-double-left:before {
  content: "";
}

.fa-circle-heart:before, .fa-heart-circle:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-square-p:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-truck-flatbed:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-arrows-rotate-reverse:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-house-building:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-cart-xmark:before {
  content: "";
}

.fa-hexagon-xmark:before, .fa-times-hexagon:before, .fa-xmark-hexagon:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-merge:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-cart-minus:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-pan-frying:before {
  content: "";
}

.fa-grid:before, .fa-grid-3:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-hand-love:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-page:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-diagram-previous:before {
  content: "";
}

.fa-gauge-min:before, .fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-folder-grid:before {
  content: "";
}

.fa-eggplant:before {
  content: "";
}

.fa-excavator:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-desktop-arrow-down:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-pen-field:before {
  content: "";
}

.fa-blueberries:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-note:before {
  content: "";
}

.fa-arrow-down-to-square:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-cloud-xmark:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-book-blank:before, .fa-book-alt:before {
  content: "";
}

.fa-golf-flag-hole:before {
  content: "";
}

.fa-message-arrow-down:before, .fa-comment-alt-arrow-down:before {
  content: "";
}

.fa-face-unamused:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-circle-9:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-pencil-slash:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-down-right:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-webhook:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-fence:before {
  content: "";
}

.fa-up:before, .fa-arrow-alt-up:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-square-v:before {
  content: "";
}

.fa-face-awesome:before, .fa-gave-dandy:before {
  content: "";
}

.fa-dial-off:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-face-smile-horns:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-grapes:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-dial-max:before {
  content: "";
}

.fa-circle-m:before {
  content: "";
}

.fa-calendar-image:before {
  content: "";
}

.fa-circle-caret-down:before, .fa-caret-circle-down:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-code-pull-request-draft:before {
  content: "";
}

.fa-square-b:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-message-question:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-square-3:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-chf-sign:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-cart-circle-arrow-up:before {
  content: "";
}

.fa-trash-clock:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-sprinkler-ceiling:before {
  content: "";
}

.fa-browsers:before {
  content: "";
}

.fa-trillium:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-circle-c:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-chart-bullet:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-message-pen:before, .fa-comment-alt-edit:before, .fa-message-edit:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-file-mp3:before {
  content: "";
}

.fa-arrow-progress:before {
  content: "";
}

.fa-chess-rook-piece:before, .fa-chess-rook-alt:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-album-collection-circle-plus:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-sign-post:before {
  content: "";
}

.fa-face-angry-horns:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-mustache:before {
  content: "";
}

.fa-hyphen:before {
  content: "-";
}

.fa-table:before {
  content: "";
}

.fa-user-chef:before {
  content: "";
}

.fa-message-image:before, .fa-comment-alt-image:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-sensor-triangle-exclamation:before, .fa-sensor-alert:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-face-mask:before {
  content: "";
}

.fa-pickleball:before {
  content: "";
}

.fa-star-sharp-half:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-bird:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-heart-half-stroke:before, .fa-heart-half-alt:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-truck-utensils:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-grill-fire:before {
  content: "";
}

.fa-rectangle-vertical-history:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-keyboard-left:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-face-beam-hand-over-mouth:before {
  content: "";
}

.fa-droplet-percent:before, .fa-humidity:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-roller-coaster:before {
  content: "";
}

.fa-photo-film-music:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-coconut:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-square-terminal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-comment-middle:before {
  content: "";
}

.fa-trash-can-list:before {
  content: "";
}

.fa-block:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-face-frown-slight:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-sidebar-flip:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-temperature-list:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-pipe-section:before {
  content: "";
}

.fa-space-station-moon-construction:before, .fa-space-station-moon-alt:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-face-sleeping:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-arrow-up-to-line:before, .fa-arrow-to-top:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-face-cowboy-hat:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-chart-scatter-3d:before {
  content: "";
}

.fa-display-chart-up:before {
  content: "";
}

.fa-square-code:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-xmark-to-slot:before, .fa-times-to-slot:before, .fa-vote-nay:before {
  content: "";
}

.fa-box-taped:before, .fa-box-alt:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-circle-n:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-key-skeleton-left-right:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-luchador-mask:before, .fa-luchador:before, .fa-mask-luchador:before {
  content: "";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-book-open-cover:before, .fa-book-open-alt:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-square-parking-slash:before, .fa-parking-slash:before {
  content: "";
}

.fa-train-tunnel:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-face-anguished:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-film-simple:before, .fa-film-alt:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-clipboard-medical:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-up-to-line:before, .fa-arrow-alt-to-top:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-clock-nine-thirty:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-arrow-down-from-line:before, .fa-arrow-from-top:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-signal-bars-slash:before, .fa-signal-alt-slash:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-rectangle-pro:before, .fa-pro:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-globe-pointer:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-comments-question-check:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-arrows-cross:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-square-small:before {
  content: "";
}

.fa-folder-arrow-up:before, .fa-folder-upload:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-crosshairs-simple:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-square-sliders:before, .fa-sliders-h-square:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-message-middle-top:before, .fa-comment-middle-top-alt:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-knife:before, .fa-utensil-knife:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-display-chart-up-circle-dollar:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-circle-w:before {
  content: "";
}

.fa-circle-calendar:before, .fa-calendar-circle:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-truck-container-empty:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-reply-clock:before, .fa-reply-time:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-right:before, .fa-arrow-alt-right:before {
  content: "";
}

.fa-circle-f:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-face-pleading:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-cucumber:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-person-biking-mountain:before, .fa-biking-mountain:before {
  content: "";
}

.fa-utensils-slash:before {
  content: "";
}

.fa-print-magnifying-glass:before, .fa-print-search:before {
  content: "";
}

.fa-turn-right:before {
  content: "";
}

.fa-folder-bookmark:before {
  content: "";
}

.fa-arrow-turn-left-down:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-flask-round-potion:before, .fa-flask-potion:before {
  content: "";
}

.fa-face-shush:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-calendar-circle-exclamation:before {
  content: "";
}

.fa-square-i:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-face-saluting:before {
  content: "";
}

.fa-gauge-simple-low:before, .fa-tachometer-slow:before {
  content: "";
}

.fa-face-persevering:before {
  content: "";
}

.fa-circle-camera:before, .fa-camera-circle:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-circle-microphone:before, .fa-microphone-circle:before {
  content: "";
}

.fa-book-arrow-up:before {
  content: "";
}

.fa-popsicle:before {
  content: "";
}

.fa-command:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-square-2:before {
  content: "";
}

.fa-field-hockey-stick-ball:before, .fa-field-hockey:before {
  content: "";
}

.fa-arrow-up-square-triangle:before, .fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-face-scream:before {
  content: "";
}

.fa-square-m:before {
  content: "";
}

.fa-camera-web:before, .fa-webcam:before {
  content: "";
}

.fa-comment-arrow-down:before {
  content: "";
}

.fa-lightbulb-cfl:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-period:before {
  content: ".";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-up-to-dotted-line:before {
  content: "";
}

.fa-thought-bubble:before {
  content: "";
}

.fa-skeleton-ribs:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-video-arrow-up-right:before {
  content: "";
}

.fa-grate-droplet:before {
  content: "";
}

.fa-seal-exclamation:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-message-sms:before {
  content: "";
}

.fa-coffee-beans:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-clock-three-thirty:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-face-relieved:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-octagon-minus:before, .fa-minus-octagon:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-face-zany:before {
  content: "";
}

.fa-tricycle:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-square-arrow-up-left:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-chart-mixed-up-circle-dollar:before {
  content: "";
}

.fa-salt-shaker:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-toilet-paper-under-slash:before, .fa-toilet-paper-reverse-slash:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-drone-front:before, .fa-drone-alt:before {
  content: "";
}

.fa-glass-empty:before {
  content: "";
}

.fa-dial-high:before {
  content: "";
}

.fa-user-helmet-safety:before, .fa-user-construction:before, .fa-user-hard-hat:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-objects-align-bottom:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-sparkles:before {
  content: "";
}

.fa-squid:before {
  content: "";
}

.fa-leafy-green:before {
  content: "";
}

.fa-circle-arrow-up-right:before {
  content: "";
}

.fa-calendars:before {
  content: "";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-circle-d:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-pear:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-file-mov:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-apartment:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-pepper:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-gun-squirt:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-chart-candlestick:before {
  content: "";
}

.fa-briefcase-blank:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-bracket-round:before, .fa-parenthesis:before {
  content: "(";
}

.fa-joint:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-filters:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-dial-med:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-monitor-waveform:before, .fa-monitor-heart-rate:before {
  content: "";
}

.fa-link-simple:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-g:before {
  content: "G";
}

.fa-wine-glass-crack:before, .fa-fragile:before {
  content: "";
}

.fa-slot-machine:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-escalator:before {
  content: "";
}

.fa-comment-image:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-donut:before, .fa-doughnut:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-tally-1:before {
  content: "";
}

.fa-file-vector:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-square-dashed:before {
  content: "";
}

.fa-bag-shopping-plus:before {
  content: "";
}

.fa-square-j:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-arrow-up-big-small:before, .fa-sort-size-up:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-baguette:before {
  content: "";
}

.fa-bowl-soft-serve:before {
  content: "";
}

.fa-face-holding-back-tears:before {
  content: "";
}

.fa-square-up:before, .fa-arrow-alt-square-up:before {
  content: "";
}

.fa-train-subway-tunnel:before, .fa-subway-tunnel:before {
  content: "";
}

.fa-square-exclamation:before, .fa-exclamation-square:before {
  content: "";
}

.fa-semicolon:before {
  content: ";";
}

.fa-bookmark:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-battery-low:before, .fa-battery-1:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-brain-arrow-curved-right:before, .fa-mind-share:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-location-smile:before, .fa-map-marker-smile:before {
  content: "";
}

.fa-arrow-left-to-line:before, .fa-arrow-to-left:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-sushi:before, .fa-nigiri:before {
  content: "";
}

.fa-message-captions:before, .fa-comment-alt-captions:before {
  content: "";
}

.fa-trash-list:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-option:before {
  content: "";
}

.fa-raccoon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-head-side-gear:before {
  content: "";
}

.fa-trash-plus:before {
  content: "";
}

.fa-objects-align-top:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-face-anxious-sweat:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-microchip-ai:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-plane-up-slash:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-pen-circle:before {
  content: "";
}

.fa-bag-seedling:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-circle-parking:before, .fa-parking-circle:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-square-bolt:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pizza:before {
  content: "";
}

.fa-bowl-chopsticks-noodles:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-rotate-reverse:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-comma:before {
  content: ",";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-toilet-paper-under:before, .fa-toilet-paper-reverse:before {
  content: "";
}

.fa-light-emergency:before {
  content: "";
}

.fa-arrow-down-to-arc:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-wave-pulse:before, .fa-heart-rate:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-tamale:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-location-crosshairs-slash:before, .fa-location-slash:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-file-chart-column:before, .fa-file-chart-line:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-folder-image:before {
  content: "";
}

.fa-calendar-pen:before, .fa-calendar-edit:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-face-smile-tear:before {
  content: "";
}

.fa-message-plus:before, .fa-comment-alt-plus:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-person-dress-fairy:before {
  content: "";
}

.fa-rectangle-history-circle-user:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-chart-line-up:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-sign-posts:before {
  content: "";
}

.fa-truck-bolt:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-circle-three-quarters-stroke:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-circle-bookmark:before, .fa-bookmark-circle:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-face-weary:before {
  content: "";
}

.fa-uniform-martial-arts:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-comment-text:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-signal-bars:before, .fa-signal-alt:before, .fa-signal-alt-4:before, .fa-signal-bars-strong:before {
  content: "";
}

.fa-diamond-exclamation:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-dial-min:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-page-caret-down:before, .fa-file-caret-down:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-clock-seven-thirty:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-clock-four-thirty:before {
  content: "";
}

.fa-signal-bars-good:before, .fa-signal-alt-3:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-lightbulb-gear:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-plane-tail:before {
  content: "";
}

.fa-gauge-simple-max:before, .fa-tachometer-fastest:before {
  content: "";
}

.fa-circle-u:before {
  content: "";
}

.fa-shield-slash:before {
  content: "";
}

.fa-square-phone-hangup:before, .fa-phone-square-down:before {
  content: "";
}

.fa-arrow-up-left:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-peanuts:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-bin-bottles-recycle:before {
  content: "";
}

.fa-arrow-up-from-square:before {
  content: "";
}

.fa-file-dashed-line:before, .fa-page-break:before {
  content: "";
}

.fa-bracket-curly-right:before {
  content: "}";
}

.fa-spider:before {
  content: "";
}

.fa-clock-three:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-scalpel-line-dashed:before, .fa-scalpel-path:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-pipe-smoking:before {
  content: "";
}

.fa-face-astonished:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-file-lock:before {
  content: "";
}

.fa-diagram-venn:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-goal-net:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-location-xmark:before, .fa-map-marker-times:before, .fa-map-marker-xmark:before {
  content: "";
}

.fa-circle-quarter-stroke:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-person-to-portal:before, .fa-portal-enter:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-cannon:before {
  content: "";
}

.fa-nfc-lock:before {
  content: "";
}

.fa-person-ski-lift:before, .fa-ski-lift:before {
  content: "";
}

.fa-square-6:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-sliders-simple:before {
  content: "";
}

.fa-grid-round:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-family:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-user-hair-buns:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-hourglass-clock:before {
  content: "";
}

.fa-person-seat-reclined:before {
  content: "";
}

.fa-paper-plane-top:before, .fa-paper-plane-alt:before, .fa-send:before {
  content: "";
}

.fa-message-arrow-up:before, .fa-comment-alt-arrow-up:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-layer-minus:before, .fa-layer-group-minus:before {
  content: "";
}

.fa-chart-pie-simple-circle-currency:before {
  content: "";
}

.fa-circle-e:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-gauge-max:before, .fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-apostrophe:before {
  content: "'";
}

.fa-file-png:before {
  content: "";
}

.fa-fire-hydrant:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-square-right:before, .fa-arrow-alt-square-right:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-inbox-in:before, .fa-inbox-arrow-down:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-circle-8:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clock-ten-thirty:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-folder-user:before {
  content: "";
}

.fa-trash-can-xmark:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-left-long-to-line:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-calendar-range:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-hand-back-point-up:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-arrow-up-to-arc:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-layer-plus:before, .fa-layer-group-plus:before {
  content: "";
}

.fa-play-pause:before {
  content: "";
}

.fa-block-question:before {
  content: "";
}

.fa-snooze:before, .fa-zzz:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-square-t:before {
  content: "";
}

.fa-farm:before, .fa-barn-silo:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-bars-sort:before {
  content: "";
}

.fa-pallet-boxes:before, .fa-palette-boxes:before, .fa-pallet-alt:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-code-simple:before {
  content: "";
}

.fa-bolt-slash:before {
  content: "";
}

.fa-panel-fire:before {
  content: "";
}

.fa-binary-circle-check:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-objects-column:before {
  content: "";
}

.fa-square-chevron-down:before, .fa-chevron-square-down:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-triangle-instrument:before, .fa-triangle-music:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-user-pilot-tie:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-rectangle-vertical:before, .fa-rectangle-portrait:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-signal-stream:before {
  content: "";
}

.fa-down-to-bracket:before {
  content: "";
}

.fa-circle-z:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-shirt-long-sleeve:before {
  content: "";
}

.fa-chart-pie-simple:before, .fa-chart-pie-alt:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-album-collection-circle-user:before {
  content: "";
}

.fa-candy:before {
  content: "";
}

.fa-bowl-hot:before, .fa-soup:before {
  content: "";
}

.fa-flatbread:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-signal-bars-weak:before, .fa-signal-alt-1:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-clock-twelve:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-citrus-slice:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-hexagon-plus:before, .fa-plus-hexagon:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-messages:before, .fa-comments-alt:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-umbrella-simple:before, .fa-umbrella-alt:before {
  content: "";
}

.fa-rectangle-history-circle-plus:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-prescription-bottle-pill:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-binary-slash:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-engine-warning:before, .fa-engine-exclamation:before {
  content: "";
}

.fa-circle-down-right:before {
  content: "";
}

.fa-square-k:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-money-check-pen:before, .fa-money-check-edit:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-tilde:before {
  content: "~";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-arrow-down-square-triangle:before, .fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-face-downcast-sweat:before {
  content: "";
}

.fa-mailbox-flag-up:before {
  content: "";
}

.fa-memo-circle-info:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-volume:before, .fa-volume-medium:before {
  content: "";
}

.fa-transporter-5:before {
  content: "";
}

.fa-gauge-circle-bolt:before {
  content: "";
}

.fa-coin-front:before {
  content: "";
}

.fa-file-slash:before {
  content: "";
}

.fa-message-arrow-up-right:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-paintbrush-fine:before, .fa-paint-brush-alt:before, .fa-paint-brush-fine:before, .fa-paintbrush-alt:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-square-quote:before {
  content: "";
}

.fa-up-left:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-burger-cheese:before, .fa-cheeseburger:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-arrow-down-to-line:before, .fa-arrow-to-bottom:before {
  content: "";
}

.fa-grid-5:before {
  content: "";
}

.fa-swap-arrows:before {
  content: "";
}

.fa-right-long-to-line:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-grid-round-5:before {
  content: "";
}

.fa-tally:before, .fa-tally-5:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-up-from-dotted-line:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-face-exhaling:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-hexagon-divide:before {
  content: "";
}

.fa-00:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-cassette-betamax:before, .fa-betamax:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-nfc-magnifying-glass:before {
  content: "";
}

.fa-file-binary:before {
  content: "";
}

.fa-circle-v:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-bowl-scoops:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-custard:before {
  content: "";
}

.fa-lacrosse-stick:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-subtitles:before {
  content: "";
}

.fa-panel-ews:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-cloud-exclamation:before {
  content: "";
}

.fa-message-lines:before, .fa-comment-alt-lines:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-floppy-disk-pen:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-file-zip:before {
  content: "";
}

.fa-square-ring:before {
  content: "";
}

.fa-down-from-line:before, .fa-arrow-alt-from-top:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-shield-xmark:before, .fa-shield-times:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-circle-sort-down:before, .fa-sort-circle-down:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-bracket-square-right:before {
  content: "]";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-square-a:before {
  content: "";
}

.fa-tick:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-octagon-xmark:before, .fa-times-octagon:before, .fa-xmark-octagon:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-truck-ramp-couch:before, .fa-truck-couch:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "";
}

.fa-location-arrow-up:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-360-degrees:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-square-dashed-circle-plus:before {
  content: "";
}

.fa-hand-holding-circle-dollar:before {
  content: "";
}

.fa-money-simple-from-bracket:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-calendar-circle-minus:before {
  content: "";
}

.fa-chopsticks:before {
  content: "";
}

.fa-car-wrench:before, .fa-car-mechanic:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-bin-bottles:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-square-arrow-down-left:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-aperture:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-gear-complex:before {
  content: "";
}

.fa-file-magnifying-glass:before, .fa-file-search:before {
  content: "";
}

.fa-up-right:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-user-police:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-teddy-bear:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-image-slash:before {
  content: "";
}

.fa-mask-snorkel:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-battery-exclamation:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-crystal-ball:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-binary-lock:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-comment-pen:before, .fa-comment-edit:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-light-emergency-on:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-square-f:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-arrow-left-from-line:before, .fa-arrow-from-right:before {
  content: "";
}

.fa-strawberry:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-clock-eight-thirty:before {
  content: "";
}

.fa-plane-engines:before, .fa-plane-alt:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-gauge-low:before, .fa-tachometer-alt-slow:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-trash-can-plus:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-circle-caret-up:before, .fa-caret-circle-up:before {
  content: "";
}

.fa-user-vneck-hair-long:before {
  content: "";
}

.fa-square-a-lock:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-transporter-4:before {
  content: "";
}

.fa-chart-mixed-up-circle-currency:before {
  content: "";
}

.fa-objects-align-right:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-inbox-full:before {
  content: "";
}

.fa-circle-envelope:before, .fa-envelope-circle:before {
  content: "";
}

.fa-triangle-person-digging:before, .fa-construction:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-clock-seven:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-file-jpg:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-square-9:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-square-dollar:before, .fa-dollar-square:before, .fa-usd-square:before {
  content: "";
}

.fa-phone-arrow-right:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-message-check:before, .fa-comment-alt-check:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-chart-line-up-down:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-circle-down-left:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-tombstone-blank:before, .fa-tombstone-alt:before {
  content: "";
}

.fa-chess-king-piece:before, .fa-chess-king-alt:before {
  content: "";
}

.fa-circle-6:before {
  content: "";
}

.fa-waves-sine:before {
  content: "";
}

.fa-left:before, .fa-arrow-alt-left:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-square-down:before, .fa-arrow-alt-square-down:before {
  content: "";
}

.fa-objects-align-center-vertical:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-meter-droplet:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-signal-strong:before, .fa-signal-4:before {
  content: "";
}

.fa-lollipop:before, .fa-lollypop:before {
  content: "";
}

.fa-list-tree:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-rabbit-running:before, .fa-rabbit-fast:before {
  content: "";
}

.fa-memo-pad:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-comment-question:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-burger-fries:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-circle-book-open:before, .fa-book-circle:before {
  content: "";
}

.fa-arrows-to-dotted-line:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-magnifying-glass-arrows-rotate:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-shelves-empty:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-watch-apple:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-list-dropdown:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-square-arrow-up:before, .fa-arrow-square-up:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-pallet-box:before {
  content: "";
}

.fa-face-confounded:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-grid-round-2:before {
  content: "";
}

.fa-comment-middle-top:before {
  content: "";
}

.fa-wave:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-restroom-simple:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-hashtag-lock:before {
  content: "";
}

.fa-clock-two-thirty:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-ticket-perforated:before {
  content: "";
}

.fa-heart-half:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-memo:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-calendar-lines-pen:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-person-snowmobiling:before, .fa-snowmobile:before {
  content: "";
}

.fa-face-hushed:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-tickets-simple:before {
  content: "";
}

.fa-pickaxe:before {
  content: "";
}

.fa-link-simple-slash:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-face-confused:before {
  content: "";
}

.fa-pinball:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-input-pipe:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-bookmark-slash:before {
  content: "";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-trash-can-clock:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-broom-wide:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-arrow-turn-down-right:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-bread-slice-butter:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-user-group-crown:before, .fa-users-crown:before {
  content: "";
}

.fa-circle-i:before {
  content: "";
}

.fa-toilet-paper-check:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-chart-waterfall:before {
  content: "";
}

.fa-sparkle:before {
  content: "";
}

.fa-face-party:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-wifi-exclamation:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-wheat-awn-slash:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-memo-circle-check:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-down-to-line:before, .fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-square-g:before {
  content: "";
}

.fa-circle-phone:before, .fa-phone-circle:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-user-nurse-hair:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-toggle-large-on:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-dryer-heat:before, .fa-dryer-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-arrow-up-small-big:before, .fa-sort-size-up-alt:before {
  content: "";
}

.fa-train-track:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-clothes-hanger:before {
  content: "";
}

.fa-mobile-notch:before, .fa-mobile-iphone:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-images-user:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-image-polaroid-user:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-square-ellipsis:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-chess-knight-piece:before, .fa-chess-knight-alt:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-cloud-plus:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-clock-eight:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-xls:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-circle-q:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-face-disguise:before {
  content: "";
}

.fa-circle-arrow-down-right:before {
  content: "";
}

.fa-alien-8bit:before, .fa-alien-monster:before {
  content: "";
}

.fa-hand-point-ribbon:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-object-exclude:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-square-list:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-comment-code:before {
  content: "";
}

.fa-sim-cards:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-seal:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-hexagon-vertical-nft:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-calendar-arrow-down:before, .fa-calendar-download:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-tickets:before {
  content: "";
}

.fa-signature-lock:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-octagon-plus:before, .fa-plus-octagon:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-melon-slice:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-message-smile:before, .fa-comment-alt-smile:before {
  content: "";
}

.fa-cup-straw:before {
  content: "";
}

.fa-left-from-line:before, .fa-arrow-alt-from-right:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-basket-shopping-simple:before, .fa-shopping-basket-alt:before {
  content: "";
}

.fa-hands-holding-heart:before, .fa-hands-heart:before {
  content: "";
}

.fa-clock-nine:before {
  content: "";
}

.fa-hammer-brush:before {
  content: "";
}

.fa-tarp:before {
  content: "";
}

.fa-face-sleepy:before {
  content: "";
}

.fa-hand-horns:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-circle-three-quarters:before {
  content: "";
}

.fa-trophy-star:before, .fa-trophy-alt:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-face-thermometer:before {
  content: "";
}

.fa-grid-round-4:before {
  content: "";
}

.fa-sign-posts-wrench:before {
  content: "";
}

.fa-shirt-running:before {
  content: "";
}

.fa-book-circle-arrow-up:before {
  content: "";
}

.fa-face-nauseated:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-circle-arrow-down-left:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-hexagon-minus:before, .fa-minus-hexagon:before {
  content: "";
}

.fa-left-to-line:before, .fa-arrow-alt-to-left:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-paw-simple:before, .fa-paw-alt:before {
  content: "";
}

.fa-arrow-left-long-to-line:before {
  content: "";
}

.fa-brackets-round:before, .fa-parentheses:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-user-shakespeare:before {
  content: "";
}

.fa-arrow-right-to-arc:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-gauge-circle-plus:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-circle-euro:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-diamond-half:before {
  content: "";
}

.fa-lock-keyhole:before, .fa-lock-alt:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-square-info:before, .fa-info-square:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-toilet-paper-xmark:before {
  content: "";
}

.fa-hands-holding-dollar:before, .fa-hands-usd:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-arrow-down-triangle-square:before, .fa-sort-shapes-down:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shutters:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-table-tree:before {
  content: "";
}

.fa-house-chimney-heart:before {
  content: "";
}

.fa-tally-3:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-cart-circle-exclamation:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-interrobang:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-circle-dashed:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-fork-knife:before, .fa-utensils-alt:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-slider:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-clock-one-thirty:before {
  content: "";
}

.fa-inbox-out:before, .fa-inbox-arrow-up:before {
  content: "";
}

.fa-cloud-slash:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-octagon-check:before {
  content: "";
}

.fa-flatbread-stuffed:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-cart-circle-plus:before {
  content: "";
}

.fa-truck-clock:before, .fa-shipping-timed:before {
  content: "";
}

.fa-pool-8-ball:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-turn-down-left:before {
  content: "";
}

.fa-lock-hashtag:before {
  content: "";
}

.fa-chart-radar:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-utility-pole:before {
  content: "";
}

.fa-transporter-6:before {
  content: "";
}

.fa-arrow-turn-left:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-vector-polygon:before {
  content: "";
}

.fa-diagram-nested:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-tickets-perforated:before {
  content: "";
}

.fa-image-user:before {
  content: "";
}

.fa-buoy:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-can-food:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-arrow-right-from-arc:before {
  content: "";
}

.fa-circle-k:before {
  content: "";
}

.fa-face-hand-over-mouth:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-house-water:before, .fa-house-flood:before {
  content: "";
}

.fa-object-subtract:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-warehouse-full:before, .fa-warehouse-alt:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-arrow-down-from-dotted-line:before {
  content: "";
}

.fa-file-doc:before {
  content: "";
}

.fa-square-quarters:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-trash-xmark:before {
  content: "";
}

.fa-circle-caret-left:before, .fa-caret-circle-left:before {
  content: "";
}

.fa-files:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-face-clouds:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-basket-shopping-plus:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-cart-circle-check:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-user-tie-hair-long:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-arrow-turn-right:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-card-diamond:before {
  content: "";
}

.fa-face-zipper:before {
  content: "";
}

.fa-face-raised-eyebrow:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-square-chevron-up:before, .fa-chevron-square-up:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-arrows-to-line:before {
  content: "";
}

.fa-dolphin:before {
  content: "";
}

.fa-arrow-up-right:before {
  content: "";
}

.fa-circle-r:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-circle-sort-up:before, .fa-sort-circle-up:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-chestnut:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-ticket-airline:before, .fa-ticket-perforated-plane:before, .fa-ticket-plane:before {
  content: "";
}

.fa-boot-heeled:before {
  content: "";
}

.fa-arrows-minimize:before, .fa-compress-arrows:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-manhole:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-floppy-disks:before {
  content: "";
}

.fa-toilet-paper-blank-under:before, .fa-toilet-paper-reverse-alt:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-pump:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-arrow-up-left-from-circle:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-chess-bishop-piece:before, .fa-chess-bishop-alt:before {
  content: "";
}

.fa-shirt-tank-top:before {
  content: "";
}

.fa-diploma:before, .fa-scroll-ribbon:before {
  content: "";
}

.fa-screencast:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-face-kiss-closed-eyes:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-user-police-tie:before {
  content: "";
}

.fa-face-tongue-money:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-square-l:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-calendar-arrow-up:before, .fa-calendar-upload:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-album-circle-plus:before {
  content: "";
}

.fa-user-nurse-hair-long:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-square-left:before, .fa-arrow-alt-square-left:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-circle-ellipsis-vertical:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-grid-dividers:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-face-pensive:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-messages-dollar:before, .fa-comments-alt-dollar:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-balloon:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-chess-queen-piece:before, .fa-chess-queen-alt:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-house-person-leave:before, .fa-house-leave:before, .fa-house-person-depart:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-card-club:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-computer-mouse-scrollwheel:before, .fa-mouse-alt:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-table-layout:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-calendar-circle-plus:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-kiwi-fruit:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-rectangle-code:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-keyboard-brightness:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-house-blank:before, .fa-home-blank:before {
  content: "";
}

.fa-square-5:before {
  content: "";
}

.fa-square-heart:before, .fa-heart-square:before {
  content: "";
}

.fa-puzzle:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-pipe-circle-check:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-octagon-exclamation:before {
  content: "";
}

.fa-dial-low:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-laptop-mobile:before, .fa-phone-laptop:before {
  content: "";
}

.fa-conveyor-belt-boxes:before, .fa-conveyor-belt-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-starfighter-twin-ion-engine-advanced:before, .fa-starfighter-alt-advanced:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-starfighter-twin-ion-engine:before, .fa-starfighter-alt:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-vent-damper:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-ban-bug:before, .fa-debug:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-square-kanban:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-chart-kanban:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-mouse-field:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-shower-down:before, .fa-shower-alt:before {
  content: "";
}

.fa-box-circle-check:before {
  content: "";
}

.fa-brightness:before {
  content: "";
}

.fa-car-side-bolt:before {
  content: "";
}

.fa-file-xml:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-phone-arrow-down-left:before, .fa-phone-arrow-down:before, .fa-phone-incoming:before {
  content: "";
}

.fa-cloud-word:before {
  content: "";
}

.fa-hand-fingers-crossed:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-arrow-down-small-big:before, .fa-sort-size-down-alt:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-face-melting:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-pen-clip-slash:before, .fa-pen-alt-slash:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-phone-xmark:before {
  content: "";
}

.fa-hose:before {
  content: "";
}

.fa-clock-six:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-billboard:before {
  content: "";
}

.fa-square-r:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-square-7:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-circle-ampersand:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-cup-straw-swoosh:before {
  content: "";
}

.fa-temperature-sun:before, .fa-temperature-hot:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-high-definition:before, .fa-rectangle-hd:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-galaxy:before {
  content: "";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-cherries:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-circle-sort:before, .fa-sort-circle:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-bag-shopping-minus:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-arrow-up-to-dotted-line:before {
  content: "";
}

.fa-image-landscape:before, .fa-landscape:before {
  content: "";
}

.fa-tank-water:before {
  content: "";
}

.fa-curling-stone:before, .fa-curling:before {
  content: "";
}

.fa-gamepad-modern:before, .fa-gamepad-alt:before {
  content: "";
}

.fa-messages-question:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-briefcase-arrow-right:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-clock-eleven-thirty:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-people-pants-simple:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-seal-question:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-table-rows:before, .fa-rows:before {
  content: "";
}

.fa-location-exclamation:before, .fa-map-marker-exclamation:before {
  content: "";
}

.fa-face-fearful:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-film-slash:before {
  content: "";
}

.fa-square-arrow-down-right:before {
  content: "";
}

.fa-book-sparkles:before, .fa-book-spells:before {
  content: "";
}

.fa-washing-machine:before, .fa-washer:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-file-plus-minus:before {
  content: "";
}

.fa-chess-clock-flip:before, .fa-chess-clock-alt:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-truck-fire:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-book-section:before, .fa-book-law:before {
  content: "";
}

.fa-inboxes:before {
  content: "";
}

.fa-coffee-bean:before {
  content: "";
}

.fa-circle-yen:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-ellipsis-stroke-vertical:before, .fa-ellipsis-v-alt:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-square-1:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-truck-ladder:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-face-tissue:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-bin-recycle:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-lock-a:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-person-pinball:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-apple-core:before {
  content: "";
}

.fa-circle-y:before {
  content: "";
}

.fa-h6:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-circle-small:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-arrow-turn-down-left:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-reflect-horizontal:before {
  content: "";
}

.fa-message-medical:before, .fa-comment-alt-medical:before {
  content: "";
}

.fa-rugby-ball:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-tree-deciduous:before, .fa-tree-alt:before {
  content: "";
}

.fa-puzzle-piece-simple:before, .fa-puzzle-piece-alt:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-nfc-trash:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-file-xmark:before, .fa-file-times:before {
  content: "";
}

.fa-house-heart:before, .fa-home-heart:before {
  content: "";
}

.fa-house-chimney-blank:before {
  content: "";
}

.fa-meter-bolt:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-slash-back:before {
  content: "\\";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-fishing-rod:before {
  content: "";
}

.fa-hammer-crash:before {
  content: "";
}

.fa-message-heart:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-cart-arrow-up:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-buoy-mooring:before {
  content: "";
}

.fa-square-4:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-file-eps:before {
  content: "";
}

.fa-tricycle-adult:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-star-sharp-half-stroke:before, .fa-star-sharp-half-alt:before {
  content: "";
}

.fa-nfc-signal:before {
  content: "";
}

.fa-plane-prop:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-clock-desk:before {
  content: "";
}

.fa-calendar-clock:before, .fa-calendar-time:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-plate-utensils:before {
  content: "";
}

.fa-family-pants:before {
  content: "";
}

.fa-hose-reel:before {
  content: "";
}

.fa-house-window:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-music-magnifying-glass:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-laptop-binary:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-people-dress-simple:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-bowling-ball-pin:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-plus-large:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-camera-viewfinder:before, .fa-screenshot:before {
  content: "";
}

.fa-message-music:before, .fa-comment-alt-music:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-border-bottom-right:before, .fa-border-style-alt:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-comment-arrow-up-right:before {
  content: "";
}

.fa-octagon-divide:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-reel:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-grate:before {
  content: "";
}

.fa-arrow-down-right:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-person-from-portal:before, .fa-portal-exit:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-cowbell-circle-plus:before, .fa-cowbell-more:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-distribute-spacing-vertical:before {
  content: "";
}

.fa-signal-bars-fair:before, .fa-signal-alt-2:before {
  content: "";
}

.fa-sportsball:before {
  content: "";
}

.fa-game-console-handheld-crank:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-face-smile-upside-down:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-money-bills-simple:before, .fa-money-bills-alt:before {
  content: "";
}

.fa-list-timeline:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-keyboard-down:before {
  content: "";
}

.fa-circle-up-right:before {
  content: "";
}

.fa-cloud-bolt-moon:before, .fa-thunderstorm-moon:before {
  content: "";
}

.fa-turn-left-up:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-bracket-round-right:before {
  content: ")";
}

.fa-circle-sterling:before {
  content: "";
}

.fa-circle-5:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-fire-flame:before, .fa-flame:before {
  content: "";
}

.fa-right-to-line:before, .fa-arrow-alt-to-right:before {
  content: "";
}

.fa-gif:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-trash-slash:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-arrow-down-left:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-flask-round-poison:before, .fa-flask-poison:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-book-circle-arrow-right:before {
  content: "";
}

.fa-chart-user:before, .fa-user-chart:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-presentation-screen:before, .fa-presentation:before {
  content: "";
}

.fa-circle-bolt:before {
  content: "";
}

.fa-face-smile-halo:before {
  content: "";
}

.fa-cart-circle-arrow-down:before {
  content: "";
}

.fa-house-person-return:before, .fa-house-person-arrive:before, .fa-house-return:before {
  content: "";
}

.fa-message-xmark:before, .fa-comment-alt-times:before, .fa-message-times:before {
  content: "";
}

.fa-file-certificate:before, .fa-file-award:before {
  content: "";
}

.fa-user-doctor-hair-long:before {
  content: "";
}

.fa-camera-security:before, .fa-camera-home:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-flag-swallowtail:before, .fa-flag-alt:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-book-font:before {
  content: "";
}

.fa-shield-plus:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-eye-dropper-full:before {
  content: "";
}

.fa-distribute-spacing-horizontal:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-temperature-snow:before, .fa-temperature-frigid:before {
  content: "";
}

.fa-moped:before {
  content: "";
}

.fa-face-smile-plus:before, .fa-smile-plus:before {
  content: "";
}

.fa-radio-tuner:before, .fa-radio-alt:before {
  content: "";
}

.fa-face-swear:before {
  content: "";
}

.fa-water-arrow-down:before, .fa-water-lower:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-circle-7:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-person-ski-jumping:before, .fa-ski-jump:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-water-arrow-up:before, .fa-water-rise:before {
  content: "";
}

.fa-waveform-lines:before, .fa-waveform-path:before {
  content: "";
}

.fa-split:before {
  content: "";
}

.fa-film-canister:before, .fa-film-cannister:before {
  content: "";
}

.fa-folder-xmark:before, .fa-folder-times:before {
  content: "";
}

.fa-toilet-paper-blank:before, .fa-toilet-paper-alt:before {
  content: "";
}

.fa-tablet-screen:before, .fa-tablet-android-alt:before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted:before {
  content: "";
}

.fa-folder-music:before {
  content: "";
}

.fa-display-medical:before, .fa-desktop-medical:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-peapod:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-square-d:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-mobile-signal-out:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-arrow-up-from-dotted-line:before {
  content: "";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-arrow-down-arrow-up:before, .fa-sort-alt:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-dash:before, .fa-minus-large:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-input-numeric:before {
  content: "";
}

.fa-truck-tow:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-car-bolt:before {
  content: "";
}

.fa-arrows-maximize:before, .fa-expand-arrows:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-lamp-street:before {
  content: "";
}

.fa-list-radio:before {
  content: "";
}

.fa-pen-nib-slash:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-square-up-left:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-arrows-from-dotted-line:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-location-dot-slash:before, .fa-map-marker-alt-slash:before {
  content: "";
}

.fa-cards:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-boxing-glove:before, .fa-glove-boxing:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-folder-heart:before {
  content: "";
}

.fa-circle-location-arrow:before, .fa-location-circle:before {
  content: "";
}

.fa-face-head-bandage:before {
  content: "";
}

.fa-sushi-roll:before, .fa-maki-roll:before, .fa-makizushi:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-car-mirrors:before {
  content: "";
}

.fa-industry-windows:before, .fa-industry-alt:before {
  content: "";
}

.fa-bolt-auto:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-honey-pot:before {
  content: "";
}

.fa-olive:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-filter-list:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-people:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-square-u:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-laptop-slash:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-circle-b:before {
  content: "";
}

.fa-person-dress-simple:before {
  content: "";
}

.fa-pipe-collar:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-citrus:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-chart-tree-map:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-clock-five-thirty:before {
  content: "";
}

.fa-pipe-valve:before {
  content: "";
}

.fa-arrow-up-from-arc:before {
  content: "";
}

.fa-face-spiral-eyes:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-circle-phone-hangup:before, .fa-phone-circle-down:before {
  content: "";
}

.fa-gear-complex-code:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-badminton:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-right-from-line:before, .fa-arrow-alt-from-left:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-user-hair:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-swap:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-face-lying:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-person-sledding:before, .fa-sledding:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-clock-six-thirty:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-tugrik-sign:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-angles-up-down:before {
  content: "";
}

.fa-shelves:before, .fa-inventory:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-cloud-binary:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-comment-captions:before {
  content: "";
}

.fa-comments-question:before {
  content: "";
}

.fa-scribble:before {
  content: "";
}

.fa-rotate-exclamation:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-loader:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-user-pilot:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-code-pull-request-closed:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-face-dotted:before {
  content: "";
}

.fa-face-worried:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-court-sport:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-vector-circle:before {
  content: "";
}

.fa-car-circle-bolt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-flying-disc:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-square-down-right:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-seat-airline:before {
  content: "";
}

.fa-moon-over-sun:before, .fa-eclipse-alt:before {
  content: "";
}

.fa-pipe:before {
  content: "|";
}

.fa-file-medical:before {
  content: "";
}

.fa-potato:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-circle-a:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-butter:before {
  content: "";
}

.fa-blanket-fire:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-castle:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-chart-pie-simple-circle-dollar:before {
  content: "";
}

.fa-balloons:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-timeline-arrow:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-game-board-simple:before, .fa-game-board-alt:before {
  content: "";
}

.fa-circle-video:before, .fa-video-circle:before {
  content: "";
}

.fa-chart-scatter-bubble:before {
  content: "";
}

.fa-house-turret:before {
  content: "";
}

.fa-banana:before {
  content: "";
}

.fa-hand-holding-skull:before {
  content: "";
}

.fa-people-dress:before {
  content: "";
}

.fa-loveseat:before, .fa-couch-small:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-block-quote:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-money-check-dollar-pen:before, .fa-money-check-edit-alt:before {
  content: "";
}

.fa-up-from-line:before, .fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-grid-round-2-plus:before {
  content: "";
}

.fa-people-pants:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-circle-half:before {
  content: "";
}

.fa-brake-warning:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-saxophone-fire:before, .fa-sax-hot:before {
  content: "";
}

.fa-camera-web-slash:before, .fa-webcam-slash:before {
  content: "";
}

.fa-folder-medical:before {
  content: "";
}

.fa-folder-gear:before, .fa-folder-cog:before {
  content: "";
}

.fa-hand-wave:before {
  content: "";
}

.fa-arrow-up-arrow-down:before, .fa-sort-up-down:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-message-slash:before, .fa-comment-alt-slash:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-trash-can-check:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-person-simple:before {
  content: "";
}

.fa-arrow-turn-left-up:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-gear-code:before {
  content: "";
}

.fa-notes:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-trash-undo:before, .fa-trash-arrow-turn-left:before {
  content: "";
}

.fa-champagne-glass:before, .fa-glass-champagne:before {
  content: "";
}

.fa-objects-align-center-horizontal:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-trash-can-slash:before, .fa-trash-alt-slash:before {
  content: "";
}

.fa-screen-users:before, .fa-users-class:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-square-arrow-left:before, .fa-arrow-square-left:before {
  content: "";
}

.fa-square-8:before {
  content: "";
}

.fa-face-smile-hearts:before {
  content: "";
}

.fa-brackets-square:before, .fa-brackets:before {
  content: "";
}

.fa-laptop-arrow-down:before {
  content: "";
}

.fa-hockey-stick-puck:before {
  content: "";
}

.fa-house-tree:before {
  content: "";
}

.fa-signal-fair:before, .fa-signal-2:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-circle-dollar:before, .fa-dollar-circle:before, .fa-usd-circle:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-arrows-repeat:before, .fa-repeat-alt:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-turn-left-down:before {
  content: "";
}

.fa-person-running-fast:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-grill:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-chart-mixed:before, .fa-analytics:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-display-code:before, .fa-desktop-code:before {
  content: "";
}

.fa-face-drooling:before {
  content: "";
}

.fa-oil-temperature:before, .fa-oil-temp:before {
  content: "";
}

.fa-square-question:before, .fa-question-square:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-pan-food:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-tickets-airline:before, .fa-tickets-perforated-plane:before, .fa-tickets-plane:before {
  content: "";
}

.fa-tent-double-peak:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-nfc-slash:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-grid-2-plus:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-envelope-dot:before, .fa-envelope-badge:before {
  content: "";
}

.fa-magnifying-glass-waveform:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-bowl-chopsticks:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-circle-s:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-user-tie-hair:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-user-hair-mullet:before, .fa-business-front:before, .fa-party-back:before, .fa-trian-balbot:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-family-dress:before {
  content: "";
}

.fa-circle-x:before {
  content: "";
}

.fa-cabin:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-chart-simple-horizontal:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-hand-back-point-left:before {
  content: "";
}

.fa-message-dots:before, .fa-comment-alt-dots:before, .fa-messaging:before {
  content: "";
}

.fa-file-heart:before {
  content: "";
}

.fa-beer-mug:before, .fa-beer-foam:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-person-fairy:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-book-copy:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-square-c:before {
  content: "";
}

.fa-clock-two:before {
  content: "";
}

.fa-square-ellipsis-vertical:before {
  content: "";
}

.fa-calendar-users:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-bee:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-candy-bar:before, .fa-chocolate-bar:before {
  content: "";
}

.fa-xmark-large:before {
  content: "";
}

.fa-pinata:before {
  content: "";
}

.fa-file-ppt:before {
  content: "";
}

.fa-arrows-from-line:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-bowl-spoon:before {
  content: "";
}

.fa-hexagon-check:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-accent-grave:before {
  content: "`";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-circle-0:before {
  content: "";
}

.fa-dial-med-low:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-crab:before {
  content: "";
}

.fa-box-open-full:before, .fa-box-full:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-quotes:before {
  content: "";
}

.fa-pretzel:before {
  content: "";
}

.fa-t-rex:before {
  content: "";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-user-robot-xmarks:before {
  content: "";
}

.fa-message-quote:before, .fa-comment-alt-quote:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-folder-magnifying-glass:before, .fa-folder-search:before {
  content: "";
}

.fa-notebook:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-hexagon-image:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-file-svg:before {
  content: "";
}

.fa-crate-apple:before, .fa-apple-crate:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hand-back-point-right:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-snowflake-droplets:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-grid-4:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-face-sunglasses:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-square-0:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-square-this-way-up:before, .fa-box-up:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-square-ampersand:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-whiskey-glass-ice:before, .fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-user-bounty-hunter:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-diagram-sankey:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-circle-up-left:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-objects-align-left:before {
  content: "";
}

.fa-oil-can-drip:before {
  content: "";
}

.fa-face-smiling-hands:before {
  content: "";
}

.fa-broccoli:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-angle-90:before {
  content: "";
}

.fa-rectangle-terminal:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fish-bones:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-head-side-heart:before {
  content: "";
}

.fa-square-e:before {
  content: "";
}

.fa-meter-fire:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-money-from-bracket:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-timer:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-landmark-magnifying-glass:before {
  content: "";
}

.fa-grill-hot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-cup-togo:before, .fa-coffee-togo:before {
  content: "";
}

.fa-square-down-left:before {
  content: "";
}

.fa-burger-lettuce:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-chevrons-down:before, .fa-chevron-double-down:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-signal-good:before, .fa-signal-3:before {
  content: "";
}

.fa-location-question:before, .fa-map-marker-question:before {
  content: "";
}

.fa-floppy-disk-circle-xmark:before, .fa-floppy-disk-times:before, .fa-save-circle-xmark:before, .fa-save-times:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-peach:before {
  content: "";
}

.fa-taxi-bus:before {
  content: "";
}

.fa-bracket-curly:before, .fa-bracket-curly-left:before {
  content: "{";
}

.fa-lobster:before {
  content: "";
}

.fa-cart-flatbed-empty:before, .fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-colon:before {
  content: ":";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-table-picnic:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-circle-microphone-lines:before, .fa-microphone-circle-alt:before {
  content: "";
}

.fa-display-slash:before, .fa-desktop-slash:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-hands-holding-diamond:before, .fa-hand-receiving:before {
  content: "";
}

.fa-money-bill-simple-wave:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-book-arrow-right:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-droplet-degree:before, .fa-dewpoint:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-watermelon-slice:before {
  content: "";
}

.fa-circle-ellipsis:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-sd-cards:before {
  content: "";
}

.fa-jug-bottle:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-envelopes:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-nfc-pen:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-magnifying-glass-play:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-down-from-dotted-line:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-circle-divide:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-diagram-subtask:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-square-y:before {
  content: "";
}

.fa-user-doctor-hair:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-mushroom:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-wreath-laurel:before {
  content: "";
}

.fa-circle-exclamation-check:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-box-dollar:before, .fa-box-usd:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-user-headset:before {
  content: "";
}

.fa-arrows-retweet:before, .fa-retweet-alt:before {
  content: "";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-square-chevron-right:before, .fa-chevron-square-right:before {
  content: "";
}

.fa-lacrosse-stick-ball:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-user-magnifying-glass:before {
  content: "";
}

.fa-star-sharp:before {
  content: "";
}

.fa-comment-heart:before {
  content: "";
}

.fa-circle-1:before {
  content: "";
}

.fa-circle-star:before, .fa-star-circle:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-cloud-fog:before, .fa-fog:before {
  content: "";
}

.fa-waffle:before {
  content: "";
}

.fa-music-note:before, .fa-music-alt:before {
  content: "";
}

.fa-hexagon-exclamation:before {
  content: "";
}

.fa-cart-shopping-fast:before {
  content: "";
}

.fa-object-union:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-arrow-right-long-to-line:before {
  content: "";
}

.fa-square-arrow-down:before, .fa-arrow-square-down:before {
  content: "";
}

.fa-diamond-half-stroke:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-square-chevron-left:before, .fa-chevron-square-left:before {
  content: "";
}

.fa-phone-intercom:before {
  content: "";
}

.fa-link-horizontal:before, .fa-chain-horizontal:before {
  content: "";
}

.fa-mango:before {
  content: "";
}

.fa-music-note-slash:before, .fa-music-alt-slash:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-face-tongue-sweat:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-circle-p:before {
  content: "";
}

.fa-award-simple:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-pedestal:before {
  content: "";
}

.fa-chart-pyramid:before {
  content: "";
}

.fa-sidebar:before {
  content: "";
}

.fa-snowman-head:before, .fa-frosty-head:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-burger-glass:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-bars-filter:before {
  content: "";
}

.fa-paintbrush-pencil:before {
  content: "";
}

.fa-party-bell:before {
  content: "";
}

.fa-user-vneck-hair:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-hat-beach:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-fort:before {
  content: "";
}

.fa-cloud-check:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-face-smirking:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-rhombus:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-face-smile-tongue:before {
  content: "";
}

.fa-cart-circle-xmark:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-circle-phone-flip:before, .fa-phone-circle-alt:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-arrow-down-to-bracket:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-square-q:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-symbols:before, .fa-icons-alt:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-flask-gear:before {
  content: "";
}

.fa-highlighter-line:before {
  content: "";
}

.fa-bracket-square:before, .fa-bracket:before, .fa-bracket-left:before {
  content: "[";
}

.fa-island-tropical:before, .fa-island-tree-palm:before {
  content: "";
}

.fa-arrow-right-from-line:before, .fa-arrow-from-left:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-cake-slice:before, .fa-shortcake:before {
  content: "";
}

.fa-building-magnifying-glass:before {
  content: "";
}

.fa-peanut:before {
  content: "";
}

.fa-wrench-simple:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-tally-2:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-user-hair-long:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-arrow-left-from-arc:before {
  content: "";
}

.fa-file-circle-info:before {
  content: "";
}

.fa-face-disappointed:before {
  content: "";
}

.fa-lasso-sparkles:before {
  content: "";
}

.fa-clock-eleven:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-clock-ten:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-video-arrow-down-left:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right:before, .fa-save-circle-arrow-right:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-face-eyes-xmarks:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-display-arrow-down:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-olive-branch:before {
  content: "";
}

.fa-angle:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-square-divide:before {
  content: "";
}

.fa-folder-check:before {
  content: "";
}

.fa-signal-stream-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-eye-dropper-half:before {
  content: "";
}

.fa-store-lock:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-cloud-bolt-sun:before, .fa-thunderstorm-sun:before {
  content: "";
}

.fa-camera-slash:before {
  content: "";
}

.fa-comment-quote:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-message-code:before {
  content: "";
}

.fa-glass-half:before, .fa-glass-half-empty:before, .fa-glass-half-full:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-message-minus:before, .fa-comment-alt-minus:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-dinosaur:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-link-horizontal-slash:before, .fa-chain-horizontal-slash:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-nose:before {
  content: "";
}

.fa-arrow-left-to-arc:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-money-bill-simple:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-table-pivot:before {
  content: "";
}

.fa-filter-slash:before {
  content: "";
}

.fa-trash-can-undo:before, .fa-trash-can-arrow-turn-left:before, .fa-trash-undo-alt:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-person-to-door:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-signal-weak:before, .fa-signal-1:before {
  content: "";
}

.fa-clock-five:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-frame:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-ellipsis-stroke:before, .fa-ellipsis-h-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-face-expressionless:before {
  content: "";
}

.fa-down-to-dotted-line:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-cloud-minus:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-shield-minus:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-gauge-circle-minus:before {
  content: "";
}

.fa-brightness-low:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-location-check:before, .fa-map-marker-check:before {
  content: "";
}

.fa-coin-vertical:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-phone-hangup:before {
  content: "";
}

.fa-signature-slash:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-wheat-slash:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-face-vomit:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-tty-answer:before, .fa-teletype-answer:before {
  content: "";
}

.fa-mug-tea-saucer:before {
  content: "";
}

.fa-diagram-lean-canvas:before {
  content: "";
}

.fa-alt:before {
  content: "";
}

.fa-dial:before, .fa-dial-med-high:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-circle-trash:before, .fa-trash-circle:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-circle-quarters:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-tower-control:before {
  content: "";
}

.fa-arrow-up-triangle-square:before, .fa-sort-shapes-up:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-party-horn:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-sun-bright:before, .fa-sun-alt:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-conveyor-belt-arm:before {
  content: "";
}

.fa-lock-keyhole-open:before, .fa-lock-open-alt:before {
  content: "";
}

.fa-square-fragile:before, .fa-box-fragile:before, .fa-square-wine-glass-crack:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-square-n:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-meter:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-up-from-bracket:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-card-spade:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-arrow-right-to-line:before, .fa-arrow-to-right:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-flag-pennant:before, .fa-pennant:before {
  content: "";
}

.fa-conveyor-belt-empty:before {
  content: "";
}

.fa-user-group-simple:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-camcorder:before, .fa-video-handheld:before {
  content: "";
}

.fa-pancakes:before {
  content: "";
}

.fa-album-circle-user:before {
  content: "";
}

.fa-subtitles-slash:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-pen-paintbrush:before, .fa-pencil-paintbrush:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-magnifying-glass-music:before {
  content: "";
}

.fa-nesting-dolls:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-phone-missed:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-arrows-repeat-1:before, .fa-repeat-1-alt:before {
  content: "";
}

.fa-gun-slash:before {
  content: "";
}

.fa-avocado:before {
  content: "";
}

.fa-binary:before {
  content: "";
}

.fa-glasses-round:before, .fa-glasses-alt:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-ditto:before {
  content: "\"";
}

.fa-person-seat:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-ban-parking:before, .fa-parking-circle-slash:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-face-diagonal-mouth:before {
  content: "";
}

.fa-diagram-cells:before {
  content: "";
}

.fa-cricket-bat-ball:before, .fa-cricket:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-pen-line:before {
  content: "";
}

.fa-atom-simple:before, .fa-atom-alt:before {
  content: "";
}

.fa-ampersand:before {
  content: "&";
}

.fa-carrot:before {
  content: "";
}

.fa-arrow-up-from-line:before, .fa-arrow-from-bottom:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-pen-slash:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-square-star:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-clock-one:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-display-chart-up-circle-currency:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-circle-g:before {
  content: "";
}

.fa-circle-arrow-up-left:before {
  content: "";
}

.fa-coin-blank:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-vacuum:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-card-heart:before {
  content: "";
}

.fa-lightbulb-cfl-on:before {
  content: "";
}

.fa-melon:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-face-pouting:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-face-explode:before, .fa-exploding-head:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-transformer-bolt:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-cassette-vhs:before, .fa-vhs:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-object-intersect:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-block-brick:before, .fa-wall-brick:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-paragraph-left:before, .fa-paragraph-rtl:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-caravan-simple:before, .fa-caravan-alt:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-pencil-mechanical:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-user-doctor-message:before, .fa-user-md-chat:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-lighthouse:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-wifi-weak:before, .fa-wifi-1:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-delete-right:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-circle-quarter:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-people-simple:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-face-monocle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-calendar-lines:before, .fa-calendar-note:before {
  content: "";
}

.fa-arrow-down-big-small:before, .fa-sort-size-down:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-spinner-scale:before {
  content: "";
}

.fa-grip-dots-vertical:before {
  content: "";
}

.fa-face-viewfinder:before {
  content: "";
}

.fa-soft-serve:before, .fa-creemee:before {
  content: "";
}

.fa-h5:before {
  content: "";
}

.fa-hand-back-point-down:before {
  content: "";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-basket-shopping-minus:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-rectangle:before, .fa-rectangle-landscape:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-tomato:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-buildings:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-cart-flatbed-boxes:before, .fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-square-w:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-airplay:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-shield-quartered:before {
  content: "";
}

.fa-slash-forward:before {
  content: "/";
}

.fa-location-pen:before, .fa-map-marker-edit:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-pot-food:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-hand-back-point-ribbon:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-corner:before {
  content: "";
}

.fa-chevrons-right:before, .fa-chevron-double-right:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-note-medical:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-down-left:before {
  content: "";
}

.fa-share:before, .fa-mail-forward:before {
  content: "";
}

.fa-face-thinking:before {
  content: "";
}

.fa-turn-down-right:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-grid-horizontal:before {
  content: "";
}

.fa-message-dollar:before, .fa-comment-alt-dollar:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-columns-3:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-input-text:before {
  content: "";
}

.fa-window-flip:before, .fa-window-alt:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-scanner-gun:before, .fa-scanner:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-engine:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-circle-caret-right:before, .fa-caret-circle-right:before {
  content: "";
}

.fa-turn-left:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-audio-description-slash:before {
  content: "";
}

.fa-bell-ring:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-chess-pawn-piece:before, .fa-chess-pawn-alt:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-square-s:before {
  content: "";
}

.fa-rectangle-barcode:before, .fa-barcode-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-pompebled:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-watch-smart:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-sensor-cloud:before, .fa-sensor-smoke:before {
  content: "";
}

.fa-clapperboard-play:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-circle-4:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-onion:before {
  content: "";
}

.fa-clock-twelve-thirty:before {
  content: "";
}

.fa-arrow-down-to-dotted-line:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-comment-arrow-up:before {
  content: "";
}

.fa-garlic:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-calendar-heart:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-arrow-down-from-arc:before {
  content: "";
}

.fa-right-left-large:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-circles-overlap:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-bridge-suspension:before {
  content: "";
}

.fa-trash-check:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-shield-exclamation:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-grip-dots:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-pen-swirl:before {
  content: "";
}

.fa-falafel:before {
  content: "";
}

.fa-circle-2:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-down:before, .fa-arrow-alt-down:before {
  content: "";
}

.fa-hundred-points:before, .fa-100:before {
  content: "";
}

.fa-paperclip-vertical:before {
  content: "";
}

.fa-wind-warning:before, .fa-wind-circle-exclamation:before {
  content: "";
}

.fa-location-pin-slash:before, .fa-map-marker-slash:before {
  content: "";
}

.fa-face-sad-sweat:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-cupcake:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-toggle-large-off:before {
  content: "";
}

.fa-pen-fancy-slash:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-cards-blank:before {
  content: "";
}

.fa-circle-3:before {
  content: "";
}

.fa-bench-tree:before {
  content: "";
}

.fa-keyboard-brightness-low:before {
  content: "";
}

.fa-ski-boot-ski:before {
  content: "";
}

.fa-brain-circuit:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-block-brick-fire:before, .fa-firewall:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-face-smile-relaxed:before {
  content: "";
}

.fa-comment-xmark:before, .fa-comment-times:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-face-nose-steam:before {
  content: "";
}

.fa-circle-waveform-lines:before, .fa-waveform-circle:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-ferris-wheel:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-circle-t:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-grid-2:before {
  content: "";
}

.fa-camera-cctv:before, .fa-cctv:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-bed-front:before, .fa-bed-alt:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-file-gif:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-face-woozy:before {
  content: "";
}

.fa-cloud-question:before {
  content: "";
}

.fa-pineapple:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-gallery-thumbnails:before {
  content: "";
}

.fa-circle-j:before {
  content: "";
}

.fa-eyes:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-page-caret-up:before, .fa-file-caret-up:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-reflect-vertical:before {
  content: "";
}

.fa-shield-keyhole:before {
  content: "";
}

.fa-file-mp4:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bulldozer:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-square-sliders-vertical:before, .fa-sliders-v-square:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-message-middle:before, .fa-comment-middle-alt:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-square-z:before {
  content: "";
}

.fa-message-text:before, .fa-comment-alt-text:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.bf4b4210.woff2") format("woff2"), url("fa-light-300.80da0a92.ttf") format("truetype");
}

.fal, .fa-light {
  font-weight: 300;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.48858c97.woff2") format("woff2"), url("fa-regular-400.0b1caf9d.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.1a848c4a.woff2") format("woff2"), url("fa-solid-900.eea11a3a.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.8a93052b.woff2") format("woff2"), url("fa-brands-400.48e3f450.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-pixiv:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-brave:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-opensuse:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-square-letterboxd:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-google-scholar:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-signal-messenger:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-mintbit:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-brave-reverse:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-letterboxd:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-upwork:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-webflow:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

html {
  scroll-padding-top: 64px;
}

body {
  color: #333e48;
  -webkit-font-smoothing: antialiased;
  flex-direction: column;
  font-family: Lato, sans-serif;
  display: flex;
  overflow-y: scroll;
}

main {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
}

video {
  max-width: 100%;
  display: block;
}

p {
  margin-bottom: 1em;
}

.keil-nav {
  z-index: 2;
  background-color: #fff;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 5px #0000001a;
}

.keil-nav__container {
  justify-content: space-between;
  max-width: 80rem;
  height: 4rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
}

@media (width <= 640px) {
  .keil-nav__container {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.keil-nav__left {
  flex: 1 0 auto;
}

.keil-nav__right {
  flex: 0 auto;
}

.keil-nav__left, .keil-nav__right {
  align-items: center;
  display: flex;
}

.keil-nav__logo {
  border-right: 1px solid #cbcdd0;
  width: auto;
  height: 1.75rem;
  margin-right: .5rem;
  padding: .25rem .5rem .25rem 0;
}

.keil-nav__title {
  color: #0091bd;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
}

.keil-nav__menu {
  display: none;
}

@media (width >= 768px) {
  .keil-nav__menu {
    align-items: center;
    margin: 0 0 0 1.5rem;
    padding: 0;
    list-style-type: none;
    display: flex;
  }
}

.keil-nav__menu > * + * {
  margin-left: 1.5rem;
}

.keil-nav__menuitem {
  color: #333e48;
  cursor: pointer;
  padding: 1.25rem 0;
  font-weight: 400;
  position: relative;
}

.keil-nav__menuitem[aria-haspopup="true"]:after {
  font: var(--fa-font-light);
  content: "";
  margin-left: .5rem;
  font-size: .75rem;
}

.keil-nav__menuitem[aria-haspopup="true"]:hover:after {
  color: #333e48;
  content: "";
}

.keil-nav__menuitem:hover > a {
  color: #000;
}

.keil-nav__menuitem > a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

.keil-nav__submenu {
  visibility: hidden;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  width: 15rem;
  margin-left: -7.5rem;
  padding: 0;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 50%;
}

.keil-nav__submenu a {
  color: #333e48;
  padding: .5rem;
  text-decoration: none;
  display: block;
}

.keil-nav__submenu a:hover {
  background-color: #e5eceb66;
}

.keil-nav__submenu:after {
  content: "";
  border: .5rem solid #0000;
  border-bottom-color: #e1e1e1;
  margin-left: -.5rem;
  position: absolute;
  bottom: 100%;
  left: 50%;
}

.keil-nav__menuitem:hover .keil-nav__submenu {
  visibility: visible;
}

.keil-nav__cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  margin-left: 2rem;
  padding: .6em;
  font-family: inherit;
  font-size: .9rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .keil-nav__cta {
    white-space: nowrap;
  }
}

.keil-nav__cta:hover, .keil-nav__cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

@media (width <= 768px) {
  .keil-nav__cta {
    display: none;
  }
}

.keil-nav__control {
  background: none;
  border: none;
}

.keil-nav__control:after {
  font: var(--fa-font-light);
  content: "";
  color: #333e48;
}

@media (width >= 768px) {
  .keil-nav__control:after {
    display: none;
  }
}

.keil-nav__control[aria-expanded="true"]:after {
  content: "";
}

.keil-nav-mob__container {
  width: 100%;
  height: calc(100vh - 4rem);
  display: none;
  position: absolute;
}

.keil-nav-mob__container[data-expanded] {
  display: block;
}

@media (width >= 768px) {
  .keil-nav-mob__container[data-expanded] {
    display: none;
  }
}

.keil-nav-mob__container button {
  color: #333e48;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.keil-nav-mob__container button i {
  margin-right: 1rem;
}

.keil-nav-mob__container a {
  color: #333e48;
  text-decoration: none;
  display: block;
}

.keil-nav-mob__menu, .keil-nav-mob__submenu {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1.5rem;
  list-style-type: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.keil-nav-mob__menu > * + *, .keil-nav-mob__submenu > * + * {
  margin-top: 1.5rem;
}

.keil-nav-mob__menu a, .keil-nav-mob__submenu a {
  align-items: center;
  text-decoration: none;
  display: flex;
}

.keil-nav-mob__menu a span, .keil-nav-mob__submenu a span {
  flex: 1 0 auto;
}

.keil-nav-mob__menu a {
  color: #333e48;
  font-weight: 400;
}

.keil-nav-mob__menuitem[aria-expanded="true"] .keil-nav-mob__submenu {
  display: block;
}

.keil-nav-mob__submenu {
  display: none;
}

.keil-nav-mob__submenu a {
  color: #5d6c7a;
  font-weight: 400;
}

.keil-footer {
  background: #010101;
  padding: 0;
  font-size: 14px;
}

.keil-footer__container {
  max-width: 80rem;
  margin: 2rem auto;
  padding: 0 1rem;
}

@media (width >= 640px) {
  .keil-footer__container {
    padding: 0 2rem;
  }
}

.keil-footer hr {
  border: 0;
  border-bottom: 1px solid #fff;
  width: 100%;
  margin: 2rem 0;
}

.keil-footer-links {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  display: grid;
}

@media (width >= 768px) {
  .keil-footer-links {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .keil-footer-links-collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    display: grid;
  }
}

.keil-footer-links-collection > :first-child {
  margin-bottom: 2rem;
}

@media (width >= 768px) {
  .keil-footer-links-collection > :first-child {
    margin-bottom: 0;
  }
}

.keil-footer-links h2 {
  color: #a3a8ae;
  margin-bottom: .8rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.keil-footer-links a {
  color: #fff;
  font-weight: normal;
  text-decoration: none;
}

.keil-footer-links a:hover {
  color: #ff6b00;
}

.keil-footer-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.keil-footer-links li {
  margin-bottom: .5rem;
}

.keil-footer img {
  float: right;
  width: auto;
  height: 2rem;
}

.keil-footer__legal-links {
  flex-flow: wrap;
  gap: 1rem;
  margin-top: 3rem;
  padding: 0;
  display: flex;
}

@media (width >= 768px) {
  .keil-footer__legal-links li:after {
    content: "|";
    color: #fff;
  }
}

.keil-footer__legal-links li:last-child:after {
  content: none;
}

.keil-footer__legal-links a {
  color: #a3a8ae;
  white-space: nowrap;
  padding-right: .5rem;
  font-weight: normal;
  text-decoration: none;
}

.keil-footer__legal-links a:hover {
  color: #c75300;
}

.keil-footer__copyright {
  color: #a3a8ae;
  margin-top: 2rem;
}

.board-pagination {
  justify-content: center;
  display: flex;
}

.board-pagination__list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.board-pagination__page {
  text-align: center;
  color: #0091bd;
  width: 38px;
  margin: 0 10px;
  line-height: 36px;
}

.board-pagination__page a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.board-pagination__page:hover {
  color: #c75300;
}

@media (width <= 640px) {
  .board-pagination__page {
    display: none;
  }
}

.board-pagination__current_page {
  text-align: center;
  color: #e5eceb;
  cursor: default;
  background-color: #333e48;
  border: 1px solid #333e48;
  border-radius: 50%;
  width: 38px;
  margin: 0 10px;
  line-height: 36px;
}

.board-pagination__next, .board-pagination__previous {
  text-align: center;
  color: #333e48;
  background-color: #e5eceb;
  border: 1px solid #333e48;
  border-radius: 50%;
  width: 38px;
  margin: 0 10px;
  line-height: 36px;
}

.board-pagination__next a, .board-pagination__previous a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.board-pagination__next:hover, .board-pagination__previous:hover {
  color: #e5eceb;
  background-color: #333e48;
  border: 1px solid #333e48;
  border-radius: 50%;
}

.board-pagination__next--disabled, .board-pagination__previous--disabled, .board-pagination__next--disabled:hover, .board-pagination__previous--disabled:hover {
  color: #e5eceb;
  background-color: #cbcdd0;
  border-color: #cbcdd0;
}

.board-pagination__spacer {
  text-align: center;
  cursor: default;
  width: 38px;
  margin: 0 10px;
  line-height: 36px;
}

@media (width <= 640px) {
  .board-pagination__spacer {
    display: none;
  }
}

.kl-banner {
  background: #e5eceb;
  padding: .8rem;
  display: flex;
}

.kl-banner__text {
  text-align: center;
  flex: 1;
  margin: 0;
}

.kl-banner__text a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.kl-banner__text a:hover {
  color: #c75300;
}

.kl-banner__text i {
  margin-right: .25rem;
}

.kl-banner__dismiss {
  background: initial;
  border: none;
}

.kl-banner__dismiss:hover {
  cursor: pointer;
}

#hardware-detector-container {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
}

#hardware-detector {
  color: #333e48;
  cursor: pointer;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: .6em;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  #hardware-detector {
    white-space: nowrap;
  }
}

#hardware-detector:hover, #hardware-detector:focus {
  color: #333e48;
  background-color: #f6d452;
}

#hardware-detector i {
  margin: 0 5px;
}

.hardware-detector__error, .hardware-detector__detected_board, .hardware-detector__loading {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
  margin: .5em .5em .5em 0;
  padding: 1em;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.hardware-detector__error a, .hardware-detector__detected_board a, .hardware-detector__loading a {
  font-weight: bold;
  text-decoration: none;
}

.hardware-detector__error p:last-child, .hardware-detector__detected_board p:last-child, .hardware-detector__loading p:last-child {
  margin: 0;
}

.hardware-detector__detected_board__header, .hardware-detector__detected_board__board {
  margin-bottom: .5em;
}

.hardware-detector__detected_board__device {
  margin-bottom: 1em;
  overflow: hidden;
}

.hardware-detector__detected_board__board__label, .hardware-detector__detected_board__device__label {
  margin-right: 5px;
}

.hardware-detector__detected_board__board__value, .hardware-detector__detected_board__device__value {
  float: right;
}

.hardware-detector__detected_board__device__value {
  margin: 0;
  list-style-type: none;
}

[data-accordion] > [data-accordion-content] {
  display: none;
}

[data-accordion][aria-expanded="true"] > [data-accordion-content] {
  display: block;
}

.keil-bulleted-list {
  padding: 0 .5rem;
  list-style-type: none;
}

.keil-bulleted-list > * + * {
  margin-top: .5rem;
}

.keil-bulleted-list__item {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.guide-markdown h1, .pack-overview-markdown h1 {
  margin-top: 1.2em;
  margin-bottom: .8em;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .guide-markdown h1, .pack-overview-markdown h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.guide-markdown h2, .pack-overview-markdown h2 {
  margin-top: 1.1em;
  margin-bottom: .7em;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

@media (width >= 640px) {
  .guide-markdown h2, .pack-overview-markdown h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
}

.guide-markdown h3, .pack-overview-markdown h3 {
  margin-top: 1em;
  margin-bottom: .6em;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.guide-markdown h4, .pack-overview-markdown h4 {
  margin-top: .8em;
  margin-bottom: .5em;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.guide-markdown h5, .guide-markdown h6, .pack-overview-markdown h5, .pack-overview-markdown h6 {
  margin-top: .6em;
  margin-bottom: .4em;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.guide-markdown p, .pack-overview-markdown p {
  margin: 1em 0;
  font-size: 1em;
  line-height: 1.5;
}

.guide-markdown p code, .pack-overview-markdown p code {
  color: #11809f;
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: .1rem .2rem;
  font-family: Monaco, monospace;
  font-size: 14px;
}

.guide-markdown li > p, .pack-overview-markdown li > p {
  margin: 0;
}

.guide-markdown br, .pack-overview-markdown br {
  line-height: 1.5;
}

.guide-markdown a, .pack-overview-markdown a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.guide-markdown a:hover, .pack-overview-markdown a:hover {
  color: #c75300;
}

.guide-markdown ul, .guide-markdown ol, .pack-overview-markdown ul, .pack-overview-markdown ol {
  margin: 1em 0;
  padding-left: 1.5em;
}

.guide-markdown ul, .pack-overview-markdown ul {
  list-style-type: disc;
}

.guide-markdown ol, .pack-overview-markdown ol {
  list-style-type: decimal;
}

.guide-markdown li, .pack-overview-markdown li {
  margin: .25em 0;
}

.guide-markdown li code, .pack-overview-markdown li code {
  color: #11809f;
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: .1rem .2rem;
  font-family: Monaco, monospace;
  font-size: 14px;
}

.guide-markdown blockquote, .pack-overview-markdown blockquote {
  background-color: #eee;
  border-left: 4px solid #c2c7c9;
  margin: 1.5em 0;
  padding: .5em 10px;
  font-style: italic;
}

.guide-markdown code, .pack-overview-markdown code {
  background-color: #f7f7f7;
  border-radius: 4px;
  font-family: Monaco, monospace;
  font-size: 14px;
}

.guide-markdown pre, .pack-overview-markdown pre {
  background-color: #f7f7f7;
  border-radius: 3px;
  padding: 10px;
  overflow-x: auto;
}

.guide-markdown hr, .pack-overview-markdown hr {
  border: none;
  border-top: 1px solid #eee;
  margin: 1.5em 0;
}

.guide-markdown table, .pack-overview-markdown table {
  border-collapse: collapse;
  width: 100%;
  margin: 1.5em 0;
}

.guide-markdown table thead, .pack-overview-markdown table thead {
  background-color: #eee;
}

.guide-markdown table th, .guide-markdown table td, .pack-overview-markdown table th, .pack-overview-markdown table td {
  text-align: left;
  border: 1px solid #c2c7c9;
  padding: 8px;
}

.guide-markdown table th, .pack-overview-markdown table th {
  font-weight: bold;
}

.guide-markdown table tbody tr:nth-child(2n), .pack-overview-markdown table tbody tr:nth-child(2n) {
  background-color: #f7f7f7;
}

.guide-markdown img, .pack-overview-markdown img {
  max-width: 100%;
  height: auto;
  margin: 1.5em 0;
}

.license-tabs {
  border-bottom: 1px solid #e1e1e1;
  margin: 2rem 0;
}

.license-tabs ul {
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.license-tabs ul li {
  margin: 0 1.5em;
  padding: .5em 0;
}

.license-tabs ul li:first-child {
  margin-left: 0;
}

@media (width <= 30rem) {
  .license-tabs ul {
    justify-content: space-between;
  }

  .license-tabs ul li {
    margin: 0;
  }
}

.license-tabs a {
  color: #0091bd;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.license-tabs a:hover {
  color: #333e48;
}

@media (width <= 640px) {
  .license-tabs a {
    font-size: 16px;
  }
}

.license-tabs--selected {
  border-bottom: 5px solid #0091bd;
}

.license-tabs--selected a {
  color: #333e48;
}

.license-wrapper {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.license-wrapper pre {
  white-space: pre-wrap;
}

.km6-community-strip {
  padding: 3rem 0;
}

.km6-community-header, .km6-community-uvision, .km6-community-vscode, .km6-community-conclusion {
  max-width: 80rem;
  margin: 0 auto 4rem;
  padding: 0 2rem;
}

@media (width <= 640px) {
  .km6-community-header, .km6-community-uvision, .km6-community-vscode, .km6-community-conclusion {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.km6-community-header__title {
  color: #0091bd;
  margin-bottom: 1.5rem;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}

@media (width >= 640px) {
  .km6-community-header__title {
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
  }
}

.km6-community-header__description {
  font-size: 1.25rem;
}

.km6-community-header a {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.km6-community-header a:hover {
  color: #c75300;
}

.km6-community-uvision__title, .km6-community-vscode__title {
  margin-bottom: 1rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.km6-community-uvision img, .km6-community-vscode img {
  margin: 2rem auto;
}

.km6-installation-instructions {
  margin: 0;
  padding: 0 0 0 1rem;
  font-size: 18px;
}

.km6-installation-instructions li {
  margin-bottom: 1rem;
}

.km6-installation-instructions li p a {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.km6-installation-instructions li p a:hover {
  color: #c75300;
}

.km6-installation-instructions-download__button {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  width: 225px;
  padding: .6em;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-installation-instructions-download__button {
    white-space: nowrap;
  }
}

.km6-installation-instructions-download__button:hover, .km6-installation-instructions-download__button:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-installation-instructions-download__button i {
  margin-right: .1em;
}

.km6-installation-instructions-download__requirements {
  color: #a3a8ae;
  white-space: nowrap;
  margin-left: .5rem;
  font-size: .9rem;
}

.km6-community-conclusion__title {
  margin-bottom: 1rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.km6-community-conclusion__text {
  font-size: 18px;
}

.km6-community-conclusion a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.km6-community-conclusion a:hover {
  color: #c75300;
}

.km6-community-conclusion video {
  margin: 2rem auto;
}

.video-wrapper {
  justify-content: center;
  padding: 4rem;
  display: flex;
}

@media (width <= 640px) {
  .video-wrapper {
    padding: 1rem;
  }
}

.error-page {
  text-align: center;
  margin: auto;
}

@media (width <= 60rem) {
  .error-page {
    padding: 0 2rem;
  }
}

.error-page h1 {
  margin-bottom: .5rem;
  font-size: 60px;
  line-height: 1.2;
}

@media (width <= 40rem) {
  .error-page h1 {
    font-size: 50px;
  }
}

.error-page__button {
  color: #333e48;
  cursor: pointer;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  min-width: 300px;
  margin-bottom: 2rem;
  padding: .6em;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .error-page__button {
    white-space: nowrap;
  }
}

.error-page__button:hover, .error-page__button:focus {
  color: #333e48;
  background-color: #f6d452;
}

.error-page__button i {
  margin-right: .2rem;
  font-size: .9rem;
}

.cmsis {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
  font-size: 1.1rem;
}

@media (width <= 640px) {
  .cmsis {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.cmsis__header {
  align-self: center;
  margin-bottom: .8rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .cmsis__header {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.cmsis__header-container {
  justify-content: space-between;
  margin-top: 2rem;
  display: flex;
}

.cmsis__info {
  margin-top: 1.2rem;
  margin-bottom: 2rem;
  font-size: 16px;
}

.cmsis__resources {
  margin-top: 2.5rem;
}

.cmsis__resources h1 {
  margin-bottom: .8rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.cmsis a {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.cmsis a:hover {
  color: #c75300;
}

.cmsis ul {
  margin-top: .5rem;
}

.cmsis li {
  margin-bottom: .7rem;
}

.km6-editions-header-strip {
  padding: 3rem 0;
}

.km6-editions-tools-strip {
  padding: 5.5rem 0;
}

.km6-editions-comparison-strip {
  background-color: #f7f7f7;
  padding: 3rem 0 4rem;
}

.km6-editions-header, .km6-editions-middleware, .km6-editions-functional-safety, .km6-editions-comparison, .km6-editions-whats-included-container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
}

@media (width <= 640px) {
  .km6-editions-header, .km6-editions-middleware, .km6-editions-functional-safety, .km6-editions-comparison, .km6-editions-whats-included-container {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.km6-editions-middleware__content, .km6-editions-functional-safety__content {
  flex-direction: column;
  align-items: flex-start;
  gap: 10%;
  display: flex;
}

.km6-editions-middleware__text, .km6-editions-middleware__image, .km6-editions-functional-safety__text, .km6-editions-functional-safety__image {
  flex: 0 0 45%;
}

.km6-editions-middleware__text > * + *, .km6-editions-functional-safety__text > * + * {
  margin-top: 24px;
}

.km6-editions-middleware__text p, .km6-editions-functional-safety__text p {
  margin-bottom: 0;
  font-size: 1.25rem;
}

.km6-editions-middleware__note, .km6-editions-functional-safety__note {
  color: #0091bd;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.km6-editions-middleware__note i, .km6-editions-functional-safety__note i {
  margin-right: .5rem;
}

.km6-editions-middleware__heading, .km6-editions-functional-safety__heading {
  margin-bottom: .8rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-editions-middleware__heading, .km6-editions-functional-safety__heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-editions-middleware__image, .km6-editions-functional-safety__image {
  order: 1;
  margin-top: 24px;
}

.km6-editions-middleware__image img, .km6-editions-functional-safety__image img {
  height: auto;
}

@media (width >= 768px) {
  .km6-editions-middleware__content, .km6-editions-functional-safety__content {
    flex-direction: row;
  }

  .km6-editions-middleware__text, .km6-editions-middleware__image, .km6-editions-functional-safety__text, .km6-editions-functional-safety__image {
    order: unset;
    margin: 0;
  }
}

.km6-editions-middleware a, .km6-editions-functional-safety a {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.km6-editions-middleware a:hover, .km6-editions-functional-safety a:hover {
  color: #c75300;
}

.km6-editions-whats-included-container, .km6-editions-middleware {
  margin-bottom: 88px;
}

.km6-editions-header__title, .km6-editions-header__subtitle {
  color: #0091bd;
  margin-bottom: 1.5rem;
}

.km6-editions-header__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.km6-editions-header__subtitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}

@media (width >= 640px) {
  .km6-editions-header__subtitle {
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
  }
}

.km6-editions-header__description {
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

.km6-editions-header__cta-list {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.km6-editions-header__cta-list > * {
  width: 100%;
}

@media (width >= 640px) {
  .km6-editions-header__cta-list > * {
    width: 15rem;
  }

  .km6-editions-header__cta-list {
    flex-direction: row;
  }
}

.km6-editions-header__features-cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: none;
}

@media (width >= 768px) {
  .km6-editions-header__features-cta {
    white-space: nowrap;
  }
}

.km6-editions-header__features-cta:hover, .km6-editions-header__features-cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

@media (width >= 640px) {
  .km6-editions-header__features-cta {
    display: initial;
  }
}

.km6-editions-header__buy-cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 2px solid #ffc700;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-editions-header__buy-cta {
    white-space: nowrap;
  }
}

.km6-editions-header__buy-cta:hover, .km6-editions-header__buy-cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-editions-whats-included {
  background-color: #f7f7f7;
  border-radius: 12px;
  margin-top: 3rem;
  padding: 2rem 3rem;
}

@media (width >= 768px) {
  .km6-editions-whats-included {
    background-image: url("whats-included.ce8b5703.png");
    background-position: 400px 110px;
    background-repeat: no-repeat;
    background-size: 1200px 400px;
  }
}

.km6-editions-whats-included__title {
  margin-bottom: 1rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-editions-whats-included__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-editions-whats-included__subtitle {
  color: #0091bd;
  margin-bottom: .5rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.km6-editions-whats-included__features-title {
  font-weight: bold;
}

.km6-editions-ecosystem-list {
  grid-template-columns: auto;
  gap: 2rem;
  display: grid;
}

@media (width >= 640px) {
  .km6-editions-ecosystem-list {
    grid-template-columns: repeat(2, auto);
  }
}

@media (width >= 768px) {
  .km6-editions-ecosystem-list {
    grid-template-columns: repeat(3, auto);
  }
}

.km6-editions-ecosystem {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1.5rem;
  display: flex;
}

.km6-editions-ecosystem i {
  color: #0091bd;
  font-size: 3rem;
}

.km6-editions-ecosystem__heading {
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.km6-editions-ecosystem__description {
  text-align: center;
  flex-grow: 1;
  margin: 0;
}

.km6-editions-ecosystem__cta {
  color: #11809f;
  font-weight: normal;
  text-decoration: none;
}

.km6-editions-ecosystem__cta:hover {
  color: #c75300;
}

.km6-editions-ecosystem__cta:after {
  font: var(--fa-font-light);
  content: "";
  color: #ffc700;
  margin-left: .25rem;
}

.km6-editions-comparison-strip {
  display: none;
}

@media (width >= 640px) {
  .km6-editions-comparison-strip {
    display: block;
  }
}

.km6-editions-functional-safety .keil-bulleted-list {
  font-size: 1.25rem;
}

.km6-editions-functional-safety .keil-bulleted-list > * + * {
  margin-top: 1.5rem;
}

.km6-editions-comparison {
  text-align: center;
}

.km6-editions-comparison__heading {
  margin-bottom: 1rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-editions-comparison__heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-editions-comparison__table {
  margin: 0 auto;
  display: inline-block;
}

.km6-pro-header {
  background-image: url("plus_pattern.c46bb217.svg");
  background-position: 750px;
  background-repeat: no-repeat;
  padding: 5rem 0 3rem;
}

.km6-pro-header__text {
  margin-bottom: 1.5rem;
  margin-right: 3rem;
  font-size: 1.25rem;
}

.km6-pro-header__title {
  color: #0091bd;
  margin-bottom: 1.5rem;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}

@media (width >= 640px) {
  .km6-pro-header__title {
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
  }
}

.km6-pro-header__cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-pro-header__cta {
    white-space: nowrap;
  }
}

.km6-pro-header__cta:hover, .km6-pro-header__cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

@media (width <= 640px) {
  .km6-pro-header__cta {
    width: 100%;
  }
}

@media (width <= 768px) {
  .km6-pro-header {
    background-image: none;
  }
}

.km6-pro-header__text {
  margin-bottom: 4rem;
}

@media (width >= 768px) {
  .km6-pro-header__text {
    max-width: 45rem;
  }
}

.km6-pro-header__cta-list {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.km6-pro-header__cta-list > * {
  width: 100%;
}

@media (width >= 640px) {
  .km6-pro-header__cta-list > * {
    width: 15rem;
  }

  .km6-pro-header__cta-list {
    flex-direction: row;
  }
}

.km6-pro-header__learn-cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-pro-header__learn-cta {
    white-space: nowrap;
  }
}

.km6-pro-header__learn-cta:hover, .km6-pro-header__learn-cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-pro-header__tools-cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 2px solid #ffc700;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-pro-header__tools-cta {
    white-space: nowrap;
  }
}

.km6-pro-header__tools-cta:hover, .km6-pro-header__tools-cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-pro-header-strip {
  padding: 4rem 0 5.5rem;
}

.km6-pro-hw-get-started-strip {
  padding: 5.5rem 0;
}

.km6-pro-tools-strip, .km6-editions-discovery-strip {
  background-color: #f7f7f7;
  padding: 3rem 0 4rem;
}

.km6-pro-header, .km6-pro-video, .km6-pro-hw-get-started, .km6-pro-tools, .km6-editions-discovery {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
}

@media (width <= 640px) {
  .km6-pro-header, .km6-pro-video, .km6-pro-hw-get-started, .km6-pro-tools, .km6-editions-discovery {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.km6-pro-video video {
  border-radius: 10px;
  margin: 0;
}

.km6-pro-hw-get-started__content {
  flex-direction: column;
  align-items: flex-start;
  gap: 10%;
  display: flex;
}

.km6-pro-hw-get-started__text, .km6-pro-hw-get-started__image {
  flex: 0 0 45%;
}

.km6-pro-hw-get-started__text > * + * {
  margin-top: 24px;
}

.km6-pro-hw-get-started__text p {
  margin-bottom: 0;
  font-size: 1.25rem;
}

.km6-pro-hw-get-started__note {
  color: #0091bd;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.km6-pro-hw-get-started__note i {
  margin-right: .5rem;
}

.km6-pro-hw-get-started__heading {
  margin-bottom: .8rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-pro-hw-get-started__heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-pro-hw-get-started__image {
  order: 1;
  margin-top: 24px;
}

.km6-pro-hw-get-started__image img {
  height: auto;
}

@media (width >= 768px) {
  .km6-pro-hw-get-started__content {
    flex-direction: row;
  }

  .km6-pro-hw-get-started__text, .km6-pro-hw-get-started__image {
    order: unset;
    margin: 0;
  }
}

.km6-pro-hw-get-started__title {
  margin-bottom: 1.5rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-pro-hw-get-started__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-pro-hw-get-started__description {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.km6-pro-hw-get-started__description a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.km6-pro-hw-get-started__description a:hover {
  color: #c75300;
}

@media (width <= 640px) {
  .km6-pro-hw-get-started__cta-search {
    width: 100%;
  }

  .km6-pro-hw-get-started__cta-detect {
    display: none;
  }
}

body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-detect #hardware-detector {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-detect #hardware-detector {
    white-space: nowrap;
  }
}

body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-detect #hardware-detector:hover, body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-detect #hardware-detector:focus {
  color: #333e48;
  background-color: #f6d452;
}

body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-search {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 2px solid #ffc700;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-search {
    white-space: nowrap;
  }
}

body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-search:hover, body[data-web-usb-available="true"] .km6-pro-hw-get-started__cta-search:focus {
  color: #333e48;
  background-color: #f6d452;
}

body[data-web-usb-available="false"] .km6-pro-hw-get-started__cta-detect {
  display: none;
}

body[data-web-usb-available="false"] .km6-pro-hw-get-started__cta-search {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  body[data-web-usb-available="false"] .km6-pro-hw-get-started__cta-search {
    white-space: nowrap;
  }
}

body[data-web-usb-available="false"] .km6-pro-hw-get-started__cta-search:hover, body[data-web-usb-available="false"] .km6-pro-hw-get-started__cta-search:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-pro-tools__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-pro-tools__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-pro-tools__subtitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.km6-pro-tools__title, .km6-pro-tools__subtitle {
  margin-bottom: 1rem;
}

.km6-pro-tools__description {
  margin: 0;
}

.km6-pro-tools__compare-link {
  margin-bottom: 1.5rem;
}

.km6-pro-tools__cli-link {
  margin-bottom: 0;
}

.km6-pro-tools__cli-link a, .km6-pro-tools__compare-link a {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.km6-pro-tools__cli-link a:hover, .km6-pro-tools__compare-link a:hover {
  color: #c75300;
}

.km6-pro-tools-list {
  grid-template-columns: auto;
  gap: 3rem;
  margin: 0 0 1.5rem;
  padding: 0;
  display: grid;
}

@media (width >= 768px) {
  .km6-pro-tools-list {
    grid-template-columns: repeat(2, auto);
  }
}

@media (width >= 1024px) {
  .km6-pro-tools-list {
    grid-template-columns: repeat(3, auto);
  }
}

.km6-pro-tools-list li {
  list-style-type: none;
}

.km6-pro-tools-list .km6-pro-tool-vscode__image, .km6-pro-tools-list .km6-pro-tool-cloud__image, .km6-pro-tools-list .km6-pro-tool-uvision__image {
  border-radius: 7px 7px 0 0;
  overflow: hidden;
}

.km6-pro-tools-list .km6-pro-tool-vscode img, .km6-pro-tools-list .km6-pro-tool-cloud img, .km6-pro-tools-list .km6-pro-tool-uvision img {
  border-radius: 7px 7px 0 0;
  margin-top: 1.1rem;
  margin-left: 1.2rem;
  box-shadow: 0 5px 15px #00000059;
}

.km6-pro-tools-list .km6-pro-tool-vscode__description, .km6-pro-tools-list .km6-pro-tool-cloud__description, .km6-pro-tools-list .km6-pro-tool-uvision__description {
  min-height: 15rem;
}

.km6-pro-tools-list .km6-pro-tool-vscode {
  background-color: #fff;
  border: 2px solid #ffc700;
  border-radius: 10px;
}

.km6-pro-tools-list .km6-pro-tool-vscode__image {
  width: 100%;
  height: 170px;
}

.km6-pro-tools-list .km6-pro-tool-vscode__description {
  color: #333e48;
  border-radius: 0 0 5px 5px;
  padding: 1.5rem 1rem;
}

.km6-pro-tools-list .km6-pro-tool-vscode__description i {
  margin-bottom: 1rem;
  font-size: 18px;
}

.km6-pro-tools-list .km6-pro-tool-vscode__description p {
  min-height: 8rem;
}

.km6-pro-tools-list .km6-pro-tool-vscode__title {
  margin-bottom: .75rem;
  font-size: 27px;
  font-weight: normal;
  line-height: 32px;
}

.km6-pro-tools-list .km6-pro-tool-vscode__cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 2px solid #ffc700;
  border-radius: 28px;
  width: 100%;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-pro-tools-list .km6-pro-tool-vscode__cta {
    white-space: nowrap;
  }
}

.km6-pro-tools-list .km6-pro-tool-vscode__cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-pro-tools-list .km6-pro-tool-vscode__cta {
    white-space: nowrap;
  }
}

.km6-pro-tools-list .km6-pro-tool-vscode__cta:hover, .km6-pro-tools-list .km6-pro-tool-vscode__cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-pro-tools-list .km6-pro-tool-vscode__image {
  background-color: #f6d452;
}

.km6-pro-tools-list .km6-pro-tool-cloud, .km6-pro-tools-list .km6-pro-tool-uvision {
  background-color: #fff;
  border: 1px solid #e5eceb;
  border-radius: 10px;
}

.km6-pro-tools-list .km6-pro-tool-cloud__image, .km6-pro-tools-list .km6-pro-tool-uvision__image {
  width: 100%;
  height: 170px;
}

.km6-pro-tools-list .km6-pro-tool-cloud__description, .km6-pro-tools-list .km6-pro-tool-uvision__description {
  color: #333e48;
  border-radius: 0 0 5px 5px;
  padding: 1.5rem 1rem;
}

.km6-pro-tools-list .km6-pro-tool-cloud__description i, .km6-pro-tools-list .km6-pro-tool-uvision__description i {
  margin-bottom: 1rem;
  font-size: 18px;
}

.km6-pro-tools-list .km6-pro-tool-cloud__description p, .km6-pro-tools-list .km6-pro-tool-uvision__description p {
  min-height: 8rem;
}

.km6-pro-tools-list .km6-pro-tool-cloud__title, .km6-pro-tools-list .km6-pro-tool-uvision__title {
  margin-bottom: .75rem;
  font-size: 27px;
  font-weight: normal;
  line-height: 32px;
}

.km6-pro-tools-list .km6-pro-tool-cloud__cta, .km6-pro-tools-list .km6-pro-tool-uvision__cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 2px solid #ffc700;
  border-radius: 28px;
  width: 100%;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-pro-tools-list .km6-pro-tool-cloud__cta, .km6-pro-tools-list .km6-pro-tool-uvision__cta {
    white-space: nowrap;
  }
}

.km6-pro-tools-list .km6-pro-tool-cloud__cta:hover, .km6-pro-tools-list .km6-pro-tool-cloud__cta:focus, .km6-pro-tools-list .km6-pro-tool-uvision__cta:hover, .km6-pro-tools-list .km6-pro-tool-uvision__cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-pro-tools-list .km6-pro-tool-cloud__image, .km6-pro-tools-list .km6-pro-tool-uvision__image {
  background-color: #e5eceb;
}

.km6-editions-discovery__heading {
  margin-bottom: 1.5rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .km6-editions-discovery__heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.km6-editions-discovery__description {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.km6-editions-table {
  border-collapse: collapse;
  text-align: left;
  border-style: hidden;
  margin: 3rem;
  display: none;
}

.km6-editions-table th, .km6-editions-table td {
  border: 1px solid #e1e1e1;
  padding: 1rem;
}

@media (width >= 640px) {
  .km6-editions-table {
    display: table;
  }
}

.km6-editions-table__header th {
  vertical-align: top;
  background-color: #f7f7f7;
  width: 25%;
  max-width: 20rem;
  position: sticky;
  top: 64px;
}

.km6-editions-table__header h3 {
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.km6-editions-table__header p {
  margin-bottom: .5rem;
  font-weight: normal;
}

.km6-editions-table__subheader {
  background-color: #e1e1e1;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.km6-editions-table__cta {
  color: #333e48;
  cursor: pointer;
  text-align: center;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  width: 90%;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .km6-editions-table__cta {
    white-space: nowrap;
  }
}

.km6-editions-table__cta:hover, .km6-editions-table__cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

.km6-editions-table-feature__name a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: underline;
}

.km6-editions-table-feature__name a:hover {
  color: #c75300;
}

.km6-editions-table-feature__name p {
  font-weight: normal;
}

.km6-editions-table-feature__value {
  text-align: center;
}

.km6-editions-table-feature__value > .fa-minus {
  color: #c2c7c9;
}

.support {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
  font-size: 1.1rem;
}

@media (width <= 640px) {
  .support {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.support a {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.support a:hover {
  color: #c75300;
}

.support-header {
  margin-top: 2rem;
}

.support-header h1 {
  margin-bottom: .8rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .support-header h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }

  .support-intro {
    margin-bottom: 0;
  }
}

.support .support-uvision, .support .support-ksc, .support .support-ks-vs-code, .support .support-cmsis-toolbox, .support .support-mdk {
  padding: 1rem 0;
}

@media (width >= 640px) {
  .support .support-uvision, .support .support-ksc, .support .support-ks-vs-code, .support .support-cmsis-toolbox, .support .support-mdk {
    padding: 2rem 0 3rem;
  }
}

.support .support-uvision__title, .support .support-ksc__title, .support .support-ks-vs-code__title, .support .support-cmsis-toolbox__title, .support .support-mdk__title {
  margin-bottom: 1.5rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.support .support-uvision__content, .support .support-ksc__content, .support .support-ks-vs-code__content, .support .support-cmsis-toolbox__content, .support .support-mdk__content {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

@media (width <= 640px) {
  .support .support-uvision__content, .support .support-ksc__content, .support .support-ks-vs-code__content, .support .support-cmsis-toolbox__content, .support .support-mdk__content {
    flex-direction: column;
  }
}

.support .support-uvision__image, .support .support-uvision__resources, .support .support-uvision__issues, .support .support-uvision__videos, .support .support-ksc__image, .support .support-ksc__resources, .support .support-ksc__issues, .support .support-ksc__videos, .support .support-ks-vs-code__image, .support .support-ks-vs-code__resources, .support .support-ks-vs-code__issues, .support .support-ks-vs-code__videos, .support .support-cmsis-toolbox__image, .support .support-cmsis-toolbox__resources, .support .support-cmsis-toolbox__issues, .support .support-cmsis-toolbox__videos, .support .support-mdk__image, .support .support-mdk__resources, .support .support-mdk__issues, .support .support-mdk__videos {
  flex: 1;
}

@media (width <= 640px) {
  .support .support-uvision__image, .support .support-uvision__resources, .support .support-uvision__issues, .support .support-uvision__videos, .support .support-ksc__image, .support .support-ksc__resources, .support .support-ksc__issues, .support .support-ksc__videos, .support .support-ks-vs-code__image, .support .support-ks-vs-code__resources, .support .support-ks-vs-code__issues, .support .support-ks-vs-code__videos, .support .support-cmsis-toolbox__image, .support .support-cmsis-toolbox__resources, .support .support-cmsis-toolbox__issues, .support .support-cmsis-toolbox__videos, .support .support-mdk__image, .support .support-mdk__resources, .support .support-mdk__issues, .support .support-mdk__videos {
    margin-bottom: 1.5rem;
  }
}

.support .support-uvision__image img, .support .support-ksc__image img, .support .support-ks-vs-code__image img, .support .support-cmsis-toolbox__image img, .support .support-mdk__image img {
  border-radius: 5px;
  box-shadow: 0 5px 15px #00000059;
}

.support .support-uvision__resources ul, .support .support-uvision__issues ul, .support .support-uvision__videos ul, .support .support-ksc__resources ul, .support .support-ksc__issues ul, .support .support-ksc__videos ul, .support .support-ks-vs-code__resources ul, .support .support-ks-vs-code__issues ul, .support .support-ks-vs-code__videos ul, .support .support-cmsis-toolbox__resources ul, .support .support-cmsis-toolbox__issues ul, .support .support-cmsis-toolbox__videos ul, .support .support-mdk__resources ul, .support .support-mdk__issues ul, .support .support-mdk__videos ul {
  margin: 0;
  padding-left: 22px;
  font-size: 1rem;
}

.support .support-uvision__resources ul li, .support .support-uvision__issues ul li, .support .support-uvision__videos ul li, .support .support-ksc__resources ul li, .support .support-ksc__issues ul li, .support .support-ksc__videos ul li, .support .support-ks-vs-code__resources ul li, .support .support-ks-vs-code__issues ul li, .support .support-ks-vs-code__videos ul li, .support .support-cmsis-toolbox__resources ul li, .support .support-cmsis-toolbox__issues ul li, .support .support-cmsis-toolbox__videos ul li, .support .support-mdk__resources ul li, .support .support-mdk__issues ul li, .support .support-mdk__videos ul li {
  margin: 8px 0;
}

.support-ks-vs-code-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 1rem 0 3rem;
  font-size: 16px;
}

.support-ks-vs-code-table thead {
  text-align: left;
}

.support-ks-vs-code-table thead th:first-child {
  border-top-left-radius: 5px;
}

.support-ks-vs-code-table thead th:last-child {
  border-top-right-radius: 5px;
}

.support-ks-vs-code-table td, .support-ks-vs-code-table th {
  border: 1px solid #c2c7c9;
  border-left: none;
}

.support-ks-vs-code-table td:first-child, .support-ks-vs-code-table th:first-child {
  border-left: 1px solid #c2c7c9;
}

.support-ks-vs-code-table td {
  border-top: none;
  padding: 1rem;
}

.support-ks-vs-code-table th {
  padding: 1rem;
}

.support-ks-vs-code-table thead th {
  background-color: #f7f7f7;
  font-weight: 700;
}

.support-ks-vs-code-table tr:hover {
  background-color: #f7f7f7;
}

.support-ks-vs-code-table a.support-ks-vs-code-table__report {
  font-weight: normal;
}

.support-ks-vs-code-table a.support-ks-vs-code-table__report i {
  margin-right: .3rem;
}

.board-page, .board-listing, .device-listing, .vendor-page, .device-page, .pack-listing, .family-page, .device-group-page, .package-listing {
  max-width: 80rem;
  min-height: calc(100vh - 64px);
  margin: 2rem 3rem;
}

@media (width >= 1280px) {
  .board-page, .board-listing, .device-listing, .vendor-page, .device-page, .pack-listing, .family-page, .device-group-page, .package-listing {
    width: 100%;
    margin: 2rem auto;
  }
}

@media (width <= 768px) {
  .board-page, .board-listing, .device-listing, .vendor-page, .device-page, .pack-listing, .family-page, .device-group-page, .package-listing {
    margin: 2rem 1rem;
  }
}

.board-listing, .device-listing, .vendor-board-listing, .device-board-listing {
  margin-top: 0;
}

.board-listing-header, .device-listing-header, .vendor-board-listing-header, .device-board-listing-header {
  background: #fff;
  padding: 1rem 0;
}

.board-listing__title, .device-listing__title, .vendor-board-listing__title, .device-board-listing__title {
  margin: 1rem 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .board-listing__title, .device-listing__title, .vendor-board-listing__title, .device-board-listing__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

@media (width <= 640px) {
  .board-listing__about-cmsis, .device-listing__about-cmsis, .vendor-board-listing__about-cmsis, .device-board-listing__about-cmsis {
    display: none;
  }
}

.board-listing__results, .device-listing__results, .vendor-board-listing__results, .device-board-listing__results {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.5rem 2rem;
  padding: 0;
  list-style: none;
  display: grid;
}

.board-listing__results-header, .device-listing__results-header, .vendor-board-listing__results-header, .device-board-listing__results-header {
  display: flex;
}

.board-listing__results-header h2, .device-listing__results-header h2, .vendor-board-listing__results-header h2, .device-board-listing__results-header h2 {
  white-space: nowrap;
  flex: 1;
  margin: 0;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.board-listing__results-header__count, .device-listing__results-header__count, .vendor-board-listing__results-header__count, .device-board-listing__results-header__count {
  margin-left: .1rem;
  font-size: 16px;
  font-weight: normal;
}

@media (width <= 60rem) {
  .board-listing__results, .device-listing__results, .vendor-board-listing__results, .device-board-listing__results {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width <= 640px) {
  .board-listing__results, .device-listing__results, .vendor-board-listing__results, .device-board-listing__results {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.board-listing__results--empty, .device-listing__results--empty, .vendor-board-listing__results--empty, .device-board-listing__results--empty {
  color: #a3a6ac;
  text-align: center;
  margin: 2rem 0 .8rem;
  font-size: 27px;
  font-weight: lighter;
  line-height: 32px;
}

.board-card {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  min-height: 150px;
  padding: 1rem;
  text-decoration: none;
  display: flex;
}

.board-card:hover {
  box-shadow: 0 2px 4px #333e481a;
}

.board-card__details {
  flex: 1;
  height: 100%;
  padding-right: 1rem;
}

.board-card__vendor, .board-card__revision {
  color: #6f777c;
  text-align: justify;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
}

.board-card__name {
  color: #0091bd;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: normal;
}

.board-card__name em {
  font-style: normal;
  font-weight: 900;
}

.board-card__projects-count {
  color: #6f777c;
  text-align: justify;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}

.board-card__image {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  margin: auto;
  display: flex;
  overflow: hidden;
}

.board-card__image img {
  max-width: 100%;
  max-height: 100%;
}

.pack-search {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.pack-search:focus-within .pack-search__suggestions {
  display: block;
}

.pack-search:focus-within .pack-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pack-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.pack-search__input::placeholder {
  color: #bbb;
}

.pack-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.pack-search__input:focus + .pack-search__suggestions {
  display: block;
}

.pack-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pack-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.pack-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.pack-search__suggestions .pack-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.pack-search__suggestions .pack-search__suggestion a, .pack-search__suggestions .pack-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.pack-search__suggestions .pack-search__suggestion a i, .pack-search__suggestions .pack-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.pack-search__suggestions .pack-search__suggestion a i:hover, .pack-search__suggestions .pack-search__suggestion div i:hover {
  color: #fff;
}

.pack-search__suggestions .pack-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.pack-search__suggestions .pack-search__suggestion--focused, .pack-search__suggestions .pack-search__suggestion:active {
  background-color: #e5eceb;
}

.pack-search__suggestions .pack-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.pack-search__suggestions .pack-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.pack-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.board-search {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.board-search:focus-within .board-search__suggestions {
  display: block;
}

.board-search:focus-within .board-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.board-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.board-search__input::placeholder {
  color: #bbb;
}

.board-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.board-search__input:focus + .board-search__suggestions {
  display: block;
}

.board-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.board-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.board-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.board-search__suggestions .board-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.board-search__suggestions .board-search__suggestion a, .board-search__suggestions .board-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.board-search__suggestions .board-search__suggestion a i, .board-search__suggestions .board-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.board-search__suggestions .board-search__suggestion a i:hover, .board-search__suggestions .board-search__suggestion div i:hover {
  color: #fff;
}

.board-search__suggestions .board-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.board-search__suggestions .board-search__suggestion--focused, .board-search__suggestions .board-search__suggestion:active {
  background-color: #e5eceb;
}

.board-search__suggestions .board-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.board-search__suggestions .board-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.board-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.pack-board-search {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.pack-board-search:focus-within .pack-board-search__suggestions {
  display: block;
}

.pack-board-search:focus-within .pack-board-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pack-board-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.pack-board-search__input::placeholder {
  color: #bbb;
}

.pack-board-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.pack-board-search__input:focus + .pack-board-search__suggestions {
  display: block;
}

.pack-board-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pack-board-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.pack-board-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.pack-board-search__suggestions .pack-board-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.pack-board-search__suggestions .pack-board-search__suggestion a, .pack-board-search__suggestions .pack-board-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.pack-board-search__suggestions .pack-board-search__suggestion a i, .pack-board-search__suggestions .pack-board-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.pack-board-search__suggestions .pack-board-search__suggestion a i:hover, .pack-board-search__suggestions .pack-board-search__suggestion div i:hover {
  color: #fff;
}

.pack-board-search__suggestions .pack-board-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.pack-board-search__suggestions .pack-board-search__suggestion--focused, .pack-board-search__suggestions .pack-board-search__suggestion:active {
  background-color: #e5eceb;
}

.pack-board-search__suggestions .pack-board-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.pack-board-search__suggestions .pack-board-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.pack-board-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.device-vendor-search {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.device-vendor-search:focus-within .device-vendor-search__suggestions {
  display: block;
}

.device-vendor-search:focus-within .device-vendor-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.device-vendor-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.device-vendor-search__input::placeholder {
  color: #bbb;
}

.device-vendor-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.device-vendor-search__input:focus + .device-vendor-search__suggestions {
  display: block;
}

.device-vendor-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.device-vendor-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.device-vendor-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion a, .device-vendor-search__suggestions .device-vendor-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion a i, .device-vendor-search__suggestions .device-vendor-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion a i:hover, .device-vendor-search__suggestions .device-vendor-search__suggestion div i:hover {
  color: #fff;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion--focused, .device-vendor-search__suggestions .device-vendor-search__suggestion:active {
  background-color: #e5eceb;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.device-vendor-search__suggestions .device-vendor-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.device-vendor-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.pack-filters, .board-filters {
  margin-bottom: 2em;
}

.device-filter__search-bar, .pack-filter__search-bar, .board-filter__search-bar {
  margin: 0 .5rem .2rem;
  display: block;
}

.device-filter__search-bar i, .pack-filter__search-bar i, .board-filter__search-bar i {
  margin-top: .1em;
  margin-left: 0;
}

.device-filter__search-bar input, .pack-filter__search-bar input, .board-filter__search-bar input {
  padding: .3em .3em .3em 2.5em;
}

.device-filters__search-icon, .pack-filters__search-icon, .board-filters__search-icon {
  margin-top: .1em;
  margin-left: .3em;
}

.pack-filter__dropdown-button, .pack-filter__dropdown-content {
  width: 210px;
}

.board-filters, .pack-filters, .pack-listing__results-header, .board-listing__results-header {
  align-items: center;
  display: flex;
}

.board-filters .board-filter__dropdown-button, .board-filters .board-filter__dropdown-content, .pack-filters .board-filter__dropdown-button, .pack-filters .board-filter__dropdown-content, .pack-listing__results-header .board-filter__dropdown-button, .pack-listing__results-header .board-filter__dropdown-content, .board-listing__results-header .board-filter__dropdown-button, .board-listing__results-header .board-filter__dropdown-content {
  width: 210px;
}

.board-filters .pack-sort__dropdown-content, .board-filters .pack-sort__dropdown-button, .board-filters .board-sort__dropdown-button, .board-filters .board-sort__dropdown-content, .pack-filters .pack-sort__dropdown-content, .pack-filters .pack-sort__dropdown-button, .pack-filters .board-sort__dropdown-button, .pack-filters .board-sort__dropdown-content, .pack-listing__results-header .pack-sort__dropdown-content, .pack-listing__results-header .pack-sort__dropdown-button, .pack-listing__results-header .board-sort__dropdown-button, .pack-listing__results-header .board-sort__dropdown-content, .board-listing__results-header .pack-sort__dropdown-content, .board-listing__results-header .pack-sort__dropdown-button, .board-listing__results-header .board-sort__dropdown-button, .board-listing__results-header .board-sort__dropdown-content {
  width: 250px;
}

.board-filters .pack-filter:not(:first-child), .board-filters .pack-sort:not(:first-child), .board-filters .board-filter:not(:first-child), .board-filters .board-sort:not(:first-child), .pack-filters .pack-filter:not(:first-child), .pack-filters .pack-sort:not(:first-child), .pack-filters .board-filter:not(:first-child), .pack-filters .board-sort:not(:first-child), .pack-listing__results-header .pack-filter:not(:first-child), .pack-listing__results-header .pack-sort:not(:first-child), .pack-listing__results-header .board-filter:not(:first-child), .pack-listing__results-header .board-sort:not(:first-child), .board-listing__results-header .pack-filter:not(:first-child), .board-listing__results-header .pack-sort:not(:first-child), .board-listing__results-header .board-filter:not(:first-child), .board-listing__results-header .board-sort:not(:first-child) {
  margin-left: 2rem;
}

.board-filters .pack-filter input:hover, .board-filters .pack-filter label:hover, .board-filters .pack-sort input:hover, .board-filters .pack-sort label:hover, .board-filters .board-filter input:hover, .board-filters .board-filter label:hover, .board-filters .board-sort input:hover, .board-filters .board-sort label:hover, .pack-filters .pack-filter input:hover, .pack-filters .pack-filter label:hover, .pack-filters .pack-sort input:hover, .pack-filters .pack-sort label:hover, .pack-filters .board-filter input:hover, .pack-filters .board-filter label:hover, .pack-filters .board-sort input:hover, .pack-filters .board-sort label:hover, .pack-listing__results-header .pack-filter input:hover, .pack-listing__results-header .pack-filter label:hover, .pack-listing__results-header .pack-sort input:hover, .pack-listing__results-header .pack-sort label:hover, .pack-listing__results-header .board-filter input:hover, .pack-listing__results-header .board-filter label:hover, .pack-listing__results-header .board-sort input:hover, .pack-listing__results-header .board-sort label:hover, .board-listing__results-header .pack-filter input:hover, .board-listing__results-header .pack-filter label:hover, .board-listing__results-header .pack-sort input:hover, .board-listing__results-header .pack-sort label:hover, .board-listing__results-header .board-filter input:hover, .board-listing__results-header .board-filter label:hover, .board-listing__results-header .board-sort input:hover, .board-listing__results-header .board-sort label:hover {
  cursor: pointer;
}

.board-filters .pack-filter label, .board-filters .pack-sort label, .board-filters .board-filter label, .board-filters .board-sort label, .pack-filters .pack-filter label, .pack-filters .pack-sort label, .pack-filters .board-filter label, .pack-filters .board-sort label, .pack-listing__results-header .pack-filter label, .pack-listing__results-header .pack-sort label, .pack-listing__results-header .board-filter label, .pack-listing__results-header .board-sort label, .board-listing__results-header .pack-filter label, .board-listing__results-header .pack-sort label, .board-listing__results-header .board-filter label, .board-listing__results-header .board-sort label {
  align-items: center;
  display: flex;
}

.board-filters .pack-filter label input[type="checkbox"], .board-filters .pack-sort label input[type="checkbox"], .board-filters .board-filter label input[type="checkbox"], .board-filters .board-sort label input[type="checkbox"], .pack-filters .pack-filter label input[type="checkbox"], .pack-filters .pack-sort label input[type="checkbox"], .pack-filters .board-filter label input[type="checkbox"], .pack-filters .board-sort label input[type="checkbox"], .pack-listing__results-header .pack-filter label input[type="checkbox"], .pack-listing__results-header .pack-sort label input[type="checkbox"], .pack-listing__results-header .board-filter label input[type="checkbox"], .pack-listing__results-header .board-sort label input[type="checkbox"], .board-listing__results-header .pack-filter label input[type="checkbox"], .board-listing__results-header .pack-sort label input[type="checkbox"], .board-listing__results-header .board-filter label input[type="checkbox"], .board-listing__results-header .board-sort label input[type="checkbox"] {
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  place-content: center;
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem 0 0;
  display: grid;
}

.board-filters .pack-filter label input[type="checkbox"]:checked, .board-filters .pack-sort label input[type="checkbox"]:checked, .board-filters .board-filter label input[type="checkbox"]:checked, .board-filters .board-sort label input[type="checkbox"]:checked, .pack-filters .pack-filter label input[type="checkbox"]:checked, .pack-filters .pack-sort label input[type="checkbox"]:checked, .pack-filters .board-filter label input[type="checkbox"]:checked, .pack-filters .board-sort label input[type="checkbox"]:checked, .pack-listing__results-header .pack-filter label input[type="checkbox"]:checked, .pack-listing__results-header .pack-sort label input[type="checkbox"]:checked, .pack-listing__results-header .board-filter label input[type="checkbox"]:checked, .pack-listing__results-header .board-sort label input[type="checkbox"]:checked, .board-listing__results-header .pack-filter label input[type="checkbox"]:checked, .board-listing__results-header .pack-sort label input[type="checkbox"]:checked, .board-listing__results-header .board-filter label input[type="checkbox"]:checked, .board-listing__results-header .board-sort label input[type="checkbox"]:checked {
  background: #0091bd;
  border: none;
}

.board-filters .pack-filter label input[type="checkbox"]:before, .board-filters .pack-sort label input[type="checkbox"]:before, .board-filters .board-filter label input[type="checkbox"]:before, .board-filters .board-sort label input[type="checkbox"]:before, .pack-filters .pack-filter label input[type="checkbox"]:before, .pack-filters .pack-sort label input[type="checkbox"]:before, .pack-filters .board-filter label input[type="checkbox"]:before, .pack-filters .board-sort label input[type="checkbox"]:before, .pack-listing__results-header .pack-filter label input[type="checkbox"]:before, .pack-listing__results-header .pack-sort label input[type="checkbox"]:before, .pack-listing__results-header .board-filter label input[type="checkbox"]:before, .pack-listing__results-header .board-sort label input[type="checkbox"]:before, .board-listing__results-header .pack-filter label input[type="checkbox"]:before, .board-listing__results-header .pack-sort label input[type="checkbox"]:before, .board-listing__results-header .board-filter label input[type="checkbox"]:before, .board-listing__results-header .board-sort label input[type="checkbox"]:before {
  content: "";
  transform-origin: 0 100%;
  font: var(--fa-font-regular);
  content: "";
  color: #fff;
  font-size: 12px;
  transition: transform .12s ease-in-out;
  transform: scale(0);
}

.board-filters .pack-filter label input[type="checkbox"]:checked:before, .board-filters .pack-sort label input[type="checkbox"]:checked:before, .board-filters .board-filter label input[type="checkbox"]:checked:before, .board-filters .board-sort label input[type="checkbox"]:checked:before, .pack-filters .pack-filter label input[type="checkbox"]:checked:before, .pack-filters .pack-sort label input[type="checkbox"]:checked:before, .pack-filters .board-filter label input[type="checkbox"]:checked:before, .pack-filters .board-sort label input[type="checkbox"]:checked:before, .pack-listing__results-header .pack-filter label input[type="checkbox"]:checked:before, .pack-listing__results-header .pack-sort label input[type="checkbox"]:checked:before, .pack-listing__results-header .board-filter label input[type="checkbox"]:checked:before, .pack-listing__results-header .board-sort label input[type="checkbox"]:checked:before, .board-listing__results-header .pack-filter label input[type="checkbox"]:checked:before, .board-listing__results-header .pack-sort label input[type="checkbox"]:checked:before, .board-listing__results-header .board-filter label input[type="checkbox"]:checked:before, .board-listing__results-header .board-sort label input[type="checkbox"]:checked:before {
  transform: scale(1);
}

.board-filters .pack-filter label input[type="checkbox"]:focus, .board-filters .pack-sort label input[type="checkbox"]:focus, .board-filters .board-filter label input[type="checkbox"]:focus, .board-filters .board-sort label input[type="checkbox"]:focus, .pack-filters .pack-filter label input[type="checkbox"]:focus, .pack-filters .pack-sort label input[type="checkbox"]:focus, .pack-filters .board-filter label input[type="checkbox"]:focus, .pack-filters .board-sort label input[type="checkbox"]:focus, .pack-listing__results-header .pack-filter label input[type="checkbox"]:focus, .pack-listing__results-header .pack-sort label input[type="checkbox"]:focus, .pack-listing__results-header .board-filter label input[type="checkbox"]:focus, .pack-listing__results-header .board-sort label input[type="checkbox"]:focus, .board-listing__results-header .pack-filter label input[type="checkbox"]:focus, .board-listing__results-header .pack-sort label input[type="checkbox"]:focus, .board-listing__results-header .board-filter label input[type="checkbox"]:focus, .board-listing__results-header .board-sort label input[type="checkbox"]:focus {
  border-color: #0091bd;
}

.board-filters .pack-filter label input[type="radio"], .board-filters .pack-sort label input[type="radio"], .board-filters .board-filter label input[type="radio"], .board-filters .board-sort label input[type="radio"], .pack-filters .pack-filter label input[type="radio"], .pack-filters .pack-sort label input[type="radio"], .pack-filters .board-filter label input[type="radio"], .pack-filters .board-sort label input[type="radio"], .pack-listing__results-header .pack-filter label input[type="radio"], .pack-listing__results-header .pack-sort label input[type="radio"], .pack-listing__results-header .board-filter label input[type="radio"], .pack-listing__results-header .board-sort label input[type="radio"], .board-listing__results-header .pack-filter label input[type="radio"], .board-listing__results-header .pack-sort label input[type="radio"], .board-listing__results-header .board-filter label input[type="radio"], .board-listing__results-header .board-sort label input[type="radio"] {
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  place-content: center;
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem 0 0;
  display: grid;
}

.board-filters .pack-filter label input[type="radio"]:checked, .board-filters .pack-sort label input[type="radio"]:checked, .board-filters .board-filter label input[type="radio"]:checked, .board-filters .board-sort label input[type="radio"]:checked, .pack-filters .pack-filter label input[type="radio"]:checked, .pack-filters .pack-sort label input[type="radio"]:checked, .pack-filters .board-filter label input[type="radio"]:checked, .pack-filters .board-sort label input[type="radio"]:checked, .pack-listing__results-header .pack-filter label input[type="radio"]:checked, .pack-listing__results-header .pack-sort label input[type="radio"]:checked, .pack-listing__results-header .board-filter label input[type="radio"]:checked, .pack-listing__results-header .board-sort label input[type="radio"]:checked, .board-listing__results-header .pack-filter label input[type="radio"]:checked, .board-listing__results-header .pack-sort label input[type="radio"]:checked, .board-listing__results-header .board-filter label input[type="radio"]:checked, .board-listing__results-header .board-sort label input[type="radio"]:checked {
  background: #0091bd;
  border: none;
}

.board-filters .pack-filter label input[type="radio"]:before, .board-filters .pack-sort label input[type="radio"]:before, .board-filters .board-filter label input[type="radio"]:before, .board-filters .board-sort label input[type="radio"]:before, .pack-filters .pack-filter label input[type="radio"]:before, .pack-filters .pack-sort label input[type="radio"]:before, .pack-filters .board-filter label input[type="radio"]:before, .pack-filters .board-sort label input[type="radio"]:before, .pack-listing__results-header .pack-filter label input[type="radio"]:before, .pack-listing__results-header .pack-sort label input[type="radio"]:before, .pack-listing__results-header .board-filter label input[type="radio"]:before, .pack-listing__results-header .board-sort label input[type="radio"]:before, .board-listing__results-header .pack-filter label input[type="radio"]:before, .board-listing__results-header .pack-sort label input[type="radio"]:before, .board-listing__results-header .board-filter label input[type="radio"]:before, .board-listing__results-header .board-sort label input[type="radio"]:before {
  content: "";
  font: var(--fa-font-solid);
  content: "";
  color: #fff;
  font-size: 8px;
  transform: scale(0);
}

.board-filters .pack-filter label input[type="radio"]:checked:before, .board-filters .pack-sort label input[type="radio"]:checked:before, .board-filters .board-filter label input[type="radio"]:checked:before, .board-filters .board-sort label input[type="radio"]:checked:before, .pack-filters .pack-filter label input[type="radio"]:checked:before, .pack-filters .pack-sort label input[type="radio"]:checked:before, .pack-filters .board-filter label input[type="radio"]:checked:before, .pack-filters .board-sort label input[type="radio"]:checked:before, .pack-listing__results-header .pack-filter label input[type="radio"]:checked:before, .pack-listing__results-header .pack-sort label input[type="radio"]:checked:before, .pack-listing__results-header .board-filter label input[type="radio"]:checked:before, .pack-listing__results-header .board-sort label input[type="radio"]:checked:before, .board-listing__results-header .pack-filter label input[type="radio"]:checked:before, .board-listing__results-header .pack-sort label input[type="radio"]:checked:before, .board-listing__results-header .board-filter label input[type="radio"]:checked:before, .board-listing__results-header .board-sort label input[type="radio"]:checked:before {
  transform: scale(1);
}

.board-filters .pack-filter label input[type="radio"]:focus, .board-filters .pack-sort label input[type="radio"]:focus, .board-filters .board-filter label input[type="radio"]:focus, .board-filters .board-sort label input[type="radio"]:focus, .pack-filters .pack-filter label input[type="radio"]:focus, .pack-filters .pack-sort label input[type="radio"]:focus, .pack-filters .board-filter label input[type="radio"]:focus, .pack-filters .board-sort label input[type="radio"]:focus, .pack-listing__results-header .pack-filter label input[type="radio"]:focus, .pack-listing__results-header .pack-sort label input[type="radio"]:focus, .pack-listing__results-header .board-filter label input[type="radio"]:focus, .pack-listing__results-header .board-sort label input[type="radio"]:focus, .board-listing__results-header .pack-filter label input[type="radio"]:focus, .board-listing__results-header .pack-sort label input[type="radio"]:focus, .board-listing__results-header .board-filter label input[type="radio"]:focus, .board-listing__results-header .board-sort label input[type="radio"]:focus {
  border-color: #0091bd;
}

.board-filters .pack-filter__dropdown-button, .board-filters .pack-sort__dropdown-button, .board-filters .board-filter__dropdown-button, .board-filters .board-sort__dropdown-button, .pack-filters .pack-filter__dropdown-button, .pack-filters .pack-sort__dropdown-button, .pack-filters .board-filter__dropdown-button, .pack-filters .board-sort__dropdown-button, .pack-listing__results-header .pack-filter__dropdown-button, .pack-listing__results-header .pack-sort__dropdown-button, .pack-listing__results-header .board-filter__dropdown-button, .pack-listing__results-header .board-sort__dropdown-button, .board-listing__results-header .pack-filter__dropdown-button, .board-listing__results-header .pack-sort__dropdown-button, .board-listing__results-header .board-filter__dropdown-button, .board-listing__results-header .board-sort__dropdown-button {
  color: initial;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

@media (width >= 768px) {
  .board-filters .pack-filter__dropdown-button, .board-filters .pack-sort__dropdown-button, .board-filters .board-filter__dropdown-button, .board-filters .board-sort__dropdown-button, .pack-filters .pack-filter__dropdown-button, .pack-filters .pack-sort__dropdown-button, .pack-filters .board-filter__dropdown-button, .pack-filters .board-sort__dropdown-button, .pack-listing__results-header .pack-filter__dropdown-button, .pack-listing__results-header .pack-sort__dropdown-button, .pack-listing__results-header .board-filter__dropdown-button, .pack-listing__results-header .board-sort__dropdown-button, .board-listing__results-header .pack-filter__dropdown-button, .board-listing__results-header .pack-sort__dropdown-button, .board-listing__results-header .board-filter__dropdown-button, .board-listing__results-header .board-sort__dropdown-button {
    white-space: nowrap;
  }
}

.board-filters .pack-filter__dropdown-button:hover, .board-filters .pack-filter__dropdown-button:focus, .board-filters .pack-sort__dropdown-button:hover, .board-filters .pack-sort__dropdown-button:focus, .board-filters .board-filter__dropdown-button:hover, .board-filters .board-filter__dropdown-button:focus, .board-filters .board-sort__dropdown-button:hover, .board-filters .board-sort__dropdown-button:focus, .pack-filters .pack-filter__dropdown-button:hover, .pack-filters .pack-filter__dropdown-button:focus, .pack-filters .pack-sort__dropdown-button:hover, .pack-filters .pack-sort__dropdown-button:focus, .pack-filters .board-filter__dropdown-button:hover, .pack-filters .board-filter__dropdown-button:focus, .pack-filters .board-sort__dropdown-button:hover, .pack-filters .board-sort__dropdown-button:focus, .pack-listing__results-header .pack-filter__dropdown-button:hover, .pack-listing__results-header .pack-filter__dropdown-button:focus, .pack-listing__results-header .pack-sort__dropdown-button:hover, .pack-listing__results-header .pack-sort__dropdown-button:focus, .pack-listing__results-header .board-filter__dropdown-button:hover, .pack-listing__results-header .board-filter__dropdown-button:focus, .pack-listing__results-header .board-sort__dropdown-button:hover, .pack-listing__results-header .board-sort__dropdown-button:focus, .board-listing__results-header .pack-filter__dropdown-button:hover, .board-listing__results-header .pack-filter__dropdown-button:focus, .board-listing__results-header .pack-sort__dropdown-button:hover, .board-listing__results-header .pack-sort__dropdown-button:focus, .board-listing__results-header .board-filter__dropdown-button:hover, .board-listing__results-header .board-filter__dropdown-button:focus, .board-listing__results-header .board-sort__dropdown-button:hover, .board-listing__results-header .board-sort__dropdown-button:focus {
  color: #fff;
  background-color: #0091bd;
}

.board-filters .pack-filter__dropdown-button:disabled, .board-filters .pack-sort__dropdown-button:disabled, .board-filters .board-filter__dropdown-button:disabled, .board-filters .board-sort__dropdown-button:disabled, .pack-filters .pack-filter__dropdown-button:disabled, .pack-filters .pack-sort__dropdown-button:disabled, .pack-filters .board-filter__dropdown-button:disabled, .pack-filters .board-sort__dropdown-button:disabled, .pack-listing__results-header .pack-filter__dropdown-button:disabled, .pack-listing__results-header .pack-sort__dropdown-button:disabled, .pack-listing__results-header .board-filter__dropdown-button:disabled, .pack-listing__results-header .board-sort__dropdown-button:disabled, .board-listing__results-header .pack-filter__dropdown-button:disabled, .board-listing__results-header .pack-sort__dropdown-button:disabled, .board-listing__results-header .board-filter__dropdown-button:disabled, .board-listing__results-header .board-sort__dropdown-button:disabled {
  cursor: default;
  opacity: .5;
}

.board-filters .pack-filter__dropdown-button:disabled:hover, .board-filters .pack-sort__dropdown-button:disabled:hover, .board-filters .board-filter__dropdown-button:disabled:hover, .board-filters .board-sort__dropdown-button:disabled:hover, .pack-filters .pack-filter__dropdown-button:disabled:hover, .pack-filters .pack-sort__dropdown-button:disabled:hover, .pack-filters .board-filter__dropdown-button:disabled:hover, .pack-filters .board-sort__dropdown-button:disabled:hover, .pack-listing__results-header .pack-filter__dropdown-button:disabled:hover, .pack-listing__results-header .pack-sort__dropdown-button:disabled:hover, .pack-listing__results-header .board-filter__dropdown-button:disabled:hover, .pack-listing__results-header .board-sort__dropdown-button:disabled:hover, .board-listing__results-header .pack-filter__dropdown-button:disabled:hover, .board-listing__results-header .pack-sort__dropdown-button:disabled:hover, .board-listing__results-header .board-filter__dropdown-button:disabled:hover, .board-listing__results-header .board-sort__dropdown-button:disabled:hover {
  background: #fff;
}

.board-filters .pack-filter__dropdown-button-text, .board-filters .pack-sort__dropdown-button-text, .board-filters .board-filter__dropdown-button-text, .board-filters .board-sort__dropdown-button-text, .pack-filters .pack-filter__dropdown-button-text, .pack-filters .pack-sort__dropdown-button-text, .pack-filters .board-filter__dropdown-button-text, .pack-filters .board-sort__dropdown-button-text, .pack-listing__results-header .pack-filter__dropdown-button-text, .pack-listing__results-header .pack-sort__dropdown-button-text, .pack-listing__results-header .board-filter__dropdown-button-text, .pack-listing__results-header .board-sort__dropdown-button-text, .board-listing__results-header .pack-filter__dropdown-button-text, .board-listing__results-header .pack-sort__dropdown-button-text, .board-listing__results-header .board-filter__dropdown-button-text, .board-listing__results-header .board-sort__dropdown-button-text {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.board-filters .pack-filter__dropdown-button:focus, .board-filters .pack-sort__dropdown-button:focus, .board-filters .board-filter__dropdown-button:focus, .board-filters .board-sort__dropdown-button:focus, .pack-filters .pack-filter__dropdown-button:focus, .pack-filters .pack-sort__dropdown-button:focus, .pack-filters .board-filter__dropdown-button:focus, .pack-filters .board-sort__dropdown-button:focus, .pack-listing__results-header .pack-filter__dropdown-button:focus, .pack-listing__results-header .pack-sort__dropdown-button:focus, .pack-listing__results-header .board-filter__dropdown-button:focus, .pack-listing__results-header .board-sort__dropdown-button:focus, .board-listing__results-header .pack-filter__dropdown-button:focus, .board-listing__results-header .pack-sort__dropdown-button:focus, .board-listing__results-header .board-filter__dropdown-button:focus, .board-listing__results-header .board-sort__dropdown-button:focus {
  background: initial;
  color: initial;
}

.board-filters .pack-filter__dropdown-button:hover, .board-filters .pack-sort__dropdown-button:hover, .board-filters .board-filter__dropdown-button:hover, .board-filters .board-sort__dropdown-button:hover, .pack-filters .pack-filter__dropdown-button:hover, .pack-filters .pack-sort__dropdown-button:hover, .pack-filters .board-filter__dropdown-button:hover, .pack-filters .board-sort__dropdown-button:hover, .pack-listing__results-header .pack-filter__dropdown-button:hover, .pack-listing__results-header .pack-sort__dropdown-button:hover, .pack-listing__results-header .board-filter__dropdown-button:hover, .pack-listing__results-header .board-sort__dropdown-button:hover, .board-listing__results-header .pack-filter__dropdown-button:hover, .board-listing__results-header .pack-sort__dropdown-button:hover, .board-listing__results-header .board-filter__dropdown-button:hover, .board-listing__results-header .board-sort__dropdown-button:hover {
  color: initial;
  background-color: #e5eceb66;
}

.board-filters .pack-filter__dropdown-content, .board-filters .pack-sort__dropdown-content, .board-filters .board-filter__dropdown-content, .board-filters .board-sort__dropdown-content, .pack-filters .pack-filter__dropdown-content, .pack-filters .pack-sort__dropdown-content, .pack-filters .board-filter__dropdown-content, .pack-filters .board-sort__dropdown-content, .pack-listing__results-header .pack-filter__dropdown-content, .pack-listing__results-header .pack-sort__dropdown-content, .pack-listing__results-header .board-filter__dropdown-content, .pack-listing__results-header .board-sort__dropdown-content, .board-listing__results-header .pack-filter__dropdown-content, .board-listing__results-header .pack-sort__dropdown-content, .board-listing__results-header .board-filter__dropdown-content, .board-listing__results-header .board-sort__dropdown-content {
  visibility: hidden;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  max-height: 250px;
  margin-top: 2px;
  padding: .5rem 0;
  position: absolute;
  overflow-y: auto;
}

.board-filters .pack-filter__dropdown-content-results, .board-filters .pack-sort__dropdown-content-results, .board-filters .board-filter__dropdown-content-results, .board-filters .board-sort__dropdown-content-results, .pack-filters .pack-filter__dropdown-content-results, .pack-filters .pack-sort__dropdown-content-results, .pack-filters .board-filter__dropdown-content-results, .pack-filters .board-sort__dropdown-content-results, .pack-listing__results-header .pack-filter__dropdown-content-results, .pack-listing__results-header .pack-sort__dropdown-content-results, .pack-listing__results-header .board-filter__dropdown-content-results, .pack-listing__results-header .board-sort__dropdown-content-results, .board-listing__results-header .pack-filter__dropdown-content-results, .board-listing__results-header .pack-sort__dropdown-content-results, .board-listing__results-header .board-filter__dropdown-content-results, .board-listing__results-header .board-sort__dropdown-content-results {
  margin: 0;
  padding: 0;
  list-style: none;
}

.board-filters .pack-filter__dropdown-content-results li, .board-filters .pack-sort__dropdown-content-results li, .board-filters .board-filter__dropdown-content-results li, .board-filters .board-sort__dropdown-content-results li, .pack-filters .pack-filter__dropdown-content-results li, .pack-filters .pack-sort__dropdown-content-results li, .pack-filters .board-filter__dropdown-content-results li, .pack-filters .board-sort__dropdown-content-results li, .pack-listing__results-header .pack-filter__dropdown-content-results li, .pack-listing__results-header .pack-sort__dropdown-content-results li, .pack-listing__results-header .board-filter__dropdown-content-results li, .pack-listing__results-header .board-sort__dropdown-content-results li, .board-listing__results-header .pack-filter__dropdown-content-results li, .board-listing__results-header .pack-sort__dropdown-content-results li, .board-listing__results-header .board-filter__dropdown-content-results li, .board-listing__results-header .board-sort__dropdown-content-results li {
  border-radius: 4px;
}

.board-filters .pack-filter__dropdown-content-results li > label, .board-filters .pack-sort__dropdown-content-results li > label, .board-filters .board-filter__dropdown-content-results li > label, .board-filters .board-sort__dropdown-content-results li > label, .pack-filters .pack-filter__dropdown-content-results li > label, .pack-filters .pack-sort__dropdown-content-results li > label, .pack-filters .board-filter__dropdown-content-results li > label, .pack-filters .board-sort__dropdown-content-results li > label, .pack-listing__results-header .pack-filter__dropdown-content-results li > label, .pack-listing__results-header .pack-sort__dropdown-content-results li > label, .pack-listing__results-header .board-filter__dropdown-content-results li > label, .pack-listing__results-header .board-sort__dropdown-content-results li > label, .board-listing__results-header .pack-filter__dropdown-content-results li > label, .board-listing__results-header .pack-sort__dropdown-content-results li > label, .board-listing__results-header .board-filter__dropdown-content-results li > label, .board-listing__results-header .board-sort__dropdown-content-results li > label {
  padding: .25rem .5rem;
}

.board-filters .pack-filter__dropdown-content-results li > label:hover, .board-filters .pack-sort__dropdown-content-results li > label:hover, .board-filters .board-filter__dropdown-content-results li > label:hover, .board-filters .board-sort__dropdown-content-results li > label:hover, .pack-filters .pack-filter__dropdown-content-results li > label:hover, .pack-filters .pack-sort__dropdown-content-results li > label:hover, .pack-filters .board-filter__dropdown-content-results li > label:hover, .pack-filters .board-sort__dropdown-content-results li > label:hover, .pack-listing__results-header .pack-filter__dropdown-content-results li > label:hover, .pack-listing__results-header .pack-sort__dropdown-content-results li > label:hover, .pack-listing__results-header .board-filter__dropdown-content-results li > label:hover, .pack-listing__results-header .board-sort__dropdown-content-results li > label:hover, .board-listing__results-header .pack-filter__dropdown-content-results li > label:hover, .board-listing__results-header .pack-sort__dropdown-content-results li > label:hover, .board-listing__results-header .board-filter__dropdown-content-results li > label:hover, .board-listing__results-header .board-sort__dropdown-content-results li > label:hover {
  background-color: #e5eceb66;
}

@media (width <= 640px) {
  .board-filters, .pack-filters, .pack-listing__results-header, .board-listing__results-header {
    align-items: initial;
    flex-flow: column wrap;
  }

  .board-filters .board-filter:not(:first-child), .board-filters .pack-filter:not(:first-child), .board-filters .pack-sort:not(:first-child), .board-filters .board-sort:not(:first-child), .pack-filters .board-filter:not(:first-child), .pack-filters .pack-filter:not(:first-child), .pack-filters .pack-sort:not(:first-child), .pack-filters .board-sort:not(:first-child), .pack-listing__results-header .board-filter:not(:first-child), .pack-listing__results-header .pack-filter:not(:first-child), .pack-listing__results-header .pack-sort:not(:first-child), .pack-listing__results-header .board-sort:not(:first-child), .board-listing__results-header .board-filter:not(:first-child), .board-listing__results-header .pack-filter:not(:first-child), .board-listing__results-header .pack-sort:not(:first-child), .board-listing__results-header .board-sort:not(:first-child) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

.pack-card {
  color: #333e48;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  flex-direction: column;
  min-height: 54px;
  padding: 1rem 2rem;
  text-decoration: none;
  display: flex;
}

.pack-card:hover {
  box-shadow: 0 2px 4px #333e481a;
}

.pack-card__header {
  margin-bottom: 1rem;
  display: flex;
}

@media (width <= 640px) {
  .pack-card__header {
    flex-direction: column;
  }
}

.pack-card__title {
  flex: 1 0 auto;
}

.pack-card__name, .pack-card__vendor {
  margin-right: 1rem;
}

.pack-card__name {
  color: #0091bd;
}

.pack-card__name em {
  font-style: normal;
  font-weight: 900;
}

.pack-card__vendor {
  color: #5d6c7a;
}

.pack-card__download a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.pack-card__download a:hover {
  color: #c75300;
}

.pack-card__download a i {
  margin-right: .1rem;
}

.pack-card__description {
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: .75rem;
  overflow: hidden;
}

.pack-card__related-boards {
  color: #5d6c7a;
  cursor: pointer;
  border: 1px solid #5d6c7a;
  border-radius: 4px;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 14px;
  text-decoration: none;
}

.pack-card__related-boards i {
  margin-right: .25rem;
}

.pack-card__related-boards:hover {
  color: #11809f;
  background-color: #f7f7f7;
  border-color: #11809f;
}

.pack-card__related-devices {
  color: #5d6c7a;
  cursor: pointer;
  border: 1px solid #5d6c7a;
  border-radius: 4px;
  padding: .25rem .5rem;
  font-size: 14px;
  text-decoration: none;
}

.pack-card__related-devices i {
  margin-right: .25rem;
}

.pack-card__related-devices:hover {
  color: #11809f;
  background-color: #f7f7f7;
  border-color: #11809f;
}

.pack-listing {
  margin-top: 0;
}

@media (width <= 640px) {
  .pack-listing__about-cmsis {
    display: none;
  }
}

.pack-listing-header {
  background: #fff;
  padding: 1rem 0;
}

.pack-listing__title {
  margin: 1rem 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .pack-listing__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.pack-listing__results {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem 2rem;
  padding: 0;
  list-style: none;
  display: grid;
}

.pack-listing__results-header {
  display: flex;
}

.pack-listing__results-header h2 {
  white-space: nowrap;
  flex: 1;
  margin: 0;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.pack-listing__results-header__count {
  margin-left: .1rem;
  font-size: 16px;
  font-weight: normal;
}

@media (width <= 60rem) {
  .pack-listing__results {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width <= 640px) {
  .pack-listing__results {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.pack-listing__results--empty {
  color: #a3a6ac;
  text-align: center;
  margin: 2rem 0 .8rem;
  font-size: 27px;
  font-weight: lighter;
  line-height: 32px;
}

.board-summary {
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "header image"
                       "components image";
  display: grid;
}

@media (width <= 40rem) {
  .board-summary {
    grid-template-columns: auto;
    grid-template-areas: "header"
                         "image"
                         "components";
  }
}

.board-summary__header {
  grid-area: header;
  justify-content: space-between;
  display: flex;
}

@media (width <= 40rem) {
  .board-summary__header {
    margin-bottom: 1rem;
  }
}

.board-summary__image {
  grid-area: image;
}

@media (width >= 640px) {
  .board-summary__image {
    justify-self: end;
  }
}

.board-summary__components {
  grid-area: components;
}

@media (width <= 640px) {
  .board-summary__components {
    overflow: scroll;
  }
}

.board-summary a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.board-summary a:hover {
  color: #333e48;
}

.board-header__container {
  flex-wrap: wrap;
  align-items: baseline;
  gap: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.board-header__title {
  white-space: break-spaces;
  overflow-wrap: anywhere;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .board-header__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.board-header__revision {
  color: #888;
  margin: 0;
}

.board-header__vendor {
  display: inline-block;
}

.board-components {
  margin: 2rem 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.board-components__item {
  border-right: 1px solid #e1e1e1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1.5rem;
  display: flex;
}

.board-components__item:first-child {
  padding-left: 0;
}

@media (width <= 40rem) {
  .board-components__item {
    padding: 0 1rem;
  }
}

.board-components__title {
  white-space: nowrap;
  color: #888;
  margin: 0;
  font-size: 14px;
}

.board-components__value--pre-wrap {
  white-space: pre-wrap;
}

.board-components__value--large-text {
  margin: 0;
  font-size: 22px;
}

@media (width <= 40rem) {
  .board-components__value--large-text {
    font-size: 18px;
  }
}

.board-components__value--tag {
  overflow-wrap: anywhere;
  color: #333e48;
  vertical-align: sub;
  background-color: #e1e1e1;
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 4px;
  font-size: 14px;
  display: flex;
}

.board-image {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  padding: 16px;
  display: flex;
  overflow: hidden;
}

.board-image--empty {
  padding: 0;
}

.board-image .board-image__image {
  max-width: 100%;
  max-height: 100%;
}

.board-breadcrumbs {
  margin: 0 0 .5em;
  padding: 0;
  list-style: none;
  display: flex;
}

.board-breadcrumbs li {
  color: #6f777c;
}

.board-breadcrumbs li:last-child {
  color: #333e48;
}

.board-breadcrumbs li a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.board-breadcrumbs li a:hover {
  color: #333e48;
}

.board-breadcrumbs i {
  align-self: center;
  margin: 0 .3rem;
}

.boards-page-info__url {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.boards-page-info__url:hover {
  color: #c75300;
}

.board-tabs, .vendor-tabs, .device-tabs, .family-tabs {
  white-space: nowrap;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 2em;
}

.board-tabs ul, .vendor-tabs ul, .device-tabs ul, .family-tabs ul {
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.board-tabs ul li, .vendor-tabs ul li, .device-tabs ul li, .family-tabs ul li {
  margin: 0 1.5em;
  padding: .5em 0;
}

.board-tabs ul li:first-child, .vendor-tabs ul li:first-child, .device-tabs ul li:first-child, .family-tabs ul li:first-child {
  margin-left: 0;
}

@media (width <= 30rem) {
  .board-tabs ul, .vendor-tabs ul, .device-tabs ul, .family-tabs ul {
    justify-content: space-between;
  }

  .board-tabs ul li, .vendor-tabs ul li, .device-tabs ul li, .family-tabs ul li {
    margin: 0;
  }
}

.board-tabs a, .vendor-tabs a, .device-tabs a, .family-tabs a {
  color: #0091bd;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.board-tabs a:hover, .vendor-tabs a:hover, .device-tabs a:hover, .family-tabs a:hover {
  color: #333e48;
}

@media (width <= 640px) {
  .board-tabs a, .vendor-tabs a, .device-tabs a, .family-tabs a {
    font-size: 16px;
  }
}

.board-tabs--selected, .vendor-tabs--selected, .device-tabs--selected, .family-tabs--selected {
  border-bottom: 5px solid #0091bd;
}

.board-tabs--selected a, .vendor-tabs--selected a, .device-tabs--selected a, .family-tabs--selected a {
  color: #333e48;
}

.board-tabs a:hover .board-tabs, .board-tabs a:hover .vendor-tabs, .board-tabs a:hover .device-tabs, .board-tabs a:hover .family-tabs__count, .vendor-tabs a:hover .board-tabs, .vendor-tabs a:hover .vendor-tabs, .vendor-tabs a:hover .device-tabs, .vendor-tabs a:hover .family-tabs__count, .device-tabs a:hover .board-tabs, .device-tabs a:hover .vendor-tabs, .device-tabs a:hover .device-tabs, .device-tabs a:hover .family-tabs__count, .family-tabs a:hover .board-tabs, .family-tabs a:hover .vendor-tabs, .family-tabs a:hover .device-tabs, .family-tabs a:hover .family-tabs__count {
  color: #333e48;
  border-color: #333e48;
}

.board-tabs__count, .vendor-tabs__count, .device-tabs__count, .family-tabs__count {
  color: #0091bd;
  text-align: center;
  background: none;
  border: 1px solid #0091bd;
  border-radius: 2em;
  min-width: 20px;
  margin-left: .3rem;
  padding: 0 5px;
  font-size: 12px;
  display: inline-block;
}

.board-tabs__count--empty, .vendor-tabs__count--empty, .device-tabs__count--empty, .family-tabs__count--empty {
  color: #7b8087;
  border-color: #7b8087;
}

.board-tabs--selected .board-tabs, .board-tabs .vendor-tabs, .board-tabs .device-tabs, .board-tabs .family-tabs__count, .vendor-tabs--selected .board-tabs, .vendor-tabs .vendor-tabs, .vendor-tabs .device-tabs, .vendor-tabs .family-tabs__count, .device-tabs--selected .board-tabs, .device-tabs .vendor-tabs, .device-tabs .device-tabs, .device-tabs .family-tabs__count, .family-tabs--selected .board-tabs, .family-tabs .vendor-tabs, .family-tabs .device-tabs, .family-tabs .family-tabs__count {
  color: #333e48;
  background: none;
  border: 1px solid #333e48;
}

.board-projects-search {
  margin-bottom: 1em;
  display: flex;
  position: relative;
}

.board-projects-search:focus-within .board-projects-search__suggestions {
  display: block;
}

.board-projects-search:focus-within .board-projects-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.board-projects-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.board-projects-search__input::placeholder {
  color: #bbb;
}

.board-projects-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.board-projects-search__input:focus + .board-projects-search__suggestions {
  display: block;
}

.board-projects-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.board-projects-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.board-projects-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.board-projects-search__suggestions .board-projects-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.board-projects-search__suggestions .board-projects-search__suggestion a, .board-projects-search__suggestions .board-projects-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.board-projects-search__suggestions .board-projects-search__suggestion a i, .board-projects-search__suggestions .board-projects-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.board-projects-search__suggestions .board-projects-search__suggestion a i:hover, .board-projects-search__suggestions .board-projects-search__suggestion div i:hover {
  color: #fff;
}

.board-projects-search__suggestions .board-projects-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.board-projects-search__suggestions .board-projects-search__suggestion--focused, .board-projects-search__suggestions .board-projects-search__suggestion:active {
  background-color: #e5eceb;
}

.board-projects-search__suggestions .board-projects-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.board-projects-search__suggestions .board-projects-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.board-projects-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.board-projects-results {
  margin: 0;
  padding: 0;
  list-style: none;
}

.board-project {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.board-project:hover {
  box-shadow: 0 2px 4px #333e481a;
}

.board-project__header {
  border-bottom: 1px solid #e1e1e1;
  padding: 1rem 1.5rem;
}

.board-project__title-row {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.board-project__chip-row > * + * {
  margin-left: .5rem;
}

.board-project__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.board-project__body {
  padding: 1rem 1.5rem;
}

.board-project__description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 80%;
  margin: 0;
  display: -webkit-box;
  overflow: hidden;
}

.board-project__description[data-extended="true"] {
  -webkit-line-clamp: unset;
}

.board-project__description + [data-extendable-content-control] {
  color: #11809f;
  background-color: unset;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-decoration: underline;
}

.board-project__description + [data-extendable-content-control]:hover {
  color: #c75300;
}

@media (width <= 30rem) {
  .board-project__description {
    max-width: 100%;
  }
}

.board-project__compatibility, .board-project__toolchains {
  color: #5d6c7a;
  border: 1px solid #5d6c7a;
  border-radius: 4px;
  padding: .25rem .5rem;
  font-size: 14px;
  text-decoration: none;
}

.board-project__compatibility i, .board-project__toolchains i {
  margin-right: .25rem;
}

.board-project__origin {
  color: #0091bd;
  margin-top: 1rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.board-project__origin:hover {
  color: #c75300;
}

.board-projects-results--no-search-results {
  text-align: center;
  margin-top: 4rem;
}

.board-projects-results--no-search-results__title {
  color: #a3a6ac;
  margin-bottom: .8rem;
  font-size: 27px;
  font-weight: lighter;
  line-height: 32px;
}

.board-projects-download-menu__dropdown-container {
  margin-left: auto;
  position: relative;
}

.board-projects-download-menu__dropdown-container:hover .board-projects-download-menu__dropdown-link-content-container {
  display: block;
}

.board-projects-download-menu__dropdown-container:hover .board-projects-download-menu__dropdown-link {
  background-color: #ffc700;
}

.board-projects-download-menu__dropdown-container:hover .board-projects-download-menu__dropdown-link-icon:before {
  content: "";
}

@media (width <= 640px) {
  .board-projects-download-menu__dropdown-container {
    display: none;
  }
}

.board-projects-download-menu__dropdown-link {
  border: 1px solid #ffc700;
  border-radius: 4px;
  padding: .4rem 1rem;
}

.board-projects-download-menu__dropdown-link-icon:before {
  font: var(--fa-font-light);
  content: "";
  margin-left: .2rem;
  font-size: 12px;
  transform: scale(0);
}

.board-projects-download-menu__dropdown-link:hover {
  cursor: pointer;
  color: #333e48;
}

.board-projects-download-menu__hint-link {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.board-projects-download-menu__hint-link:hover {
  color: #c75300;
}

.board-projects-download-menu__dropdown-link-content-container {
  z-index: 99;
  width: 310px;
  padding-top: 6px;
  display: none;
  position: absolute;
  left: auto;
  right: 0;
}

.board-projects-download-menu__dropdown-link-content-inner-container {
  position: inherit;
  width: 95%;
  margin: 0 auto .5em;
}

.board-projects-download-menu__dropdown-link-content {
  background-color: #fff;
  border: 1px solid #333e4866;
  border-radius: 4px;
  padding: 0;
  list-style: none;
}

.board-projects-download-menu__dropdown-link-content a:hover {
  background-color: #e5eceb66;
}

.board-projects-download-menu__hint {
  color: #5d6c7a;
  grid-column: 2;
  margin-bottom: 0;
  font-size: 14px;
}

.board-projects-download-menu__hint a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.board-projects-download-menu__hint a:hover {
  color: #c75300;
}

.board-projects-download-menu__link {
  color: #333e48;
  grid-template-columns: 1.4em auto;
  align-items: center;
  padding: .5em 1em;
  font-size: 1rem;
  text-decoration: none;
  display: grid;
}

.board-projects-download-menu__link:hover {
  color: #000;
}

.board-projects-download-menu__separator {
  color: #e5eceb66;
  width: 85%;
  margin: 0 auto;
}

.board-projects-uvprojx-container__content {
  background-color: #f7f7f7;
  border: none;
}

.board-projects-uvprojx-container__title {
  color: #6f777c;
  margin-left: .8em;
  margin-right: .5em;
  padding-top: .6em;
  padding-bottom: .7em;
  font-size: 14px;
  font-weight: 700;
  display: flex;
}

.board-projects-uvprojx-container__title-text {
  flex: 1 0 auto;
}

.board-projects-uvprojx-container__compatibility-tag {
  overflow-wrap: anywhere;
  color: #6f777c;
  background-color: #e5eceb;
  border-radius: 5px;
  margin: 0;
  padding: 0 4px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.board-projects-mdk-banner {
  background-color: #ebeced;
  border-radius: .5em;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-left: 2em;
  overflow-y: hidden;
}

@media (width <= 30rem) {
  .board-projects-mdk-banner {
    display: none;
  }
}

.board-projects-mdk-banner__header {
  font-size: 24px;
  font-weight: 300;
}

.board-projects-mdk-banner__body {
  margin-top: 1em;
  margin-bottom: 1em;
  display: block;
}

.board-projects-mdk-banner__link {
  color: #0091bd;
  margin-bottom: 1em;
  font-weight: bold;
  text-decoration: none;
}

.board-projects-mdk-banner__link:hover {
  color: #c75300;
}

.board-projects-mdk-banner__close-button {
  float: right;
  background: initial;
  border: none;
  margin-right: .8em;
}

.board-projects-mdk-banner__close-button:hover {
  cursor: pointer;
}

.board-projects-mdk-banner__image {
  float: right;
  margin-top: 1em;
  margin-left: 4em;
  margin-right: 1.2em;
}

.feature-categories {
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  padding: 0;
  list-style: none;
  display: grid;
}

@media (width <= 640px) {
  .feature-categories {
    grid-template-columns: auto;
  }
}

.feature-category {
  border-collapse: collapse;
  width: 100%;
}

.feature-category__title {
  color: #888;
  border-bottom: 1px solid #e1e1e1;
  font-size: 18px;
}

.feature-category__feature__type {
  vertical-align: top;
  width: 10rem;
  padding-right: 1rem;
  font-weight: bold;
}

.feature-category__feature > * {
  padding-top: 1rem;
}

.document-categories {
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  padding: 0;
  list-style: none;
  display: grid;
}

@media (width <= 640px) {
  .document-categories {
    grid-template-columns: auto;
  }
}

.document-category {
  border-collapse: collapse;
  width: 100%;
}

.document-category__title {
  color: #888;
  border-bottom: 1px solid #e1e1e1;
  font-size: 18px;
}

.document-category__document__url {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.document-category__document__url:hover {
  color: #c75300;
}

.document-category__document > * {
  padding-top: 1rem;
}

.board-page-content--empty {
  text-align: center;
  margin-top: 4rem;
}

@media (width <= 640px) {
  .board-page-content--empty {
    margin-top: 2rem;
  }
}

.board-page-content--empty__title {
  color: #a3a6ac;
  margin-bottom: .8rem;
  font-size: 27px;
  font-weight: lighter;
  line-height: 32px;
}

.board-page-content--empty__text {
  max-width: 525px;
  margin: 0 auto;
}

.board-page-content--empty__text a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.board-page-content--empty__text a:hover {
  color: #c75300;
}

.board-page-content--empty__cta {
  color: #333e48;
  cursor: pointer;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  margin-top: 2rem;
  padding: .6em;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .board-page-content--empty__cta {
    white-space: nowrap;
  }
}

.board-page-content--empty__cta:hover, .board-page-content--empty__cta:focus {
  color: #333e48;
  background-color: #f6d452;
}

@media (width <= 640px) {
  .board-page-content--empty__cta {
    margin-top: 1rem;
  }
}

.device-features__title__device {
  text-align: center;
}

.device-features__title__device div {
  text-align: left;
  display: inline-block;
}

.device-features__title__device__title {
  margin: 0;
}

.device-features__title__device__name {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
}

.device-features__title__features {
  vertical-align: bottom;
  color: #6f777c;
}

.device-features__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.device-features__table {
  border-collapse: collapse;
  table-layout: fixed;
  border: none;
  width: 60%;
  margin-bottom: .5em;
}

@media (width <= 640px) {
  .device-features__table {
    width: 100%;
  }
}

.device-features__table__title {
  border-color: #d8dadc;
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: .5em;
  padding-bottom: .5em;
}

.device-features__table__row:nth-child(odd) {
  background-color: #f7f7f7;
}

.device-features__table td {
  padding: 1rem .5rem;
}

.device-features__table td:not(:last-child) {
  border-right: 1px solid #e1e1e1;
}

.device-features__table__head {
  font-weight: bold;
}

.device-features__table__data {
  text-align: center;
}

.device-processors-table {
  table-layout: fixed;
  border-spacing: 0;
  width: 60%;
}

@media (width <= 768px) {
  .device-processors-table {
    width: 100%;
  }
}

.device-processors-table th {
  padding-bottom: .8rem;
}

.device-processors-table__col-header--small {
  color: #6f777c;
  text-align: left;
  vertical-align: bottom;
  font-size: 1rem;
}

.device-processors-table__col-header--large {
  color: #333e48;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}

.device-processors-table__col-header--large div {
  text-align: left;
  display: inline-block;
}

.device-processors-table__col-header--large p {
  margin: 0;
}

.device-processors-table__col-subheader {
  font-size: 14px;
  font-weight: bold;
}

.device-processors-table tr {
  margin: 0;
  padding: 0;
}

.device-processors-table tr:nth-child(2n) {
  background-color: #f7f7f7;
}

.device-processors-table td {
  border-collapse: collapse;
  margin: 0;
  padding: 1rem .5rem;
}

.device-processors-table td:not(:last-child) {
  border-right: 1px solid #e1e1e1;
}

.device-processors-table__name {
  font-weight: bold;
}

.device-processors-table__value {
  text-align: center;
}

.device-processors-table__value i {
  opacity: .6;
}

.package-listing-header {
  padding-bottom: 1rem;
}

.package-listing__title {
  margin-bottom: 1rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .package-listing__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.package-listing h2 {
  margin-bottom: .5rem;
}

.package-listing__terms {
  background-color: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.package-listing__terms-title {
  margin: 0;
}

.package-listing__terms * + * {
  margin: .5rem 0 0;
}

.package-listing-toc {
  margin-bottom: 3rem;
}

.package-listing-toc p {
  margin-bottom: .5rem;
}

.package-listing-toc ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.package-listing-toc li {
  margin-bottom: .5rem;
}

.package-listing-toc li:last-child {
  border: none;
}

.package-listing__code {
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: .1rem .2rem;
  font-family: Monaco, monospace;
  font-size: 14px;
}

.package-listing__results {
  margin: 0;
  padding: 0;
  list-style: none;
}

.package-listing a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.package-listing a:hover {
  color: #c75300;
}

.package {
  margin-bottom: 3rem;
}

.package__title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

@media (width <= 640px) {
  .package__title {
    margin-bottom: 0;
  }
}

.package-versions {
  padding: 0;
  list-style: none;
}

.package-version {
  border-bottom: 1px solid #e1e1e1;
  padding: 1rem 0;
}

.package-version-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.package-version-header__snippet {
  visibility: hidden;
  width: 500px;
  display: flex;
}

.package-version-header__snippet > * {
  appearance: none;
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  outline: none;
  padding: .1rem .2rem;
  font-family: Monaco, monospace;
  font-size: 14px;
}

.package-version-header__snippet input {
  text-overflow: ellipsis;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: .3rem .5rem;
}

.package-version-header__snippet button {
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 32px;
}

.package-version-header__snippet input {
  width: 100%;
}

@media (width <= 768px) {
  .package-version-header__snippet {
    display: none;
  }
}

.package-version-header__title {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: bold;
}

.package-version-header__title:before {
  font: var(--fa-font-light);
  content: "";
  width: 1em;
  font-size: 18px;
  display: inline-block;
}

.package-version[aria-expanded="true"] .package-version-header__snippet {
  visibility: visible;
}

.package-version[aria-expanded="true"] .package-version-header__title:before {
  content: "";
}

.package-version-details {
  margin-top: 1rem;
  margin-left: 1rem;
}

.package-version-details__description {
  max-width: 600px;
}

.package-version-details__availability {
  flex-flow: wrap;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.package-version-details__availability h4 {
  margin: 0;
  font-weight: bold;
}

.package-version-details__platform {
  overflow-wrap: anywhere;
  color: #333e48;
  text-wrap: nowrap;
  background-color: #e1e1e1;
  border-radius: 5px;
  margin: 0;
  padding: 0 4px;
  font-size: 14px;
  display: inline-block;
}

.vendor-summary {
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "title title"
                       "details logo";
  margin-bottom: 2rem;
  display: grid;
}

@media (width <= 40rem) {
  .vendor-summary {
    grid-template-columns: auto;
    grid-template-areas: "title"
                         "logo"
                         "details";
    margin-bottom: 1rem;
  }
}

.vendor-summary__title {
  grid-area: title;
  margin-bottom: .8rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .vendor-summary__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.vendor-summary__details {
  grid-area: details;
}

.vendor-summary__description {
  max-width: 700px;
}

.vendor-summary__description p {
  color: #6f777c;
  text-align: justify;
  font-size: 14px;
  line-height: 24px;
}

.vendor-summary__description h1 {
  margin-bottom: .8rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .vendor-summary__description h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.vendor-summary__description h2 {
  margin-bottom: .8rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

@media (width >= 640px) {
  .vendor-summary__description h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
}

.vendor-summary__description h3 {
  margin-bottom: .8rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
}

.vendor-summary__description a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.vendor-summary__description a:hover {
  color: #c75300;
}

.vendor-summary__url {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.vendor-summary__url:hover {
  color: #c75300;
}

.vendor-summary__logo {
  grid-area: logo;
  height: auto;
  margin-left: 5em;
}

@media (width <= 40rem) {
  .vendor-summary__logo {
    margin-bottom: 1em;
    margin-left: 0;
  }
}

.vendor-breadcrumbs {
  margin: 0 0 .5em;
  padding: 0;
  list-style: none;
  display: flex;
}

.vendor-breadcrumbs li {
  color: #6f777c;
}

.vendor-breadcrumbs li:last-child {
  color: #333e48;
}

.vendor-breadcrumbs li a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.vendor-breadcrumbs li a:hover {
  color: #333e48;
}

.vendor-breadcrumbs i {
  align-self: center;
  margin: 0 .3rem;
}

.device-summary, .family-summary, .device-group-summary {
  flex-direction: column;
  margin-bottom: 1rem;
  display: flex;
}

@media (width <= 40rem) {
  .device-summary__header, .family-summary__header, .device-group-summary__header {
    margin-bottom: 1rem;
  }
}

.device-summary a, .family-summary a, .device-group-summary a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.device-summary a:hover, .family-summary a:hover, .device-group-summary a:hover {
  color: #333e48;
}

.device-header__container, .family-header__container, .device-group-header__container {
  flex-wrap: wrap;
  align-items: baseline;
  gap: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.device-header__title, .family-header__title, .device-group-header__title {
  white-space: break-spaces;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .device-header__title, .family-header__title, .device-group-header__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.device-header__revision, .family-header__revision, .device-group-header__revision {
  color: #888;
  margin: 0;
}

.device-header__vendor, .family-header__vendor, .device-group-header__vendor {
  display: inline-block;
}

.device-components {
  margin: 2rem 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.device-components__item {
  border-right: 1px solid #e1e1e1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1.5rem;
  display: flex;
}

.device-components__item:first-child {
  padding-left: 0;
}

@media (width <= 40rem) {
  .device-components__item {
    padding: 0 1rem;
  }
}

.device-components__title {
  white-space: nowrap;
  color: #888;
  margin: 0;
  font-size: 14px;
}

.device-components__value--large-text {
  margin: 0;
  font-size: 22px;
}

@media (width <= 40rem) {
  .device-components__value--large-text {
    font-size: 18px;
  }
}

.device-breadcrumbs, .family-breadcrumbs, .device-group-breadcrumbs {
  margin: 0 0 .5em;
  padding: 0;
  list-style: none;
  display: flex;
}

.device-breadcrumbs li, .family-breadcrumbs li, .device-group-breadcrumbs li {
  color: #6f777c;
}

.device-breadcrumbs li:last-child, .family-breadcrumbs li:last-child, .device-group-breadcrumbs li:last-child {
  color: #333e48;
}

.device-breadcrumbs li a, .family-breadcrumbs li a, .device-group-breadcrumbs li a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.device-breadcrumbs li a:hover, .family-breadcrumbs li a:hover, .device-group-breadcrumbs li a:hover {
  color: #333e48;
}

.device-breadcrumbs i, .family-breadcrumbs i, .device-group-breadcrumbs i {
  align-self: center;
  margin: 0 .3rem;
}

.devices-page-info__url {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.devices-page-info__url:hover {
  color: #c75300;
}

.pack-listing__results, .device-listing__results {
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.device-card {
  color: #333e48;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  min-height: 54px;
  padding: 0 1rem;
  text-decoration: none;
  display: flex;
}

.device-card:hover {
  box-shadow: 0 2px 4px #333e481a;
}

.device-card__details {
  grid-template-columns: 1fr 2fr 1fr 1fr;
  align-items: center;
  width: 100%;
  display: grid;
}

.device-card p {
  margin: 0;
  font-size: 16px;
}

.device-card__name {
  color: #0091bd;
  font-size: 16px;
  font-weight: normal;
}

.device-card__name em {
  font-style: normal;
  font-weight: 900;
}

.device-card__cores {
  padding: 0;
  list-style: none;
}

.pack-device-search {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.pack-device-search:focus-within .pack-device-search__suggestions {
  display: block;
}

.pack-device-search:focus-within .pack-device-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pack-device-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.pack-device-search__input::placeholder {
  color: #bbb;
}

.pack-device-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.pack-device-search__input:focus + .pack-device-search__suggestions {
  display: block;
}

.pack-device-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pack-device-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.pack-device-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.pack-device-search__suggestions .pack-device-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.pack-device-search__suggestions .pack-device-search__suggestion a, .pack-device-search__suggestions .pack-device-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.pack-device-search__suggestions .pack-device-search__suggestion a i, .pack-device-search__suggestions .pack-device-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.pack-device-search__suggestions .pack-device-search__suggestion a i:hover, .pack-device-search__suggestions .pack-device-search__suggestion div i:hover {
  color: #fff;
}

.pack-device-search__suggestions .pack-device-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.pack-device-search__suggestions .pack-device-search__suggestion--focused, .pack-device-search__suggestions .pack-device-search__suggestion:active {
  background-color: #e5eceb;
}

.pack-device-search__suggestions .pack-device-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.pack-device-search__suggestions .pack-device-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.pack-device-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.device-search {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.device-search:focus-within .device-search__suggestions {
  display: block;
}

.device-search:focus-within .device-search__input--with-items {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.device-search__input {
  text-overflow: ellipsis;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 22px;
  outline: none;
  width: 100%;
  margin: 0;
  padding: .5rem 1rem .5rem 2.5rem;
}

.device-search__input::placeholder {
  color: #bbb;
}

.device-search__input:focus {
  color: #333e48;
  border: 1px solid #e1e1e1;
}

.device-search__input:focus + .device-search__suggestions {
  display: block;
}

.device-search__input--with-items:focus {
  border-bottom-color: #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.device-search__icon {
  color: #333e48;
  align-self: center;
  padding: .5rem .5rem .5rem 1rem;
  position: absolute;
}

.device-search__suggestions {
  z-index: 10;
  background-color: #fff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 3px 3px 3px -2px #eee, 3px 3px 3px -2px #eee, -3px 3px 3px -2px #eee;
}

.device-search__suggestions .device-search__suggestion {
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.device-search__suggestions .device-search__suggestion a, .device-search__suggestions .device-search__suggestion div {
  color: #333e48;
  cursor: pointer;
  padding: .5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.device-search__suggestions .device-search__suggestion a i, .device-search__suggestions .device-search__suggestion div i {
  float: right;
  line-height: inherit;
  padding: 0 .5em;
}

.device-search__suggestions .device-search__suggestion a i:hover, .device-search__suggestions .device-search__suggestion div i:hover {
  color: #fff;
}

.device-search__suggestions .device-search__suggestion:last-child {
  border-bottom: 1px solid #e1e1e1;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.device-search__suggestions .device-search__suggestion--focused, .device-search__suggestions .device-search__suggestion:active {
  background-color: #e5eceb;
}

.device-search__suggestions .device-search__suggestion-title {
  color: #6f777c;
  cursor: default;
  background-color: #fff;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.device-search__suggestions .device-search__suggestion-title:before {
  content: "";
  border-bottom: 1px solid #e1e1e1;
  width: 90%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 5%;
}

.device-search__suggestions hr {
  border-top: 1px solid #e1e1e1;
  width: 80%;
  margin-top: 0;
  margin-bottom: 0;
}

.device-filters {
  margin-bottom: 2em;
}

.device-filters, .device-listing__results-header {
  align-items: center;
  display: flex;
}

.device-filters .device-filter__dropdown-button, .device-filters .device-filter__dropdown-content, .device-listing__results-header .device-filter__dropdown-button, .device-listing__results-header .device-filter__dropdown-content {
  width: 210px;
}

.device-filters .device-sort__dropdown-button, .device-filters .device-sort__dropdown-content, .device-listing__results-header .device-sort__dropdown-button, .device-listing__results-header .device-sort__dropdown-content {
  width: 250px;
}

.device-filters .device-filter:not(:first-child), .device-filters .device-sort:not(:first-child), .device-listing__results-header .device-filter:not(:first-child), .device-listing__results-header .device-sort:not(:first-child) {
  margin-left: 2rem;
}

.device-filters .device-filter input:hover, .device-filters .device-filter label:hover, .device-filters .device-sort input:hover, .device-filters .device-sort label:hover, .device-listing__results-header .device-filter input:hover, .device-listing__results-header .device-filter label:hover, .device-listing__results-header .device-sort input:hover, .device-listing__results-header .device-sort label:hover {
  cursor: pointer;
}

.device-filters .device-filter label, .device-filters .device-sort label, .device-listing__results-header .device-filter label, .device-listing__results-header .device-sort label {
  align-items: center;
  display: flex;
}

.device-filters .device-filter label input[type="checkbox"], .device-filters .device-sort label input[type="checkbox"], .device-listing__results-header .device-filter label input[type="checkbox"], .device-listing__results-header .device-sort label input[type="checkbox"] {
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  place-content: center;
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem 0 0;
  display: grid;
}

.device-filters .device-filter label input[type="checkbox"]:checked, .device-filters .device-sort label input[type="checkbox"]:checked, .device-listing__results-header .device-filter label input[type="checkbox"]:checked, .device-listing__results-header .device-sort label input[type="checkbox"]:checked {
  background: #0091bd;
  border: none;
}

.device-filters .device-filter label input[type="checkbox"]:before, .device-filters .device-sort label input[type="checkbox"]:before, .device-listing__results-header .device-filter label input[type="checkbox"]:before, .device-listing__results-header .device-sort label input[type="checkbox"]:before {
  content: "";
  transform-origin: 0 100%;
  font: var(--fa-font-regular);
  content: "";
  color: #fff;
  font-size: 12px;
  transition: transform .12s ease-in-out;
  transform: scale(0);
}

.device-filters .device-filter label input[type="checkbox"]:checked:before, .device-filters .device-sort label input[type="checkbox"]:checked:before, .device-listing__results-header .device-filter label input[type="checkbox"]:checked:before, .device-listing__results-header .device-sort label input[type="checkbox"]:checked:before {
  transform: scale(1);
}

.device-filters .device-filter label input[type="checkbox"]:focus, .device-filters .device-sort label input[type="checkbox"]:focus, .device-listing__results-header .device-filter label input[type="checkbox"]:focus, .device-listing__results-header .device-sort label input[type="checkbox"]:focus {
  border-color: #0091bd;
}

.device-filters .device-filter label input[type="radio"], .device-filters .device-sort label input[type="radio"], .device-listing__results-header .device-filter label input[type="radio"], .device-listing__results-header .device-sort label input[type="radio"] {
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  place-content: center;
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem 0 0;
  display: grid;
}

.device-filters .device-filter label input[type="radio"]:checked, .device-filters .device-sort label input[type="radio"]:checked, .device-listing__results-header .device-filter label input[type="radio"]:checked, .device-listing__results-header .device-sort label input[type="radio"]:checked {
  background: #0091bd;
  border: none;
}

.device-filters .device-filter label input[type="radio"]:before, .device-filters .device-sort label input[type="radio"]:before, .device-listing__results-header .device-filter label input[type="radio"]:before, .device-listing__results-header .device-sort label input[type="radio"]:before {
  content: "";
  font: var(--fa-font-solid);
  content: "";
  color: #fff;
  font-size: 8px;
  transform: scale(0);
}

.device-filters .device-filter label input[type="radio"]:checked:before, .device-filters .device-sort label input[type="radio"]:checked:before, .device-listing__results-header .device-filter label input[type="radio"]:checked:before, .device-listing__results-header .device-sort label input[type="radio"]:checked:before {
  transform: scale(1);
}

.device-filters .device-filter label input[type="radio"]:focus, .device-filters .device-sort label input[type="radio"]:focus, .device-listing__results-header .device-filter label input[type="radio"]:focus, .device-listing__results-header .device-sort label input[type="radio"]:focus {
  border-color: #0091bd;
}

.device-filters .device-filter__dropdown-button, .device-filters .device-sort__dropdown-button, .device-listing__results-header .device-filter__dropdown-button, .device-listing__results-header .device-sort__dropdown-button {
  color: initial;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

@media (width >= 768px) {
  .device-filters .device-filter__dropdown-button, .device-filters .device-sort__dropdown-button, .device-listing__results-header .device-filter__dropdown-button, .device-listing__results-header .device-sort__dropdown-button {
    white-space: nowrap;
  }
}

.device-filters .device-filter__dropdown-button:hover, .device-filters .device-filter__dropdown-button:focus, .device-filters .device-sort__dropdown-button:hover, .device-filters .device-sort__dropdown-button:focus, .device-listing__results-header .device-filter__dropdown-button:hover, .device-listing__results-header .device-filter__dropdown-button:focus, .device-listing__results-header .device-sort__dropdown-button:hover, .device-listing__results-header .device-sort__dropdown-button:focus {
  color: #fff;
  background-color: #0091bd;
}

.device-filters .device-filter__dropdown-button:disabled, .device-filters .device-sort__dropdown-button:disabled, .device-listing__results-header .device-filter__dropdown-button:disabled, .device-listing__results-header .device-sort__dropdown-button:disabled {
  cursor: default;
  opacity: .5;
}

.device-filters .device-filter__dropdown-button:disabled:hover, .device-filters .device-sort__dropdown-button:disabled:hover, .device-listing__results-header .device-filter__dropdown-button:disabled:hover, .device-listing__results-header .device-sort__dropdown-button:disabled:hover {
  background: #fff;
}

.device-filters .device-filter__dropdown-button-text, .device-filters .device-sort__dropdown-button-text, .device-listing__results-header .device-filter__dropdown-button-text, .device-listing__results-header .device-sort__dropdown-button-text {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.device-filters .device-filter__dropdown-button:focus, .device-filters .device-sort__dropdown-button:focus, .device-listing__results-header .device-filter__dropdown-button:focus, .device-listing__results-header .device-sort__dropdown-button:focus {
  background: initial;
  color: initial;
}

.device-filters .device-filter__dropdown-button:hover, .device-filters .device-sort__dropdown-button:hover, .device-listing__results-header .device-filter__dropdown-button:hover, .device-listing__results-header .device-sort__dropdown-button:hover {
  color: initial;
  background-color: #e5eceb66;
}

.device-filters .device-filter__dropdown-content, .device-filters .device-sort__dropdown-content, .device-listing__results-header .device-filter__dropdown-content, .device-listing__results-header .device-sort__dropdown-content {
  visibility: hidden;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  max-height: 250px;
  margin-top: 2px;
  padding: .5rem 0;
  position: absolute;
  overflow-y: auto;
}

.device-filters .device-filter__dropdown-content-results, .device-filters .device-sort__dropdown-content-results, .device-listing__results-header .device-filter__dropdown-content-results, .device-listing__results-header .device-sort__dropdown-content-results {
  margin: 0;
  padding: 0;
  list-style: none;
}

.device-filters .device-filter__dropdown-content-results li, .device-filters .device-sort__dropdown-content-results li, .device-listing__results-header .device-filter__dropdown-content-results li, .device-listing__results-header .device-sort__dropdown-content-results li {
  border-radius: 4px;
}

.device-filters .device-filter__dropdown-content-results li > label, .device-filters .device-sort__dropdown-content-results li > label, .device-listing__results-header .device-filter__dropdown-content-results li > label, .device-listing__results-header .device-sort__dropdown-content-results li > label {
  padding: .25rem .5rem;
}

.device-filters .device-filter__dropdown-content-results li > label:hover, .device-filters .device-sort__dropdown-content-results li > label:hover, .device-listing__results-header .device-filter__dropdown-content-results li > label:hover, .device-listing__results-header .device-sort__dropdown-content-results li > label:hover {
  background-color: #e5eceb66;
}

@media (width <= 640px) {
  .device-filters, .device-listing__results-header {
    align-items: initial;
    flex-flow: column wrap;
  }

  .device-filters .device-filter:not(:first-child), .device-filters .device-sort:not(:first-child), .device-listing__results-header .device-filter:not(:first-child), .device-listing__results-header .device-sort:not(:first-child) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

.device-family-list, .device-subfamily-list, .device-list {
  padding: 0;
  list-style-type: none;
}

.device-subfamily-list, .device-list {
  margin: 0 1rem 1rem;
}

.device-family {
  background-color: #f7f7f7;
}

.device-subfamily {
  background-color: #fff9;
}

.device {
  background-color: #fff;
}

.device-family, .device-subfamily, .device {
  cursor: pointer;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  margin-bottom: 1em;
  text-decoration: none;
}

.device-family em, .device-subfamily em, .device em {
  font-style: normal;
  font-weight: 900;
}

.device-family:hover, .device-subfamily:hover, .device:hover {
  box-shadow: 0 2px 4px #333e481a;
}

.device-family:hover[aria-expanded="true"], .device-subfamily:hover[aria-expanded="true"], .device:hover[aria-expanded="true"] {
  box-shadow: none;
}

.device-family__details, .device-subfamily__details, .device__details {
  min-height: 54px;
  padding: 0 1em;
}

.device__details {
  text-decoration: none;
  display: block;
}

.device-family__details {
  color: #333e48;
  align-items: center;
  display: grid;
}

.device-family__details:before {
  font: var(--fa-font-regular);
  content: "";
  width: 1em;
  display: inline-block;
}

.device-family[aria-expanded="true"] .device-family__details:before {
  content: "";
}

.device-subfamily__details {
  color: #333e48;
  align-items: center;
  display: grid;
}

.device-subfamily__details:before {
  font: var(--fa-font-regular);
  content: "";
  width: 1em;
  display: inline-block;
}

.device-subfamily[aria-expanded="true"] .device-subfamily__details:before {
  content: "";
}

.device__name {
  color: #0091bd;
}

.device__cores {
  color: #333e48;
  padding: 0;
  list-style: none;
}

.device-family__view-all-button, .device-subfamily__view-all-button {
  color: #333e48;
  cursor: pointer;
  background-color: #ffc700;
  border: 2px solid #0000;
  border-radius: 28px;
  margin: auto;
  padding: .6em;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .device-family__view-all-button, .device-subfamily__view-all-button {
    white-space: nowrap;
  }
}

.device-family__view-all-button:hover, .device-family__view-all-button:focus, .device-subfamily__view-all-button:hover, .device-subfamily__view-all-button:focus {
  color: #333e48;
  background-color: #f6d452;
}

.device-family__count, .device-subfamily__count {
  color: #6f777c;
  text-align: center;
  border: 1px solid #c2c7c9;
  border-radius: 4px;
  min-width: 100px;
  padding: .2em .3em;
}

@media (width <= 640px) {
  .device-family__count, .device-subfamily__count {
    visibility: hidden;
  }
}

.device-family__links, .device-subfamily__links {
  margin-bottom: 1em;
  display: flex;
}

.device-family__details {
  grid-template-columns: 1rem 15rem 15rem max-content;
}

@media (width <= 640px) {
  .device-family__details {
    grid-template-columns: 1rem 8.5rem max-content 0;
  }
}

.device-subfamily__details {
  grid-template-columns: 1rem 14rem 15rem max-content;
}

.device__details {
  grid-template-columns: auto 15rem;
  align-items: center;
  display: grid;
}

@media (width <= 640px) {
  .device__details {
    grid-template-columns: auto auto;
  }
}

.pack-breadcrumbs {
  margin: 0 0 .5em;
  padding: 0;
  list-style: none;
  display: flex;
}

.pack-breadcrumbs li {
  color: #6f777c;
}

.pack-breadcrumbs li:last-child {
  color: #333e48;
}

.pack-breadcrumbs li a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.pack-breadcrumbs li a:hover {
  color: #333e48;
}

.pack-breadcrumbs i {
  align-self: center;
  margin: 0 .3rem;
}

.pack-summary-metadata-row {
  flex-direction: column;
  display: flex;
}

@media (width >= 640px) {
  .pack-summary-metadata-row {
    flex-direction: row;
  }

  .pack-summary-metadata-row__description {
    flex-grow: 1;
  }

  .pack-summary-metadata-row__actions {
    flex-shrink: 0;
    max-width: 30rem;
  }
}

.pack-summary-metadata-row .pack-cta-actions {
  margin: 0;
  padding-left: 10px;
  list-style-type: none;
}

.pack-summary-metadata-row .pack-cta-actions li {
  border-bottom: 1px solid #cbcdd0;
  padding: 10px 5px;
}

.pack-summary-metadata-row .pack-cta-actions li h4 {
  color: #888;
  font-size: 14px;
}

.pack-summary-metadata-row .pack-cta-actions li:last-child {
  border-bottom: none;
}

.pack-summary-metadata-row .pack-cta-actions__code {
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 5px 30px 5px 10px;
  font-family: Monaco, monospace;
  font-size: 14px;
  position: relative;
}

.pack-summary-metadata-row .pack-cta-actions__code pre {
  margin: 0;
}

.pack-summary-metadata-row .pack-cta-actions__code-key {
  color: #0091bd;
}

.pack-summary-metadata-row .pack-cta-actions__code-value {
  color: #c75300;
}

.pack-summary-metadata-row .pack-cta-actions__code [data-copy-content-id] {
  display: none;
}

.pack-summary-metadata-row .pack-cta-actions__code [data-copy-button-id] {
  color: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #e5eceb;
  border: 1px solid #e5eceb;
  border-radius: 3px;
  padding: 4px;
  transition: background-color .3s, transform .1s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.pack-summary-metadata-row .pack-cta-actions__code [data-copy-button-id]:hover {
  background-color: #c8d6d4;
}

.pack-summary-metadata-row .pack-cta-actions__code [data-copy-button-id]:active {
  background-color: #aac1be;
  transform: scale(.98);
}

.pack-summary-metadata-row .pack-cta-actions a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.pack-summary-metadata-row .pack-cta-actions a:hover {
  color: #c75300;
}

.pack-summary {
  grid-template-columns: 3fr;
  grid-template-areas: "header image"
                       "components image";
  display: grid;
}

@media (width <= 640px) {
  .pack-summary {
    grid-template-columns: auto;
    grid-template-areas: "header"
                         "image"
                         "components";
  }
}

.pack-summary__header {
  grid-area: header;
  justify-content: space-between;
  display: flex;
}

@media (width <= 640px) {
  .pack-summary__header {
    margin-bottom: 1rem;
  }
}

.pack-summary__image {
  grid-area: image;
}

@media (width >= 640px) {
  .pack-summary__image {
    justify-self: end;
  }
}

.pack-summary__components {
  grid-area: components;
}

.pack-summary a {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.pack-summary a:hover {
  color: #333e48;
}

.pack-header__container {
  flex-wrap: wrap;
  align-items: baseline;
  gap: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.pack-header__title {
  white-space: break-spaces;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@media (width >= 640px) {
  .pack-header__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }
}

.pack-header__revision {
  color: #888;
  margin: 0;
}

.pack-header__vendor {
  display: inline-block;
}

.pack-header__download-container a {
  white-space: nowrap;
  margin: 0;
  font-size: 14px;
}

.pack-header__download {
  padding-right: .25em;
}

@media (width >= 640px) {
  .pack-header__download {
    margin-left: 1rem;
  }
}

@media (width <= 640px) {
  .pack-header__download {
    display: block;
  }
}

.pack-header__download__icon {
  margin-right: .1rem;
}

.pack-components {
  margin: 2rem 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.pack-components__item {
  border-right: 1px solid #e1e1e1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1.5rem;
  display: flex;
}

.pack-components__item:first-child {
  padding-left: 0;
}

@media (width <= 640px) {
  .pack-components__item {
    padding: 0 1rem;
  }
}

.pack-components__title {
  white-space: nowrap;
  color: #888;
  margin: 0;
  font-size: 14px;
}

.pack-components__value--pre-wrap {
  white-space: pre-wrap;
}

.pack-components__value--large-text {
  margin: 0;
  font-size: 22px;
}

@media (width <= 640px) {
  .pack-components__value--large-text {
    font-size: 18px;
  }
}

.pack-components__value--tag {
  overflow-wrap: anywhere;
  color: #333e48;
  vertical-align: sub;
  background-color: #e1e1e1;
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 4px;
  font-size: 14px;
  display: flex;
}

.pack-family-page-info__url {
  color: #11809f;
  font-weight: normal;
  text-decoration: underline;
}

.pack-family-page-info__url:hover {
  color: #c75300;
}

.pack-tabs {
  white-space: nowrap;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 2em;
}

.pack-tabs ul {
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.pack-tabs ul li {
  margin: 0 1.5em;
  padding: .5em 0;
}

.pack-tabs ul li:first-child {
  margin-left: 0;
}

@media (width <= 30rem) {
  .pack-tabs ul {
    justify-content: space-between;
  }

  .pack-tabs ul li {
    margin: 0;
  }
}

.pack-tabs a {
  color: #0091bd;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.pack-tabs a:hover {
  color: #333e48;
}

@media (width <= 640px) {
  .pack-tabs a {
    font-size: 16px;
  }
}

.pack-tabs--selected {
  border-bottom: 5px solid #0091bd;
}

.pack-tabs--selected a {
  color: #333e48;
}

.pack-tabs a:hover .pack-tabs__count {
  color: #333e48;
  border-color: #333e48;
}

.pack-tabs__count {
  color: #0091bd;
  text-align: center;
  background: none;
  border: 1px solid #0091bd;
  border-radius: 2em;
  min-width: 20px;
  margin-left: .3rem;
  padding: 0 5px;
  font-size: 12px;
  display: inline-block;
}

.pack-tabs__count--empty {
  color: #7b8087;
  border-color: #7b8087;
}

.pack-tabs--selected .pack-tabs__count {
  color: #333e48;
  background: none;
  border: 1px solid #333e48;
}

.pack-page {
  max-width: 80rem;
  min-height: calc(100vh - 64px);
  margin: 2rem 3rem;
}

@media (width >= 1280px) {
  .pack-page {
    width: 100%;
    margin: 2rem auto;
  }
}

@media (width <= 768px) {
  .pack-page {
    margin: 2rem 1rem;
  }
}

.pack-versions__title {
  font-weight: bold;
}

.pack-versions__version-header {
  font-weight: bold;
  line-height: 1.5;
}

.pack-versions__versions-container {
  border-bottom: 1px solid #e5eceb;
  margin-top: 1.2rem;
}

.pack-versions__release-notes {
  white-space: pre-line;
  margin: 0;
  padding: .7rem 0 1.4rem .7rem;
  font-family: Lato;
}

.pack-versions__version-header-container {
  align-items: flex-end;
  gap: .5rem;
  display: flex;
}

.pack-versions__pack-cta .pack-cta {
  position: relative;
}

.pack-versions__pack-cta .pack-cta__button {
  color: #0091bd;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #0091bd;
  border-radius: 4px;
  padding: 4px .5em;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  .pack-versions__pack-cta .pack-cta__button {
    white-space: nowrap;
  }
}

.pack-versions__pack-cta .pack-cta__button:hover, .pack-versions__pack-cta .pack-cta__button:focus, .pack-versions__pack-cta .pack-cta:hover .pack-cta__button, .pack-versions__pack-cta .pack-cta:focus .pack-cta__button {
  color: #fff;
  background-color: #0091bd;
}

.pack-versions__pack-cta .pack-cta-actions {
  background-color: #fff;
  border-radius: 5px;
  margin: 0;
  padding: 5px 10px;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 23px;
  box-shadow: 0 5px 15px #00000059;
}

.pack-versions__pack-cta .pack-cta-actions li {
  border-bottom: 1px solid #cbcdd0;
  padding: 10px 5px;
}

.pack-versions__pack-cta .pack-cta-actions li h4 {
  color: #888;
  font-size: 14px;
}

.pack-versions__pack-cta .pack-cta-actions li:last-child {
  border-bottom: none;
}

.pack-versions__pack-cta .pack-cta-actions__code {
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 5px 30px 5px 10px;
  font-family: Monaco, monospace;
  font-size: 14px;
  position: relative;
}

.pack-versions__pack-cta .pack-cta-actions__code pre {
  margin: 0;
}

.pack-versions__pack-cta .pack-cta-actions__code-key {
  color: #0091bd;
}

.pack-versions__pack-cta .pack-cta-actions__code-value {
  color: #c75300;
}

.pack-versions__pack-cta .pack-cta-actions__code [data-copy-content-id] {
  display: none;
}

.pack-versions__pack-cta .pack-cta-actions__code [data-copy-button-id] {
  color: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #e5eceb;
  border: 1px solid #e5eceb;
  border-radius: 3px;
  padding: 4px;
  transition: background-color .3s, transform .1s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.pack-versions__pack-cta .pack-cta-actions__code [data-copy-button-id]:hover {
  background-color: #c8d6d4;
}

.pack-versions__pack-cta .pack-cta-actions__code [data-copy-button-id]:active {
  background-color: #aac1be;
  transform: scale(.98);
}

.pack-versions__pack-cta .pack-cta-actions a {
  color: #0091bd;
  font-weight: bold;
  text-decoration: none;
}

.pack-versions__pack-cta .pack-cta-actions a:hover {
  color: #c75300;
}

.pack-versions__pack-cta .pack-cta:hover .pack-cta-actions {
  z-index: 1;
  display: block;
}

.pack-release-link {
  color: #0091bd;
  font-weight: normal;
  text-decoration: none;
}

.pack-release-link:hover {
  color: #c75300;
}

.pack-release-link__icon {
  margin-right: .1rem;
}

.pack-badge__inline-container {
  gap: 10px;
  display: flex;
}

.pack-badge__deprecated {
  overflow-wrap: anywhere;
  color: #333e48;
  background-color: #6f777c33;
  border: 1px solid #6f777c99;
  border-radius: 5px;
  margin: 0;
  padding: 0 4px;
  font-size: 14px;
  display: inline-block;
}

.pack-badge__pre-release {
  overflow-wrap: anywhere;
  color: #333e48;
  background-color: #ff6b0033;
  border: 1px solid #ff6b0099;
  border-radius: 5px;
  margin: 0;
  padding: 0 4px;
  font-size: 14px;
  display: inline-block;
}

.pack-badge__recommended {
  overflow-wrap: anywhere;
  color: #333e48;
  background-color: #95d60033;
  border: 1px solid #95d60099;
  border-radius: 5px;
  margin: 0;
  padding: 0 4px;
  font-size: 14px;
  display: inline-block;
}

/*# sourceMappingURL=main.css.map */
