.document-categories {
  @include kl-feature-tables;
}

.document-category {
  @include kl-borderless-table;

  &__document {
    &__url {
      @include kl-text-link;
    }

    > * {
      padding-top: 1rem;
    }
  }
}
